import { AfterViewInit, Component, OnInit, ViewChild, } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { PartialFullListDataSource } from './partialfull-list-datasource';
import { RetrieveService } from '../api/services/retrieve.service';
import { PartialFull } from '../api/models/partial-full';
import { debounceTime, distinctUntilChanged, finalize, last } from 'rxjs/operators';
import { Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute, RouterLinkWithHref } from '@angular/router';
import moment from 'moment';

export interface SearchParams {
  editor?: string;
  id_partial?: string;
  insertedFromPartial?: string;
  insertedToPartial?: string;
  publishedFromPartial?: string;
  publishedToPartial?: string;
  id_full?: string;
  insertedFromFull?: string;
  insertedToFull?: string;
  publishedFromFull?: string;
  publishedToFull?: string;
  similarityThreshold?: number;
  version?: number;
  limit?: number;
  offset?: number;
  sort?: 'published_partial' | 'published_full' | 'inserted_partial' | 'inserted_full' | 'audiomatch_date';
  order?: 'asc' | 'desc';
}

@Component({
  selector: 'app-partialfull-list',
  templateUrl: './partialfull-list.component.html',
  styleUrls: ['./partialfull-list.component.css']
})
export class PartialfullListComponent implements OnInit, AfterViewInit {
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<PartialFull>;

  fingerprintVersion=1;

  /* Pagination */
  pageIndex: number;
  pageSize: number;

  /* Sorting */
  sortActive: string;
  sortDirection: 'asc' | 'desc';

  /** query parameters */
  searchParams: SearchParams = {};

  /** Table data source */
  dataSource: PartialFullListDataSource;

  /* Filters */
  editorFilter = new FormControl();
  idPartialFilter = new FormControl();
  idFullFilter = new FormControl();

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['id_partial', 'title_partial', 'description_partial', 'inserted_partial', 'published_partial', 'id_full', 'title_full', 'description_full', 'inserted_full', 'published_full', 'audiomatch_date', 'percentage', 'duration', 'offset_full'];

  /* Datepickers helpers */
  insertedFromPartial = new FormControl();
  insertedToPartial = new FormControl();
  publishedFromPartial = new FormControl();
  publishedToPartial = new FormControl();

  insertedFromFull = new FormControl();
  insertedToFull = new FormControl();
  publishedFromFull = new FormControl();
  publishedToFull = new FormControl();

  similarityThresholdForm = new FormControl(0,[
    Validators.pattern("\d*\.?\d+"),
    Validators.max(1),
    Validators.min(0)
    ]);


  constructor(
    private retrieve: RetrieveService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
  };

  ngOnInit(): void {
    this.dataSource = new PartialFullListDataSource(this.retrieve);


    /* Read query parameters from URL */
    this.route.queryParams.subscribe(params => {
      this.editorFilter.setValue(params['editor'], { emitEvent: false });
      this.idPartialFilter.setValue(params['id_partial'] ? params['id_partial'] : null, { emitEvent: false });
      this.insertedFromPartial.setValue(params['insertedFromPartial'] ? moment(params['insertedFromPartial']) : null, { emitEvent: false });
      this.insertedToPartial.setValue(params['insertedToPartial'] ? moment(params['insertedToPartial']) : null, { emitEvent: false });
      this.publishedFromPartial.setValue(params['publishedFromPartial'] ? moment(params['publishedFromPartial']) : null, { emitEvent: false });
      this.publishedToPartial.setValue(params['publishedToPartial'] ? moment(params['publishedToPartial']) : null, { emitEvent: false });
      this.idFullFilter.setValue(params['id_full'] ? params['id_full'] : null, { emitEvent: false });
      this.insertedFromFull.setValue(params['insertedFromFull'] ? moment(params['insertedFromFull']) : null, { emitEvent: false });
      this.insertedToFull.setValue(params['insertedToFull'] ? moment(params['insertedToFull']) : null, { emitEvent: false });
      this.publishedFromFull.setValue(params['publishedFromFull'] ? moment(params['publishedFromFull']) : null, { emitEvent: false });
      this.publishedToFull.setValue(params['publishedToFull'] ? moment(params['publishedToFull']) : null, { emitEvent: false });
      this.similarityThresholdForm.setValue(params['similarityThreshold'] ? params['similarityThreshold']: 0, { emitEvent: false });
      this.pageIndex = Number(params['pageIndex']) || 0;
      this.pageSize = Number(params['pageSize']) || 10;
      this.sortDirection = params['sortDirection'] || 'desc';
      this.sortActive = params['sortActive'] || 'audiomatch_date';

      this.loadContentsPage();
    });

  }


  ngAfterViewInit() {

    this.editorFilter.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['partialfull/'], {
        queryParams: {
          editor: value,
          pageIndex: 0
        },
        queryParamsHandling: 'merge'
      });
    });

    this.idPartialFilter.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['partialfull/'], {
        queryParams: {
          id_partial: value,
          pageIndex: 0
        },
        queryParamsHandling: 'merge'
      });
    });

    this.idFullFilter.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['partialfull/'], {
        queryParams: {
          id_full: value,
          pageIndex: 0
        },
        queryParamsHandling: 'merge'
      });
    });

    this.similarityThresholdForm.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['partialfull/'], {
        queryParams: {
          similarityThreshold: value,
          pageIndex: 0
        },
        queryParamsHandling: 'merge'
      });
    });

    this.sort.sortChange
      .subscribe(() => {
          this.router.navigate(['partialfull/'], {
            queryParams: {
              sortDirection: this.sort.direction,
              sortActive: this.sort.active,
              pageIndex: 0,
            },
            queryParamsHandling: 'merge'
          });
      });

    this.paginator.page
      .subscribe(() => {
        this.router.navigate(['partialfull/'], {
          queryParams: {
            pageIndex: this.paginator.pageIndex,
            pageSize: this.paginator.pageSize
          },
          queryParamsHandling: 'merge'
        });
      });

    /* Dates */
    this.insertedFromPartial.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged()
    ).subscribe(value => {
      this.router.navigate(['partialfull/'], {
        queryParams: {
          pageIndex: 0,
          insertedFromPartial: value ? value.format('YYYY-MM-DD') : null
        },
        queryParamsHandling: 'merge'
      });
    });

    this.insertedToPartial.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged()
    ).subscribe(value => {
      this.router.navigate(['partialfull/'], {
        queryParams: {
          pageIndex: 0,
          insertedToPartial: value ? value.format('YYYY-MM-DD') : null
        },
        queryParamsHandling: 'merge'
      });
    });

    this.publishedFromPartial.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged()
    ).subscribe(value => {
      this.router.navigate(['partialfull/'], {
        queryParams: {
          pageIndex: 0,
          publishedFromPartial: value ? value.format('YYYY-MM-DD') : null
        },
        queryParamsHandling: 'merge'
      });
    });

    this.publishedToPartial.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged()
    ).subscribe(value => {
      this.router.navigate(['partialfull/'], {
        queryParams: {
          pageIndex: 0,
          publishedToPartial: value ? value.format('YYYY-MM-DD') : null
        },
        queryParamsHandling: 'merge'
      });
    });

    this.insertedFromFull.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged()
    ).subscribe(value => {
      this.router.navigate(['partialfull/'], {
        queryParams: {
          pageIndex: 0,
          insertedFromFull: value ? value.format('YYYY-MM-DD') : null
        },
        queryParamsHandling: 'merge'
      });
    });

    this.insertedToFull.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged()
    ).subscribe(value => {
      this.router.navigate(['partialfull/'], {
        queryParams: {
          pageIndex: 0,
          insertedToFull: value ? value.format('YYYY-MM-DD') : null
        },
        queryParamsHandling: 'merge'
      });
    });

    this.publishedFromFull.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged()
    ).subscribe(value => {
      this.router.navigate(['partialfull/'], {
        queryParams: {
          pageIndex: 0,
          publishedFromFull: value ? value.format('YYYY-MM-DD') : null
        },
        queryParamsHandling: 'merge'
      });
    });

    this.publishedToFull.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged()
    ).subscribe(value => {
      this.router.navigate(['partialfull/'], {
        queryParams: {
          pageIndex: 0,
          publishedToFull: value ? value.format('YYYY-MM-DD') : null
        },
        queryParamsHandling: 'merge'
      });
    });



  }

  loadContentsPage(): void {
    /**
     * Load a page of contents based on current parameters
     */

    /* Pagination */
    this.searchParams['offset'] = this.pageIndex * this.pageSize;
    this.searchParams['limit'] = this.pageSize;

    /* Sorting */
    this.searchParams['order'] = this.sortDirection;
    this.searchParams['sort'] = this.sortActive as 'published_partial' | 'published_full' | 'inserted_partial' | 'inserted_full' | 'audiomatch_date';


    /* Filters */
    this.searchParams['editor'] = this.editorFilter.value;
    this.searchParams['id_partial'] = this.idPartialFilter.value;
    this.searchParams['id_full'] = this.idFullFilter.value;
    this.searchParams['similarityThreshold'] = this.similarityThresholdForm.value;


    /* Dates */
    this.searchParams['insertedFromPartial'] = this.insertedFromPartial.value ? this.insertedFromPartial.value.format('YYYY-MM-DD') : null;
    this.searchParams['insertedToPartial'] = this.insertedToPartial.value ? this.insertedToPartial.value.format('YYYY-MM-DD') : null;
    this.searchParams['publishedFromPartial'] = this.publishedFromPartial.value ? this.publishedFromPartial.value.format('YYYY-MM-DD') : null;
    this.searchParams['publishedToPartial'] = this.publishedToPartial.value ? this.publishedToPartial.value.format('YYYY-MM-DD') : null;

    this.searchParams['insertedFromFull'] = this.insertedFromFull.value ? this.insertedFromFull.value.format('YYYY-MM-DD') : null;
    this.searchParams['insertedToFull'] = this.insertedToFull.value ? this.insertedToFull.value.format('YYYY-MM-DD') : null;
    this.searchParams['publishedFromFull'] = this.publishedFromFull.value ? this.publishedFromFull.value.format('YYYY-MM-DD') : null;
    this.searchParams['publishedToFull'] = this.publishedToFull.value ? this.publishedToFull.value.format('YYYY-MM-DD') : null;


    /* Run */
    this.dataSource.loadContents(this.searchParams);
  }

  formatOffset(offset: number){
    return new Date(offset * 1000).toISOString().substring(11, 19);
  }

  download(fileType: string) {
    this.retrieve.countPartialFull(this.searchParams).subscribe(
      count => {
        if(confirm("Are you sure you wanto to export " + count + " partial-full?")) {
          this.retrieve.getPartialFullList({...this.searchParams, forExporting: true}).subscribe(
            data => this.downloadFile(data, fileType, (new Date()).toISOString())
          )
        }
      }
    )
  }
  downloadFile(items, fileType: string, filename='data') {

    let blob 
    if (fileType == 'csv'){
      const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
      const header = Object.keys(items[0])
      const csv = [
        header.join(';'), // header row first
        ...items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(';'))
      ].join('\r\n')
      blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    }
    else if (fileType == 'json')
    {
      blob = new Blob([JSON.stringify(items, null, 2)], {type: 'application/json'});
    }

    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
        dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + "." + fileType);
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }
}
