import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ContentsListComponent } from './contents-list/contents-list.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ContentComponent } from './content/content.component';
import { AddOndemandComponent } from './add-ondemand/add-ondemand.component';
import { ChannelsListComponent } from './channels-list/channels-list.component';
import { ChannelComponent } from './channel/channel.component';
import { OnpremiseserverComponent } from './onpremiseserver/onpremiseserver.component'
import { OnpremiseserversListComponent } from './onpremiseservers-list/onpremiseservers-list.component';
import { MonitoredchannelComponent } from './monitoredchannel/monitoredchannel.component';
import { MonitoredchannelsListComponent } from './monitoredchannels-list/monitoredchannels-list.component';
import { AddMonitoredChannelComponent } from './add-monitored-channel/add-monitored-channel.component';
import { AudiomatchMetricsComponent } from './audiomatch-metrics/audiomatch-metrics.component';
import { MatchesVisualizerComponent } from './matches-visualizer/matches-visualizer.component'
import { PartialfullListComponent } from './partialfull-list/partialfull-list.component';
import { ShowscheduleListComponent } from './showschedule-list/showschedule-list.component';
import { ViewShowscheduleComponent } from './view-showschedule/view-showschedule.component';
import { AudiomatchrunsListComponent } from './audiomatchruns-list/audiomatchruns-list.component';
import { AudiomatchjobsListComponent } from './audiomatchjobs-list/audiomatchjobs-list.component';
import { AudiomatchjobcontentsListComponent } from './audiomatchjobcontents-list/audiomatchjobcontents-list.component';
import { AudiomatchrunComponent } from './audiomatchrun/audiomatchrun.component'
import { AudiomatchchannelsListComponent } from './audiomatchchannels-list/audiomatchchannels-list.component';
import { AudiomatchrunsByDateComponent } from './audiomatchruns-by-date/audiomatchruns-by-date.component';
import { AdvsListComponent } from './advs-list/advs-list.component';

const routes: Routes = [
  {path: 'channels', component: ChannelsListComponent},
  {path: 'channel/:channel', component: ChannelComponent},
  {path: 'contents', component: ContentsListComponent},
  {path: 'dashboard', component: DashboardComponent},
  {path: 'content/:amid', component: ContentComponent},
  {path: 'new', component: AddOndemandComponent},
  {path: 'onpremiseserver/:id', component: OnpremiseserverComponent},
  {path: 'onpremiseservers', component: OnpremiseserversListComponent},
  {path: 'monitoredchannel/:amchid/:id_server/:udp_port', component: MonitoredchannelComponent},
  {path: 'audiomatchchannels', component: AudiomatchchannelsListComponent},
  {path: 'monitoredchannels', component: MonitoredchannelsListComponent},
  {path: 'monitoredchannel/new', component: AddMonitoredChannelComponent},
  {path: 'metrics', component: AudiomatchMetricsComponent},
  {path: 'matchesvisualizer/:amid', component: MatchesVisualizerComponent, runGuardsAndResolvers: 'always'},
  {path: 'partialfull', component: PartialfullListComponent},
  {path: 'showschedule', component: ShowscheduleListComponent},
  {path: 'viewshowschedule', component: ViewShowscheduleComponent},
  {path: 'audiomatchruns', component: AudiomatchrunsListComponent},
  {path: 'audiomatchrunsbydate', component: AudiomatchrunsByDateComponent},
  {path: 'audiomatchjobs', component: AudiomatchjobsListComponent},
  {path: 'audiomatchjobcontents', component: AudiomatchjobcontentsListComponent},
  {path: 'audiomatchrun/:id', component: AudiomatchrunComponent},
  {path: 'advs', component: AdvsListComponent},
  {path: '', redirectTo: 'metrics', pathMatch: 'full' },
  {path: '**', component: PageNotFoundComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
