import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RetrieveService } from '../api/services/retrieve.service';
import { BehaviorSubject, EMPTY, Observable, Subject } from 'rxjs';
import moment from 'moment';
import {Type } from '../api/models/type'
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { FindValueSubscriber } from 'rxjs/internal/operators/find';

export interface DialogData {
  amid: string,
  editor: string;
  channel: string;
  includeLinear: boolean;
  includeOndemand: boolean;
  insertedFrom: string;
  insertedTo: string;
  publishedFrom: string;
  publishedTo: string;
  onairFrom: string;
  onairTo: string;
  selectedContents: Array<string>,
  version: number
}

@Component({
  selector: 'app-match-settings',
  templateUrl: './match-settings.component.html',
  styleUrls: ['./match-settings.component.css']
})
export class MatchSettingsComponent implements OnInit {

  private linearCount = new BehaviorSubject(0);
  private ondemandCount = new BehaviorSubject(0);

  public linearCount$ = this.linearCount.asObservable();
  public ondemandCount$ = this.ondemandCount.asObservable();

  public editor$ = new Subject<string>();
  public channel$ = new Subject<string>();

  public matchingSelectedContent: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<MatchSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private retrieve: RetrieveService
  ) { 
    
    this.editor$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(term => {
        this.data.editor = term;
        this.countCandidates();
        return EMPTY;
      })
    ).subscribe();

    this.channel$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(term => {
        this.data.channel = term;
        this.countCandidates();
        return EMPTY;
      })
    ).subscribe();

  }

  ngOnInit(): void {
    if (this.data.selectedContents.length > 0){
      this.matchingSelectedContent = true;
    }else{
      this.countCandidates();
    }
  }

  public countCandidates() {
    this.data.includeLinear ? this.retrieve.countReferences({
      type: Type.Linear,
      amid: this.data.amid,
      version: this.data.version,
      editor: this.data.editor,
      channel: this.data.channel,
      insertedFrom: this.data.insertedFrom ? moment(this.data.insertedFrom).format('YYYY-MM-DD') : null,
      insertedTo: this.data.insertedTo ? moment(this.data.insertedTo).format('YYYY-MM-DD') : null,
      onairFrom: this.data.onairFrom ? moment(this.data.onairFrom).format('YYYY-MM-DD'): null,
      onairTo: this.data.onairTo ? moment(this.data.onairTo).format('YYYY-MM-DD') : null,
    }).subscribe(count => this.linearCount.next(count)) : this.linearCount.next(0);

    this.data.includeOndemand ? this.retrieve.countReferences({
      type: Type.Ondemand,
      editor: this.data.editor,
      amid: this.data.amid,
      version: this.data.version,
      insertedFrom: this.data.insertedFrom ? moment(this.data.insertedFrom).format('YYYY-MM-DD') : null,
      insertedTo: this.data.insertedTo ? moment(this.data.insertedTo).format('YYYY-MM-DD') : null,
      publishedFrom: this.data.publishedFrom ? moment(this.data.publishedFrom).format('YYYY-MM-DD') : null,
      publishedTo: this.data.publishedTo ? moment(this.data.publishedTo).format('YYYY-MM-DD') : null,
    }).subscribe(count => this.ondemandCount.next(count)): this.ondemandCount.next(0);
  }

}
