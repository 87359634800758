import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { ChannelsListDataSource } from './channels-list-datasource';
import { RetrieveService } from '../api/services/retrieve.service';
import { Channel } from '../api/models/channel';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';


export interface SearchParams {
  editor?: string;
  channel?: string;
  limit?: number;
  offset?: number;
  sort?: 'channel' | 'editor' ;
  order?: 'asc' | 'desc';
}

@Component({
  selector: 'app-channels-list',
  templateUrl: './channels-list.component.html',
  styleUrls: ['./channels-list.component.css']
})
export class ChannelsListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<Channel>;

  /* Pagination */
  pageIndex: number;
  pageSize: number;

  /* Sorting */
  sortActive: string;
  sortDirection: 'asc' | 'desc';

  /** query parameters */
  searchParams: SearchParams = {};

  /** Table data source */
  dataSource: ChannelsListDataSource;

  /* Filters */
  editorFilter = new FormControl();
  channelFilter = new FormControl();

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['editor', 'channel', 'numContents', 'onairFrom', 'onairTo'];

  constructor(
    private retrieve: RetrieveService,
    private route: ActivatedRoute,
    private router: Router
  ) {
  };

  ngOnInit() {

    this.dataSource = new ChannelsListDataSource(this.retrieve);

    /* Read query parameters from URL */
    this.route.queryParams.subscribe(params => {
      this.editorFilter.setValue(params['editor'], { emitEvent: false });
      this.channelFilter.setValue(params['channel'], { emitEvent: false });
      this.pageIndex = Number(params['pageIndex']) || 0;
      this.pageSize = Number(params['pageSize']) || 10;
      this.sortDirection = params['sortDirection'] || 'asc';
      this.sortActive = params['sortActive'] || 'channel';

      this.loadChannelsPage();
    });

  }

  ngAfterViewInit() {

    this.editorFilter.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['channels/'], {
        queryParams: {
          editor: value,
          pageIndex: 0
        },
        queryParamsHandling: 'merge'
      });
    });

    this.channelFilter.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['channels/'], {
        queryParams: {
          channel: value,
          pageIndex: 0
        },
        queryParamsHandling: 'merge'
      });
    });


    this.sort.sortChange
      .subscribe(() => {
        this.router.navigate(['channels/'], {
          queryParams: {
            sortDirection: this.sort.direction,
            sortActive: this.sort.active,
            pageIndex: 0,
          },
          queryParamsHandling: 'merge'
        });
      });

    this.paginator.page
      .subscribe(() => {
        this.router.navigate(['channels/'], {
          queryParams: {
            pageIndex: this.paginator.pageIndex,
            pageSize: this.paginator.pageSize
          },
          queryParamsHandling: 'merge'
        });
      });

  }

  loadChannelsPage(): void {
    /**
     * Load a page of channels based on current parameters
     */

    /* Pagination */
    this.searchParams['offset'] = this.pageIndex * this.pageSize;
    this.searchParams['limit'] = this.pageSize;

    /* Sorting */
    this.searchParams['order'] = this.sortDirection;
    this.searchParams['sort'] = this.sortActive as "channel" | "editor";


    /* Filters */
    this.searchParams['editor'] = this.editorFilter.value;
    this.searchParams['channel'] = this.channelFilter.value;

    /* Run */
    this.dataSource.loadChannels(this.searchParams);
  }

  filterByEditor(editor: string): void {
    this.router.navigate(['channels/'], {
      queryParams: {
        editor: editor,
        pageIndex: 0
      },
      queryParamsHandling: 'merge'
    });
  }

}
