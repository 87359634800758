import { Component, OnInit } from '@angular/core';
import { InsertService } from '../api/services/insert.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NewOndemandContent } from '../api/models/new-ondemand-content';
import { FormControl, FormGroup, Validators, AbstractControl, ValidationErrors, NgModel } from '@angular/forms';
import { ContentUploadService } from '../content-upload.service'
import { OperateService } from '../api/services/operate.service';

function jsonValidator(control: AbstractControl): ValidationErrors | null {
  try {
    JSON.parse(control.value);
  } catch (e) {
    return { jsonInvalid: true };
  }

  return null;
};

@Component({
  selector: 'app-add-ondemand',
  templateUrl: './add-ondemand.component.html',
  styleUrls: ['./add-ondemand.component.css']
})
export class AddOndemandComponent implements OnInit {

  contentForm: FormGroup;
  private audioMeta = {duration:0,size:0};
  private videoMeta = {duration:0,size:0};

  insertionProgress: FormControl;

  constructor(
    private insert: InsertService,
    private operate: OperateService,
    private snackBar: MatSnackBar,
    private upload: ContentUploadService
  ) { }

  ngOnInit(): void {
    const urlExpression = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-\?]*/?';
    this.contentForm = new FormGroup({
      auto: new FormControl(false),
      editor: new FormControl('', [Validators.required, Validators.minLength(2)]),
      id: new FormControl('', [Validators.required, Validators.minLength(8)]),
      url: new FormControl('', [Validators.pattern(urlExpression)]),
      published: new FormControl('', [Validators.required]),
      meta: new FormControl('{}', [jsonValidator]),
      audioFile: new FormControl(),
      videoFile: new FormControl()
    });
    this.insertionProgress = new FormControl(0);

    this.audioFile.valueChanges.subscribe(() => {
      if (this.audioFile.value){
        this.getAudioMeta(this.audioFile.value.files[0]);
      }else{
        this.audioMeta.duration = 0;
      }
     });
     this.videoFile.valueChanges.subscribe(() => {
      if (this.videoFile.value){
        this.getVideoMeta(this.videoFile.value.files[0]);
        this.audioFile.reset();
        this.audioFile.disable();
      }else{
        this.videoMeta.duration = 0;
        this.audioFile.enable();
      }
     });
  }

  get auto() { return this.contentForm.get('auto') }
  get editor() { return this.contentForm.get('editor') }
  get id() { return this.contentForm.get('id') }
  get url() { return this.contentForm.get('url') }
  get published() { return this.contentForm.get('published') }
  get meta() { return this.contentForm.get('meta') }
  get audioFile() { return this.contentForm.get('audioFile'); }
  get videoFile() { return this.contentForm.get('videoFile'); }

  get controls() { return this.contentForm.controls; }



  submit(): void {
    if (this.contentForm.valid) {

      let stages;
      if (this.audioFile.value || this.videoFile.value){
        stages = ['audiofeat'];
      }else{
        stages = ['download','audiofeat'];
      }

      const content: NewOndemandContent = {
        editor: this.editor.value,
        id: this.id.value,
        url: this.url.value,
        published: this.published.value,
        meta: this.meta.value,
        auto: {
          active: this.auto.value,
          stages: stages
        }
      };

      this.insertionProgress.setValue(1);

      this.insert.addOndemandContent({ body: content }).subscribe(
        (res) => {
          const amid = res.amid;
          if (this.audioFile.value || this.videoFile.value) {
            // Upload video if exists, otherwise audio.
            // TODO: Upload both if both exist
            let file: File;
            let ext = '';
            let audioUri = '';
            let videoUri = '';
            let mediaType = '';
            if (this.videoFile.value){
              file = this.videoFile.value.files[0];
              ext = file.name.split('.').pop();
              videoUri = amid + '.' + ext;
              mediaType = 'video';
            } else {
              file = this.audioFile.value.files[0];
              ext = file.name.split('.').pop();
              audioUri = amid + '.' + ext;
              mediaType = 'audio';
            }

            let uploadMonitor = this.upload.fileUpload(file, amid);
            uploadMonitor.on('httpUploadProgress', (progress) => {
              this.insertionProgress.setValue(1 + progress.loaded / progress.total * 97);
            });
            uploadMonitor.send((err, data) => {
              if (err) {
                (err) => this.uploadMediaError(mediaType,err);
              }
              else {
                this.insertionProgress.setValue(99);
                this.operate.setDownloadSuccess({
                  amid: amid, body: {
                    audioUri: audioUri,
                    audioSize: this.audioMeta.size,
                    videoUri: videoUri,
                    videoSize: this.videoMeta.size,
                    duration: this.audioMeta.duration,
                  }
                }).subscribe(
                  () => {
                    this.uploadCompleted(amid);
                  },
                  (err) => this.uploadError(err)
                );
              }

            });
          } else {
            this.uploadCompleted(amid);
          }
        },
        (err) => this.uploadError(err)
      );
    }
  }

  private uploadCompleted(amid:string) {
    this.insertionProgress.setValue(100);
    let snackBarRef = this.snackBar.open(`Added new content: ${amid}`,
      'Done',
      {
        panelClass: ['success-snack-bar']
      });
    snackBarRef.onAction().subscribe(() => this.insertionProgress.setValue(0));
  }

  private uploadError(err){
    const code = err.error.errorCode;
    const message = err.error.message;
    let snackBarRef = this.snackBar.open(`Error while inserting new content. Code: ${code}. Error: ${message}`,
      'Close',
      {
        panelClass: ['alert-snack-bar']
      });
    snackBarRef.onAction().subscribe(() => this.insertionProgress.setValue(0));
  }

  private uploadMediaError(mediatype:string,err){
    const code = err.error.errorCode;
    const message = err.error.message;
    let snackBarRef = this.snackBar.open(`Error while uploading ${mediatype}. Code: ${code}. Error: ${message}`,
      'Close',
      {
        panelClass: ['alert-snack-bar']
      });
    snackBarRef.onAction().subscribe(() => this.insertionProgress.setValue(0));
  }

  private getAudioMeta(file: File) {

    this.audioMeta.size = file.size;
    var audio = document.createElement('audio');
    var meta = this.audioMeta;

    var reader = new FileReader();
    reader.onload = function(e) {
      audio.src = e.target.result as string;
      audio.addEventListener('loadedmetadata',function(){
        meta.duration = audio.duration;
      });
    };
    reader.readAsDataURL(file);
    
  }

  private getVideoMeta(file: File) {

    this.videoMeta.size = file.size;
    var video = document.createElement('video');
    var meta = this.videoMeta;

    var reader = new FileReader();
    reader.onload = function(e) {
      video.src = e.target.result as string;
      video.addEventListener('loadedmetadata',function(){
        meta.duration = video.duration;
      });
    };
    reader.readAsDataURL(file);
    
  }

}
