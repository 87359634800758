import { AfterViewInit, Component, OnInit, ViewChild, } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { ContentsListDataSource } from '../contents-list/contents-list-datasource';
import { RetrieveService } from '../api/services/retrieve.service';
import { OperateService } from '../api/services/operate.service';
import { Content } from '../api/models/content';
import { ContentMeta } from '../api/models/content-meta';
import { debounceTime, distinctUntilChanged, finalize, last, mergeMap } from 'rxjs/operators';
import { FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute, RouterLinkWithHref } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { MatchSettingsComponent } from '../match-settings/match-settings.component';
import { MatchPolicy } from '../api/models/match-policy';
import { Type } from '../api/models/type'
import { TaskStatus } from '../api/models/task-status';
import { AudiomatchTaskStatus } from '../api/models/audiomatch-task-status';
import { DownloadTaskStatus } from '../api/models/download-task-status';
import { AudioMatchRunLegacy } from '../api/models/audio-match-run-legacy'
import { AudioMatchProperty } from '../api/models/audio-match-property'
import { AudioFeatProperty } from '../api/models/audio-feat-property'
import { environment } from '../../environments/environment';
import { combineLatest, interval, Observable, of, timer } from 'rxjs';


interface TaskStatusView {
  value: string;
  viewValue: string;
}

export interface SearchParams {
  type: Type,
  id?: string;
  title?: string;
  insertedFrom?: string;
  insertedTo?: string;
  publishedFrom?: string;
  publishedTo?: string;
  download?: DownloadTaskStatus;
  audiofeat?: TaskStatus;
  indexing?: TaskStatus;
  audiomatch?: AudiomatchTaskStatus;
  hasAudiomatchRuns?: boolean;
  withMatches?: boolean;
  durationFrom?: number;
  durationTo?: number;
  version?: number;
  advSubject?: string;
  limit?: number;
  offset?: number;
  sort?: 'id' | 'editor' | 'published' | 'onair' | 'inserted' | 'audiomatch' | 'download' | 'audiofeat' | 'indexing' |'duration';
  order?: 'asc' | 'desc';
}
@Component({
  selector: 'app-advs-list',
  templateUrl: './advs-list.component.html',
  styleUrls: ['./advs-list.component.css']
})
export class AdvsListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<Content>;

  fingerprintVersions = environment.fingerprintVersions;
  defaultFingerprintVersion=1;

  fingerprintVersion=1;

  /* Pagination */
  pageIndex: number;
  pageSize: number;

  /* Sorting */
  sortActive: string;
  sortDirection: 'asc' | 'desc';

  /** query parameters */
  searchParams: SearchParams = {
    type: Type.Adv,
    version: this.defaultFingerprintVersion,
  };

  /** Table data source */
  dataSource: ContentsListDataSource;

  /* Filters */
  idFilter = new FormControl();
  advSubjectFilter = new FormControl();

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['select', 'id', 'title', 'duration', 'download', 'audiofeat', 'indexing', 'audiomatch', 'inserted', 'published'];

  /* Datepickers helpers */
  insertedFrom = new FormControl();
  insertedTo = new FormControl();
  publishedFrom = new FormControl();
  publishedTo = new FormControl();

  /* Duration*/
  durationFrom = new FormControl('', [
    Validators.pattern("^[0-9]+([\.][0-9]+)?"),,
  ]);
  durationTo = new FormControl('', [
    Validators.pattern("^[0-9]+([\.][0-9]+)?"),
  ]);

  /* Task status */
  taskStatuses: TaskStatusView[] = [
    { value: 'none', viewValue: 'Empty' },
    { value: 'requested', viewValue: 'Requested' },
    { value: 'completed', viewValue: 'Completed' },
    { value: 'error', viewValue: 'Error' }
  ];

  fingerprintVersionFilter = new FormControl();

  downloadStatus = new FormControl();
  audiofeatStatus = new FormControl();
  indexingStatus = new FormControl();
  audiomatchStatus = new FormControl();
  audiomatchRuns = new FormControl();

  /* Audiomatch policy filters*/
  policyOptions = [
    { value: 'exists', viewValue: 'Exists' },
    { value: 'last', viewValue: 'Last Run' },
  ]
  includeOptions = [
    { value: 'true', viewValue: 'True' },
    { value: 'false', viewValue: 'False' },
  ]

  withMatchesPolicy = new FormControl();

  selectedContents: Array<string> = [];

  downloadRequested: number;
  fingerprintRequestedV1: number;
  fingerprintRequestedV2: number;
  queueLengthRequestsCompleted: boolean;

  constructor(
    private retrieve: RetrieveService,
    private operate: OperateService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
  };

  ngOnInit() {

    this.queueLengthRequestsCompleted = false;

    let queueLengthRequests = [this.retrieve.countContents(
      {type: Type.Adv, download: DownloadTaskStatus.Requested}
    ),this.retrieve.countContents(
      {type: Type.Adv, audiofeat: TaskStatus.Requested, version: 1}
    ),this.retrieve.countContents(
      {type: Type.Adv, audiofeat: TaskStatus.Requested, version: 2}
    )
  ]

  const source = timer(0, 60*1000);
  source.subscribe(
    () => {
      combineLatest(queueLengthRequests).subscribe(
        (results) => {
          this.downloadRequested = results[0];
          this.fingerprintRequestedV1 = results[1];
          this.fingerprintRequestedV2 = results[2];
  
          this.queueLengthRequestsCompleted = true;
        }
      )
    }
  )


    this.dataSource = new ContentsListDataSource(this.retrieve);

    /* Read query parameters from URL */
    this.route.queryParams.subscribe(params => {
      this.downloadStatus.setValue(params['download'], { emitEvent: false });
      this.audiofeatStatus.setValue(params['audiofeat'], { emitEvent: false });
      this.indexingStatus.setValue(params['indexing'], { emitEvent: false });
      this.audiomatchStatus.setValue(params['audiomatch'], { emitEvent: false });
      this.audiomatchRuns.setValue(params['audiomatchRuns'], { emitEvent: false });
      this.withMatchesPolicy.setValue(params['withMatches'], { emitEvent: false });
      this.advSubjectFilter.setValue(params['subject'], { emitEvent: false });
      this.idFilter.setValue(params['id'], { emitEvent: false });
      this.insertedFrom.setValue(params['insertedFrom'] ? moment(params['insertedFrom']) : null, { emitEvent: false });
      this.insertedTo.setValue(params['insertedTo'] ? moment(params['insertedTo']) : null, { emitEvent: false });
      this.publishedFrom.setValue(params['publishedFrom'] ? moment(params['publishedFrom']) : null, { emitEvent: false });
      this.publishedTo.setValue(params['publishedTo'] ? moment(params['publishedTo']) : null, { emitEvent: false });
      this.durationFrom.setValue(params['durationFrom'] ? params['durationFrom'] : null, { emitEvent: false });
      this.durationTo.setValue(params['durationTo'] ? params['durationTo'] : null, { emitEvent: false });
      this.pageIndex = Number(params['pageIndex']) || 0;
      this.pageSize = Number(params['pageSize']) || 10;
      this.sortDirection = params['sortDirection'] || 'desc';
      this.sortActive = params['sortActive'] || 'inserted';
      this.fingerprintVersionFilter.setValue(params['version'] ? parseInt(params['version']) : this.defaultFingerprintVersion, { emitEvent: false })

      this.loadContentsPage();
    });

  }

  ngAfterViewInit() {

    this.audiomatchRuns.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['advs/'], {
        queryParams: {
          audiomatchRuns: value,
          pageIndex: 0
        },
        queryParamsHandling: 'merge'
      });
    });

    this.fingerprintVersionFilter.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['advs/'], {
        queryParams: {
          version: value,
          pageIndex: 0
        },
        queryParamsHandling: 'merge'
      });
    });

    this.idFilter.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['advs/'], {
        queryParams: {
          id: value,
          pageIndex: 0
        },
        queryParamsHandling: 'merge'
      });
    });


    this.advSubjectFilter.valueChanges.pipe(
      debounceTime(1500),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['advs/'], {
        queryParams: {
          subject: value,
          pageIndex: 0
        },
        queryParamsHandling: 'merge'
      });
    });

  
    this.durationFrom.valueChanges.pipe(
      debounceTime(250),
      distinctUntilChanged(),
    ).subscribe(value => {
      if (!this.durationFrom.errors){
        this.router.navigate(['advs/'], {
          queryParams: {
            durationFrom: value,
            pageIndex: 0
          },
          queryParamsHandling: 'merge'
        });
      } 
    });


    this.durationTo.valueChanges.pipe(
      debounceTime(250),
      distinctUntilChanged(),
    ).subscribe(value => {
      if (!this.durationTo.errors){
        this.router.navigate(['advs/'], {
          queryParams: {
            durationTo: value,
            pageIndex: 0
          },
          queryParamsHandling: 'merge'
        });
      }
    });

    this.downloadStatus.valueChanges
      .subscribe(value => {
        this.router.navigate(['advs/'], {
          queryParams: {
            download: value,
            pageIndex: 0
          },
          queryParamsHandling: 'merge'
        });
      });

    this.audiomatchStatus.valueChanges
      .subscribe(value => {
        this.router.navigate(['advs/'], {
          queryParams: {
            audiomatch: value,
            pageIndex: 0
          },
          queryParamsHandling: 'merge'
        });
      });

    this.audiofeatStatus.valueChanges
      .subscribe(value => {
        this.router.navigate(['advs/'], {
          queryParams: {
            audiofeat: value,
            pageIndex: 0
          },
          queryParamsHandling: 'merge'
        });
      });
    
    this.indexingStatus.valueChanges
    .subscribe(value => {
      this.router.navigate(['advs/'], {
        queryParams: {
          indexing: value,
          pageIndex: 0
        },
        queryParamsHandling: 'merge'
      });
    });

    this.withMatchesPolicy.valueChanges
    .subscribe(value => {
        this.router.navigate(['advs/'], {
          queryParams: {
            withMatches: value,
            pageIndex: 0
          },
          queryParamsHandling: 'merge'
        });
    });

    this.sort.sortChange
      .subscribe(() => {
          this.router.navigate(['advs/'], {
            queryParams: {
              sortDirection: this.sort.direction,
              sortActive: this.sort.active,
              pageIndex: 0,
            },
            queryParamsHandling: 'merge'
          });
      });

    this.paginator.page
      .subscribe(() => {
        this.router.navigate(['advs/'], {
          queryParams: {
            pageIndex: this.paginator.pageIndex,
            pageSize: this.paginator.pageSize
          },
          queryParamsHandling: 'merge'
        });
      });

    /* Dates */
    this.insertedFrom.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged()
    ).subscribe(value => {
      this.router.navigate(['advs/'], {
        queryParams: {
          pageIndex: 0,
          insertedFrom: value ? value.format('YYYY-MM-DD') : null
        },
        queryParamsHandling: 'merge'
      });
    });

    this.insertedTo.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged()
    ).subscribe(value => {
      this.router.navigate(['advs/'], {
        queryParams: {
          pageIndex: 0,
          insertedTo: value ? value.format('YYYY-MM-DD') : null
        },
        queryParamsHandling: 'merge'
      });
    });

    this.publishedFrom.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged()
    ).subscribe(value => {
      this.router.navigate(['advs/'], {
        queryParams: {
          pageIndex: 0,
          publishedFrom: value ? value.format('YYYY-MM-DD') : null
        },
        queryParamsHandling: 'merge'
      });
    });

    this.publishedTo.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged()
    ).subscribe(value => {
      this.router.navigate(['advs/'], {
        queryParams: {
          pageIndex: 0,
          publishedTo: value ? value.format('YYYY-MM-DD') : null
        },
        queryParamsHandling: 'merge'
      });
    });

  }

  clearSelection() {
    this.selectedContents = [];
  }

  updateContentList(event, amid: string): void {
    if (event.checked){
      this.selectedContents.push(amid);
    }else{
      let contentIdx = this.selectedContents.indexOf(amid);
      if (contentIdx > -1)
      {
        this.selectedContents.splice(contentIdx, 1);
      }
    }
  }

  isSelected(amid:string): boolean {
    let contentIdx = this.selectedContents.indexOf(amid);
    return (contentIdx > -1);
  }

  loadContentsPage(): void {
    /**
     * Load a page of contents based on current parameters
     */

    /* Pagination */
    this.searchParams['offset'] = this.pageIndex * this.pageSize;
    this.searchParams['limit'] = this.pageSize;

    /* Sorting */
    this.searchParams['order'] = this.sortDirection;
    this.searchParams['sort'] = this.sortActive as "id" | "editor" | "published" | "onair" | "inserted";


    /* Filters */
    this.searchParams['id'] = this.idFilter.value;
    this.searchParams['advSubject'] = this.advSubjectFilter.value;
    this.searchParams['version'] = this.fingerprintVersionFilter.value;

    /* Dates */
    this.searchParams['insertedFrom'] = this.insertedFrom.value ? this.insertedFrom.value.format('YYYY-MM-DD') : null;
    this.searchParams['insertedTo'] = this.insertedTo.value ? this.insertedTo.value.format('YYYY-MM-DD') : null;
    this.searchParams['publishedFrom'] = this.publishedFrom.value ? this.publishedFrom.value.format('YYYY-MM-DD') : null;
    this.searchParams['publishedTo'] = this.publishedTo.value ? this.publishedTo.value.format('YYYY-MM-DD') : null;

    /* Duration */
    this.searchParams['durationFrom'] = this.durationFrom.value;
    this.searchParams['durationTo'] = this.durationTo.value;

    /* Tasks */
    this.searchParams['download'] = this.downloadStatus.value;
    this.searchParams['audiofeat'] = this.audiofeatStatus.value;
    this.searchParams['indexing'] = this.indexingStatus.value;
    this.searchParams['audiomatch'] = this.audiomatchStatus.value;
    this.searchParams['hasAudiomatchRuns'] = this.audiomatchRuns.value ? (this.audiomatchRuns.value=='true') :  this.audiomatchRuns.value;

    this.searchParams['withMatches'] = this.withMatchesPolicy.value;

    /* Run */
    this.dataSource.loadContents(this.searchParams);
  }

  queueDownload(amid: string) {
    this.operate.queueDownload({ amid: amid })
      .pipe(finalize(() => {
        this.dataSource.updateContent(amid);
      }))
      .subscribe(
        () => {
          this.snackBar.open("Download requested for: " + amid, '', {
            duration: 3000,
            panelClass: ['success-snack-bar']
          });
        },
        err => {
          const code = err.error.errorCode;
          const message = err.error.message;
          this.snackBar.open(`Error in requesting download for: ${amid}. Code: ${code}. Error: ${message}`, '', {
            duration: 5000,
            panelClass: ['alert-snack-bar']
          })
        });
  }

  queueAudiofeat(amid: string, version: number) {
    this.operate.queueAudiofeat({ amid: amid, version: version })
      .pipe(finalize(() => {
        this.dataSource.updateContent(amid);
      }))
      .subscribe(
        () => {
          this.snackBar.open("Feature extraction requested for: " + amid, '', {
            duration: 3000,
            panelClass: ['success-snack-bar']
          });
        },
        err => {
          const code = err.error.errorCode;
          const message = err.error.message;
          this.snackBar.open(`Error in requesting feature extraction for: ${amid}. Code: ${code}. Error: ${message}`, '', {
            duration: 5000,
            panelClass: ['alert-snack-bar']
          })
        });
  }

  queueIndexing(amid: string, version: number) {
    this.operate.queueIndexing({ amid: amid, version:version, delete_fingerprint: false})
      .pipe(finalize(() => {
        this.dataSource.updateContent(amid);
      }))
      .subscribe(
        () => {
          this.snackBar.open("Indexing requested for: " + amid, '', {
            duration: 3000,
            panelClass: ['success-snack-bar']
          });
        },
        err => {
          const code = err.error.errorCode;
          const message = err.error.message;
          this.snackBar.open(`Error in requesting indexing for: ${amid}. Code: ${code}. Error: ${message}`, '', {
            duration: 5000,
            panelClass: ['alert-snack-bar']
          })
        });
  }

  queueAudiomatch(amid: string, version: number, body: any) {
    this.operate.queueAudiomatch({ amid: amid, version: version, body: body })
      .pipe(finalize(() => {
        this.dataSource.updateContent(amid);
      }))
      .subscribe(
        () => {
          this.snackBar.open("Matching requested for: " + amid, '', {
            duration: 3000,
            panelClass: ['success-snack-bar']
          });
        },
        err => {
          const code = err.error.errorCode;
          const message = err.error.message;
          this.snackBar.open(`Error in requesting matching for: ${amid}. Code: ${code}. Error: ${message}`, '', {
            duration: 5000,
            panelClass: ['alert-snack-bar']
          })
        });
  }

  filterByEditor(editor: string): void {
    this.router.navigate(['advs/'], {
      queryParams: {
        editor: editor,
        pageIndex: 0
      },
      queryParamsHandling: 'merge'
    });
  }

  openAudiomatchSettings(amid: string, editor: string,
    inserted: string, published: string, meta: ContentMeta, version: number): void {
    
    const insertedDate = moment(inserted); 
    const publishedDate = moment(published);
    
    let channel = '';
    if (meta.ChannelBrandDescription)
    {
      channel = meta.ChannelBrandDescription.replace(/\s/g, "");
    }

    let onairFrom = moment(insertedDate).subtract(7, 'd').format("YYYY-MM-DD")
    let onairTo = moment(insertedDate).add(7, 'd').format("YYYY-MM-DD")

    if (publishedDate)
    {
      onairFrom = publishedDate.format("YYYY-MM-DD")
      onairTo = publishedDate.add(3, 'd').format("YYYY-MM-DD")
    }

    if (this.selectedContents.length > 0){
      let contentIdx = this.selectedContents.indexOf(amid);
      if (contentIdx > -1)
      {
        this.selectedContents.splice(contentIdx, 1);
      }
    }

    const dialogData = {
      amid: amid,
      editor: editor,
      channel: channel,
      insertedFrom: moment(insertedDate).subtract(7, 'd').format("YYYY-MM-DD"),
      insertedTo: moment(insertedDate).add(7, 'd').format("YYYY-MM-DD"),
      publishedFrom: moment(publishedDate).subtract(7, 'd').format("YYYY-MM-DD"),
      publishedTo: moment(publishedDate).add(7, 'd').format("YYYY-MM-DD"),
      onairFrom: onairFrom,
      onairTo: onairTo,
      includeLinear: true,
      includeOndemand: false,
      selectedContents: this.selectedContents,
      version:version,
    }

    const dialogRef = this.dialog.open(MatchSettingsComponent, {data: dialogData});

    dialogRef.afterClosed().subscribe(result => {
      if (!(result == null || result == '')) {
        let params: MatchPolicy = {};
        if (result.selectedContents.length == 0){
          params.includeLinear = result.includeLinear;
          params.includeOndemand = result.includeOndemand;
          if (result.insertedFrom) {
            params.insertedFrom = moment(result.insertedFrom).format('YYYY-MM-DD');
          }
          if (result.insertedTo) {
            params.insertedTo = moment(result.insertedTo).format('YYYY-MM-DD');
          }
          if (params.includeOndemand && result.publishedFrom) {
            params.publishedFrom = moment(result.publishedFrom).format('YYYY-MM-DD');
          }
          if (params.includeOndemand && result.publishedTo) {
            params.publishedTo = moment(result.publishedTo).format('YYYY-MM-DD');
          }
          if (params.includeLinear && result.onairFrom) {
            params.onairFrom = moment(result.onairFrom).format('YYYY-MM-DD');
          }
          if (params.includeLinear && result.onairTo) {
            params.onairTo = moment(result.onairTo).format('YYYY-MM-DD');
          }
          if (result.editor) {
            params.editor = result.editor;
          }
          if (result.channel) {
            params.channel = result.channel;
          }
        }else{
          params.listOfReferences = result.selectedContents;
        }
        this.queueAudiomatch(amid, result.version, params);
      }
    });

  }

  getLastAudiomatchRun(audioMatchProperty: AudioMatchProperty, amid: string) {
    
    if (audioMatchProperty.status == 'none' ){
      return audioMatchProperty
    }

    if (audioMatchProperty.status == 'completed' || audioMatchProperty.status == 'error' || audioMatchProperty.status == 'requested') {
      let idx = 0;
      let lastRun: AudioMatchRunLegacy;
      for (let runKey of Object.keys(audioMatchProperty.runs)) {
        let run: AudioMatchRunLegacy = audioMatchProperty.runs[runKey]

        if (idx == 0){
          lastRun = run;
        }else{
          if (run.lastUpdate >= lastRun.lastUpdate){
            lastRun = run;
          }
        }
        idx++;
      }
      lastRun['taskType'] = 'audiomatch';
      lastRun['amid'] = amid;
      return lastRun
    }
  }


  getIndexingTask(audioFeatProperty: AudioFeatProperty){
    
    let indexing_task

    if (audioFeatProperty.indexing_status == 'completed'){
      indexing_task = {
        'status': audioFeatProperty.indexing_status,
        'elapsed': audioFeatProperty.indexing_elapsed,
        'lastUpdate': audioFeatProperty.indexing_lastUpdate
      };
    }else if (audioFeatProperty.indexing_status == 'requested'){
      indexing_task = {
        'status': audioFeatProperty.indexing_status,
        'lastUpdate': audioFeatProperty.indexing_lastUpdate
      };
    }else if (audioFeatProperty.indexing_status == 'error'){
      indexing_task = {
        'status': audioFeatProperty.indexing_status,
        'lastUpdate': audioFeatProperty.indexing_lastUpdate,
        'error': audioFeatProperty['indexing_error']
      };
    } else
    {
      indexing_task = {
        'status': 'none'
      };
    }
    return indexing_task;
  }

  download(fileType: string) {
      this.retrieve.countContents(this.searchParams).subscribe(
        count => {
          if(confirm("Are you sure you wanto to export " + count + " contents?")) {
            this.retrieve.getContentsList({...this.searchParams, forExporting: true}).subscribe(
              data => this.downloadFile(data, fileType, (new Date()).toISOString())
            )
          }
        }
      )
  }
  downloadFile(items, fileType: string, filename='data') {

    let blob 
    if (fileType == 'csv'){
      const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
      const header = Object.keys(items[0])
      const csv = [
        header.join(';'), // header row first
        ...items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(';'))
      ].join('\r\n')
      blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    }
    else if (fileType == 'json')
    {
      blob = new Blob([JSON.stringify(items, null, 2)], {type: 'application/json'});
    }

    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
        dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + "." + fileType);
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  getCardClass(queueLenght: number){
    if (queueLenght < 500){
      return "card-header card-header-success card-header-icon"
    }else if (queueLenght >= 500 && queueLenght < 1000){
      return "card-header card-header-warning card-header-icon"
    }else{
      return "card-header card-header-danger card-header-icon"
    }
   
  }

}

