import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RetrieveService } from '../api/services/retrieve.service';
import { BehaviorSubject, EMPTY, Observable, Subject } from 'rxjs';
import moment from 'moment';
import {Type } from '../api/models/type'
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { MonitoredChannel } from '../api/models/monitored-channel';
import { MonitoredChannelStatus } from '../api/models/monitored-channel-status';
import { ShowScheduleStatus } from '../api/models/show-schedule-status';
import { environment } from '../../environments/environment';
import { FormControl } from '@angular/forms';



export interface DialogData {
  monitoredChannel: MonitoredChannel
  processingDate: string,
  fpversion: number,
}

@Component({
  selector: 'app-showschedule-match-settings',
  templateUrl: './showschedule-match-settings.component.html',
  styleUrls: ['./showschedule-match-settings.component.css']
})
export class ShowscheduleMatchSettingsComponent implements OnInit {

  channels$: Observable<MonitoredChannel[]>;

  fingerprintVersions = environment.fingerprintVersions;
  defaultFingerprintVersion=1;

  constructor(
    public dialogRef: MatDialogRef<ShowscheduleMatchSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private retrieve: RetrieveService
  ) { }

  ngOnInit(): void {
    this.data.fpversion = this.defaultFingerprintVersion;
    this.loadChannels()
  }

  loadChannels(){
    this.channels$ = this.retrieve.getMonitoredChannelsList({
      status: MonitoredChannelStatus.Running,
      showscheduleStatus: ShowScheduleStatus.On,
      fpversion: this.data.fpversion ,
      limit: -1,
    })
  }

}
