/* tslint:disable */
/* eslint-disable */

/**
 * Monitored Channel status
 */
export enum MonitoredChannelStatus {
  None = 'none',
  Pending = 'pending',
  Running = 'running',
  Error = 'error',
  Stopping = 'stopping',
  Stopped = 'stopped'
}
