import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { AudiomatchrunsListDataSource } from '../audiomatchruns-list/audiomatchruns-list-datasource'
import { RetrieveService } from '../api/services/retrieve.service';
import { AudioMatchRun } from '../api/models/audio-match-run';
import { TaskStatus } from '../api/models/task-status';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { Router, ActivatedRoute, RouterLinkWithHref } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import moment from 'moment';
import { MatDialog } from '@angular/material/dialog';


export interface SearchParams {
  id?: string,
  query_amid?: string;
  status?: TaskStatus;
  version?: number;
  last_update_from?: string;
  last_update_to?: string;
  inserted_from?: string;
  inserted_to?: string;
  count_grouped_by_date?: boolean;
  limit?: number;
  offset?: number;
}

interface TaskStatusView {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-audiomatchruns-by-date',
  templateUrl: './audiomatchruns-by-date.component.html',
  styleUrls: ['./audiomatchruns-by-date.component.css']
})
export class AudiomatchrunsByDateComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<AudioMatchRun>;

  /* Task status */
  taskStatuses: TaskStatusView[] = [
    { value: 'none', viewValue: 'Empty' },
    { value: 'requested', viewValue: 'Requested' },
    { value: 'completed', viewValue: 'Completed' },
    { value: 'error', viewValue: 'Error' }
  ];

  /* Pagination */
  pageIndex: number;
  pageSize: number;

  /* Sorting */
  sortActive: string;
  sortDirection: 'asc' | 'desc';

  /** query parameters */
  searchParams: SearchParams = {
    count_grouped_by_date: true
  };

  /** Table data source */
  dataSource: AudiomatchrunsListDataSource;

  /**  Filters */
  idFilter = new FormControl();
  queryAmidFilter = new FormControl();
  statusFilter = new FormControl();
  versionFilter = new FormControl();

  lastUpdateTo = new FormControl();
  lastUpdateFrom = new FormControl();

  insertedTo = new FormControl();
  insertedFrom = new FormControl();

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['date', 'count', 'runs'];

  constructor(
    private retrieve: RetrieveService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
  };

  ngOnInit() {

    this.dataSource = new AudiomatchrunsListDataSource(this.retrieve);

    /* Read query parameters from URL */
    this.route.queryParams.subscribe(params => {
      this.idFilter.setValue(params['id'], { emitEvent: false });
      this.queryAmidFilter.setValue(params['queryAmid'], { emitEvent: false });
      this.statusFilter.setValue(params['status'], { emitEvent: false });
      this.versionFilter.setValue(params['version'], { emitEvent: false });
      this.lastUpdateFrom.setValue(params['lastUpdateFrom'] ? moment(params['lastUpdateFrom']) : null, { emitEvent: false });
      this.lastUpdateTo.setValue(params['lastUpdateTo'] ? moment(params['lastUpdateTo']) : null, { emitEvent: false });
      this.insertedFrom.setValue(params['insertedFrom'] ? moment(params['insertedFrom']) : null, { emitEvent: false });
      this.insertedTo.setValue(params['insertedTo'] ? moment(params['insertedTo']) : null, { emitEvent: false });
      this.pageIndex = Number(params['pageIndex']) || 0;
      this.pageSize = Number(params['pageSize']) || 10;
      this.sortDirection = params['sortDirection'] || 'desc';
      this.sortActive = params['sortActive'] || 'last_update';
      this.loadAudiomatchRunsPage();
    });

  }

  ngAfterViewInit() {

    this.idFilter.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['audiomatchrunsbydate/'], {
        queryParams: {
          id: value,
          pageIndex: 0
        },
        queryParamsHandling: 'merge'
      });
    });

    this.statusFilter.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['audiomatchrunsbydate/'], {
        queryParams: {
          status: value,
          pageIndex: 0
        },
        queryParamsHandling: 'merge'
      });
    });

    this.versionFilter.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['audiomatchrunsbydate/'], {
        queryParams: {
          version: value,
          pageIndex: 0
        },
        queryParamsHandling: 'merge'
      });
    });


    this.queryAmidFilter.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['audiomatchrunsbydate/'], {
        queryParams: {
          queryAmid: value,
          pageIndex: 0
        },
        queryParamsHandling: 'merge'
      });
    });

    /* Dates */
    this.lastUpdateFrom.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged()
    ).subscribe(value => {
      this.router.navigate(['audiomatchrunsbydate/'], {
        queryParams: {
          pageIndex: 0,
          lastUpdateFrom: value ? value.format('YYYY-MM-DD') : null
        },
        queryParamsHandling: 'merge'
      });
    });

    this.lastUpdateTo.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged()
    ).subscribe(value => {
      this.router.navigate(['audiomatchrunsbydate/'], {
        queryParams: {
          pageIndex: 0,
          lastUpdateTo: value ? value.format('YYYY-MM-DD') : null
        },
        queryParamsHandling: 'merge'
      });
    });

    this.insertedFrom.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged()
    ).subscribe(value => {
      this.router.navigate(['audiomatchrunsbydate/'], {
        queryParams: {
          pageIndex: 0,
          insertedFrom: value ? value.format('YYYY-MM-DD') : null
        },
        queryParamsHandling: 'merge'
      });
    });

    this.insertedTo.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged()
    ).subscribe(value => {
      this.router.navigate(['audiomatchrunsbydate/'], {
        queryParams: {
          pageIndex: 0,
          insertedTo: value ? value.format('YYYY-MM-DD') : null
        },
        queryParamsHandling: 'merge'
      });
    });

    this.sort.sortChange
      .subscribe(() => {
        this.router.navigate(['audiomatchrunsbydate/'], {
          queryParams: {
            sortDirection: this.sort.direction,
            sortActive: this.sort.active,
            pageIndex: 0,
          },
          queryParamsHandling: 'merge'
        });
      });

    this.paginator.page
      .subscribe(() => {
        this.router.navigate(['audiomatchrunsbydate/'], {
          queryParams: {
            pageIndex: this.paginator.pageIndex,
            pageSize: this.paginator.pageSize
          },
          queryParamsHandling: 'merge'
        });
      });
  }

  loadAudiomatchRunsPage(): void {
    /**
     * Load a page of onpremise servers based on current parameters
     */

    /* Pagination */
    this.searchParams['offset'] = this.pageIndex * this.pageSize;
    this.searchParams['limit'] = this.pageSize;


    /* Filters */
    this.searchParams['id'] = this.idFilter.value ? this.idFilter.value: null;
    this.searchParams['status'] = this.statusFilter.value ? this.statusFilter.value: null;
    this.searchParams['query_amid'] = this.queryAmidFilter.value ? this.queryAmidFilter.value: null;
    this.searchParams['version'] = this.versionFilter.value ? this.versionFilter.value: null;

    /* Dates */
    this.searchParams['last_update_from'] = this.lastUpdateFrom.value ? this.lastUpdateFrom.value.format('YYYY-MM-DD') : null;
    this.searchParams['last_update_to'] = this.lastUpdateTo.value ? this.lastUpdateTo.value.format('YYYY-MM-DD') : null;
    this.searchParams['inserted_from'] = this.insertedFrom.value ? this.insertedFrom.value.format('YYYY-MM-DD') : null;
    this.searchParams['inserted_to'] = this.insertedTo.value ? this.insertedTo.value.format('YYYY-MM-DD') : null;


    /* Run */
    this.dataSource.loadAudiomatchRuns(this.searchParams);
  }

  getInsertedTo(date){
    return moment(date).add(1, 'day').format('YYYY-MM-DD')
  }

}
