import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { AudiomatchChannelsListDataSource } from './audiomatchchannels-list-datasource'
import { RetrieveService } from '../api/services/retrieve.service';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { Router, ActivatedRoute, RouterLinkWithHref } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { AudiomatchChannel } from '../api/models/audiomatch-channel';


export interface SearchParams {
  channel?: string,
  editor?: string,
  isMonitored?: boolean,
  monitoringStatus?: 'none' | 'error' | 'running' | 'warning',
  limit?: number;
  offset?: number;
}

@Component({
  selector: 'app-audiomatchchannels-list',
  templateUrl: './audiomatchchannels-list.component.html',
  styleUrls: ['./audiomatchchannels-list.component.css']
})
export class AudiomatchchannelsListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<AudiomatchChannel>;


  /* Pagination */
  pageIndex: number;
  pageSize: number;

  /** query parameters */
  searchParams: SearchParams = {
    'isMonitored': true
  };

  /** Table data source */
  dataSource: AudiomatchChannelsListDataSource;

  /**  Filters */
  channelFilter = new FormControl();
  editorFiter = new FormControl();
  monitoringStatusFilter = new FormControl();

  monitoringStatuses = ['running', 'error', 'warning'];

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['amchid', 'editor', 'channel', 'monitoring_status', 'monitoredChannels'];


  constructor(
    private retrieve: RetrieveService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
  };

  ngOnInit(): void {

    this.dataSource = new AudiomatchChannelsListDataSource(this.retrieve);

    /* Read query parameters from URL */
    this.route.queryParams.subscribe(params => {
      this.editorFiter.setValue(params['editor'], { emitEvent: false });
      this.channelFilter.setValue(params['channel'], { emitEvent: false });
      this.monitoringStatusFilter.setValue(params['monitoringStatus'], { emitEvent: false });
      this.pageIndex = Number(params['pageIndex']) || 0;
      this.pageSize = Number(params['pageSize']) || 10;
      this.loadAudiomatchChannelsPage();
    });
  }

  ngAfterViewInit() {

    this.channelFilter.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['audiomatchchannels/'], {
        queryParams: {
          channel: value,
          pageIndex: 0
        },
        queryParamsHandling: 'merge'
      });
    });

    this.editorFiter.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['audiomatchchannels/'], {
        queryParams: {
          editor: value,
          pageIndex: 0
        },
        queryParamsHandling: 'merge'
      });
    });

    this.monitoringStatusFilter.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['audiomatchchannels/'], {
        queryParams: {
          monitoringStatus: value,
          pageIndex: 0
        },
        queryParamsHandling: 'merge'
      });
    });


    this.paginator.page
      .subscribe(() => {
        this.router.navigate(['audiomatchchannels/'], {
          queryParams: {
            pageIndex: this.paginator.pageIndex,
            pageSize: this.paginator.pageSize
          },
          queryParamsHandling: 'merge'
        });
      });
  }

  loadAudiomatchChannelsPage(): void {
    /**
     * Load a page of onpremise servers based on current parameters
     */

    /* Pagination */
    this.searchParams['offset'] = this.pageIndex * this.pageSize;
    this.searchParams['limit'] = this.pageSize;

    /* Filters */
    this.searchParams['editor'] = this.editorFiter.value ? this.editorFiter.value: null;
    this.searchParams['channel'] = this.channelFilter.value ? this.channelFilter.value: null;
    this.searchParams['monitoringStatus'] = this.monitoringStatusFilter.value ? this.monitoringStatusFilter.value as 'none' | 'error' | 'running' | 'warning': null;
    
    /* Run */
    this.dataSource.loadAudiomatchChannels(this.searchParams);
  }

  getStatusColor(channel: AudiomatchChannel){
    if (channel.monitoring_status == 'running'){
      return '#90EE91';
    }else if  (channel.monitoring_status == 'warning'){
      return '#FFEB00';
    }else if (channel.monitoring_status == 'error'){
      return '#FF496D';
    }else{
      return '#ADD8E6'
    }
  }
}

