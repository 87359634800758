import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '../../environments/environment';
import { InsertService } from '../api/services/insert.service';
import { NewAudiomatchChannel } from '../api/models/new-audiomatch-channel';
import { MatSnackBar } from '@angular/material/snack-bar';


export interface DialogData {
  amchid: string,
  editor: string,
  channel: string
}

@Component({
  selector: 'app-add-audiomatch-channel',
  templateUrl: './add-audiomatch-channel.component.html',
  styleUrls: ['./add-audiomatch-channel.component.css']
})
export class AddAudiomatchChannelComponent implements OnInit {

  audiomatchChannelForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<AddAudiomatchChannelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private insert: InsertService,
    private snackBar: MatSnackBar,
  ) { 

  }

  ngOnInit(): void {
    this.audiomatchChannelForm = new FormGroup({
      amchid: new FormControl(this.data ? this.data.amchid : '', [Validators.required]),
      editor: new FormControl(this.data ? this.data.editor : '', [Validators.required]),
      channel: new FormControl(this.data ? this.data.channel : '', [Validators.required]),
    }); 
  }

  submit(): void {

    const newAudiomatchChannel: NewAudiomatchChannel = {
      amchid: this.audiomatchChannelForm.get('amchid').value,
      editor: this.audiomatchChannelForm.get('editor').value,
      channel: this.audiomatchChannelForm.get('channel').value,
    };

    this.insert.addAudiomatchChannel({body: newAudiomatchChannel}).subscribe(
      (res) => {this.dialogRef.close()},
      (err) => {this.insertError(err)}
    )
  }

  private insertError(err){
    const code = err.error.errorCode;
    const message = err.error.message;
    let snackBarRef = this.snackBar.open(`Error while inserting new linear channel. Code: ${code}. Error: ${message}`,
      'Close',
      {
        panelClass: ['alert-snack-bar']
      });
  }

  closeDialog(){
    this.dialogRef.close(false);
  }

}
