// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  fingerprintVersions: [1,2],
  apiEndpoint: 'https://6aqsb5qag2.execute-api.eu-central-1.amazonaws.com/dev',
  contentBucket: 'audiomatch-backend-dev-contentbucket-1xkex93akf7li',
  accessKeyId: 'AKIAUOAKCOT35KVSBOGQ',
  secretAccessKey: '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',
  region: 'eu-central-1',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
