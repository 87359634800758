import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { AudiomatchjobContentsListDataSource } from './audiomatchjobcontents-list-datasource'
import { RetrieveService } from '../api/services/retrieve.service';
import { TaskStatus } from '../api/models/task-status';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { Router, ActivatedRoute, RouterLinkWithHref } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { AudioMatchJobContents } from '../api/models/audio-match-job-contents';

export interface SearchParams {
  query_amid?: string,
  reference_amid?: string,
  job_id?: string,
  status?: TaskStatus;
  limit?: number;
  offset?: number;
  sort?: 'query_amid' | 'last_update';
  order?: 'asc' | 'desc';
}

interface TaskStatusView {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-audiomatchjobcontents-list',
  templateUrl: './audiomatchjobcontents-list.component.html',
  styleUrls: ['./audiomatchjobcontents-list.component.css']
})
export class AudiomatchjobcontentsListComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<AudioMatchJobContents>;

  /* Task status */
  taskStatuses: TaskStatusView[] = [
    { value: 'none', viewValue: 'Empty' },
    { value: 'requested', viewValue: 'Requested' },
    { value: 'completed', viewValue: 'Completed' },
    { value: 'error', viewValue: 'Error' }
  ];

  /* Pagination */
  pageIndex: number;
  pageSize: number;

  /* Sorting */
  sortActive: string;
  sortDirection: 'asc' | 'desc';

  /** query parameters */
  searchParams: SearchParams = {
  };

  /** Table data source */
  dataSource: AudiomatchjobContentsListDataSource;

  /**  Filters */
  jobIdFilter = new FormControl();
  queryAmidFilter = new FormControl();
  referenceAmidFilter = new FormControl();
  statusFilter = new FormControl();

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['query_amid', 'references_amids', 'job_id', 'status', 'elapsed', 'last_update'];

  constructor(
    private retrieve: RetrieveService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
  };

  ngOnInit() {

    this.dataSource = new AudiomatchjobContentsListDataSource(this.retrieve);

    /* Read query parameters from URL */
    this.route.queryParams.subscribe(params => {
      this.jobIdFilter.setValue(params['jobId'], { emitEvent: false });
      this.queryAmidFilter.setValue(params['queryAmid'], { emitEvent: false });
      this.referenceAmidFilter.setValue(params['referenceAmid'], { emitEvent: false });
      this.statusFilter.setValue(params['status'], { emitEvent: false });
      this.pageIndex = Number(params['pageIndex']) || 0;
      this.pageSize = Number(params['pageSize']) || 10;
      this.sortDirection = params['sortDirection'] || 'desc';
      this.sortActive = params['sortActive'] || 'last_update';
      this.loadAudiomatchJobContentsPage();
    });
  }

  ngAfterViewInit() {

    this.jobIdFilter.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['audiomatchjobcontents/'], {
        queryParams: {
          jobId: value,
          pageIndex: 0
        },
        queryParamsHandling: 'merge'
      });
    });

    this.queryAmidFilter.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['audiomatchjobcontents/'], {
        queryParams: {
          queryAmid: value,
          pageIndex: 0
        },
        queryParamsHandling: 'merge'
      });
    });

    this.referenceAmidFilter.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['audiomatchjobcontents/'], {
        queryParams: {
          referenceAmid: value,
          pageIndex: 0
        },
        queryParamsHandling: 'merge'
      });
    });


    this.statusFilter.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['audiomatchjobcontents/'], {
        queryParams: {
          status: value,
          pageIndex: 0
        },
        queryParamsHandling: 'merge'
      });
    });


    this.sort.sortChange
      .subscribe(() => {
        this.router.navigate(['audiomatchjobcontents/'], {
          queryParams: {
            sortDirection: this.sort.direction,
            sortActive: this.sort.active,
            pageIndex: 0,
          },
          queryParamsHandling: 'merge'
        });
      });

    this.paginator.page
      .subscribe(() => {
        this.router.navigate(['audiomatchjobcontents/'], {
          queryParams: {
            pageIndex: this.paginator.pageIndex,
            pageSize: this.paginator.pageSize
          },
          queryParamsHandling: 'merge'
        });
      });
  }

  loadAudiomatchJobContentsPage(): void {
    /**
     * Load a page of audiomatch job contents based on current parameters
     */

    /* Pagination */
    this.searchParams['offset'] = this.pageIndex * this.pageSize;
    this.searchParams['limit'] = this.pageSize;

    /* Sorting */
    this.searchParams['order'] = this.sortDirection;
    this.searchParams['sort'] = this.sortActive as 'query_amid' | 'last_update';


    /* Filters */
    this.searchParams['job_id'] = this.jobIdFilter.value ? this.jobIdFilter.value: null;
    this.searchParams['query_amid'] = this.queryAmidFilter.value ? this.queryAmidFilter.value: null;
    this.searchParams['reference_amid'] = this.referenceAmidFilter.value ? this.referenceAmidFilter.value: null;
    this.searchParams['status'] = this.statusFilter.value ? this.statusFilter.value: null;
    
    /* Run */
    this.dataSource.loadAudiomatchJobContents(this.searchParams);
  }


}
