import { Component, OnInit } from '@angular/core';
import { RetrieveService } from '../api/services/retrieve.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  fingerprintVersion = 1;
  cards;

  constructor(
    private retrieve: RetrieveService) {

    
  }

  ngOnInit(): void {
    this.updateCounters();
  }

  updateCounters(): void {

    this.cards = [
      {
        title: 'Indexed',
        description: '',
        params: { type: 'ondemand' },
        cols: 3,
        rows: 1,
        icon: "table_chart",
        type: "success"
      },
      {
        title: 'Download completed',
        description: '',
        params: { type: 'ondemand', download:'completed' },
        cols: 1,
        rows: 1,
        icon: "download",
        type: "success"
      },
      {
        title: 'Download pending',
        description: '',
        params: { type: 'ondemand', download:'requested' },
        cols: 1,
        rows: 1,
        icon: "download",
        type: "warning"
      },
      {
        title: 'Download error',
        description: '',
        params: { type: 'ondemand', download:'error' },
        cols: 1,
        rows: 1,
        icon: "download",
        type: "danger"
      },

      {
        title: 'AudioFeat completed',
        description: '',
        params: { type: 'ondemand', audiofeat:'completed', version:this.fingerprintVersion },
        cols: 1,
        rows: 1,
        icon: "fingerprint",
        type: "success"
      },
      {
        title: 'AudioFeat pending',
        description: '',
        params: { type: 'ondemand', audiofeat:'requested', version:this.fingerprintVersion },
        cols: 1,
        rows: 1,
        icon: "fingerprint",
        type: "warning"
      },
      {
        title: 'AudioFeat error',
        description: '',
        params: { type: 'ondemand', audiofeat:'error', version:this.fingerprintVersion },
        cols: 1,
        rows: 1,
        icon: "fingerprint",
        type: "danger"
      },

      {
        title: 'AudioMatch completed',
        description: '',
        params: { type: 'ondemand', audiomatch:'completed', version:this.fingerprintVersion },
        cols: 1,
        rows: 1,
        icon: "compare_arrows",
        type: "success"
      },
      {
        title: 'AudioMatch pending',
        description: '',
        params: { type: 'ondemand', audiomatch:'requested', version:this.fingerprintVersion },
        cols: 1,
        rows: 1,
        icon: "compare_arrows",
        type: "warning"
      },
      {
        title: 'AudioMatch error',
        description: '',
        params: { type: 'ondemand', audiomatch:'error', version:this.fingerprintVersion },
        cols: 1,
        rows: 1,
        icon: "compare_arrows",
        type: "danger"
      },
    ];

    this.cards.forEach(element => {
      element['value'] = this.retrieve.countContents(element['params']);
    });

  }



}
