import { DataSource, CollectionViewer } from '@angular/cdk/collections';
import { catchError, finalize } from 'rxjs/operators';
import { Observable, of as observableOf, merge, BehaviorSubject, of } from 'rxjs';
import { RetrieveService } from '../api/services/retrieve.service';
import { AudioMatchJob } from '../api/models/audio-match-job';
import { SearchParams } from './audiomatchjobs-list.component';

/**
 * Data source for the  AudiomatchrunsList view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class AudiomatchjobsListDataSource implements DataSource<AudioMatchJob> {

  private audiomatchJobsSubject = new BehaviorSubject<AudioMatchJob[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private audiomatchJobsCount = new BehaviorSubject(0);

  public loading$ = this.loadingSubject.asObservable();
  public count$ = this.audiomatchJobsCount.asObservable();

  constructor(private retrieveService: RetrieveService) {
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(collectionViewer: CollectionViewer): Observable<AudioMatchJob[]> {
    return this.audiomatchJobsSubject.asObservable();
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect(collectionViewer: CollectionViewer): void {
    this.audiomatchJobsSubject.complete();
    this.loadingSubject.complete();
  }

  loadAudiomatchJobs(params: SearchParams) {

    this.loadingSubject.next(true);

    /* Retrieve audiomatch runs */
    this.retrieveService.getAudiomatchJobsList(params).pipe(
      catchError(() => {
        // console.error('getContentsList ERROR');
        return of([]);
      }),
      finalize(() => {
        // console.log('getContentsList OK');
        this.loadingSubject.next(false);
      })
    ).subscribe(contents => {
      // console.log(contents);
      this.audiomatchJobsSubject.next(contents);
    });

    /* Count audiomatch runs */
    this.retrieveService.countAudiomatchJobs(params).pipe(
      catchError(() => {
        return of([]);
      }),
      finalize(() => {
        this.loadingSubject.next(false);
      })
    ).subscribe(count => {
      this.audiomatchJobsCount.next(count as number);
    });
  }
}

