import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import S3 from 'aws-sdk/clients/s3';

@Injectable({
  providedIn: 'root'
})
export class ContentUploadService {

  constructor() { }

  fileUpload(file: File, amid: string) {
    const contentType = file.type;
    const ext = file.name.split('.').pop();
    const bucket = new S3(
      {
        accessKeyId: environment.accessKeyId,
        secretAccessKey: environment.secretAccessKey,
        region: environment.region,
      }
    );
    const params = {
      Bucket: environment.contentBucket,
      Key: amid + '.' + ext,
      Body: file,
      ACL: 'private',
      ContentType: contentType
    };
    return bucket.upload(params);
  }

}
