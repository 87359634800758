import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { OnpremiseserversListDataSource } from './onpremiseservers-list-datasource'
import { RetrieveService } from '../api/services/retrieve.service';
import { OnpremiseServer } from '../api/models/onpremise-server';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { Router, ActivatedRoute, RouterLinkWithHref } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { OnpremiseServerStatus } from '../api/models/onpremise-server-status';

interface OnpremiseServerStatusView {
  value: string;
  viewValue: string;
}

export interface SearchParams {
  id?: string,
  name?: string;
  location?: string;
  status?: OnpremiseServerStatus;
  last_update_from?: string;
  last_update_to?: string;
  limit?: number;
  offset?: number;
  sort?: 'id' | 'name' | 'location' | 'status' | 'last_update';
  order?: 'asc' | 'desc';
}

@Component({
  selector: 'app-onpremiseservers-list',
  templateUrl: './onpremiseservers-list.component.html',
  styleUrls: ['./onpremiseservers-list.component.css']
})
export class OnpremiseserversListComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<OnpremiseServer>;

  /* Pagination */
  pageIndex: number;
  pageSize: number;

  /* Sorting */
  sortActive: string;
  sortDirection: 'asc' | 'desc';

  /** query parameters */
  searchParams: SearchParams = {
  };

  /** Table data source */
  dataSource: OnpremiseserversListDataSource;

  /**  Filters */
  idFilter = new FormControl();
  nameFilter = new FormControl();
  locationFilter = new FormControl();

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['id', 'name', 'location', 'status', 'last_update', 'monitored_channels'];

  /* Datepickers helpers */
  lastUpdateFrom = new FormControl();
  lastUpdateTo = new FormControl();

  /* Onpremise server status */
  onpremiseServerStatuses: OnpremiseServerStatusView[] = [
    { value: 'none', viewValue: 'Empty' },
    { value: 'running', viewValue: 'Runnning' },
    { value: 'error', viewValue: 'Error' }
  ];

  onpremiseServerStatus = new FormControl();


  constructor(
    private retrieve: RetrieveService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
  };

  ngOnInit() {

    this.dataSource = new OnpremiseserversListDataSource(this.retrieve);

    /* Read query parameters from URL */
    this.route.queryParams.subscribe(params => {
      this.onpremiseServerStatus.setValue(params['status'], { emitEvent: false });
      this.idFilter.setValue(params['id'], { emitEvent: false });
      this.nameFilter.setValue(params['name'], { emitEvent: false });
      this.locationFilter.setValue(params['location'], { emitEvent: false });
      this.lastUpdateFrom.setValue(params['last_update_from'] ? moment(params['last_update_from']) : null, { emitEvent: false });
      this.lastUpdateTo.setValue(params['last_update_to'] ? moment(params['last_update_to']) : null, { emitEvent: false });
      this.pageIndex = Number(params['pageIndex']) || 0;
      this.pageSize = Number(params['pageSize']) || 10;
      this.sortDirection = params['sortDirection'] || 'desc';
      this.sortActive = params['sortActive'] || 'name';
      this.loadOnpremiseServersPage();
    });

  }

  ngAfterViewInit() {

    this.idFilter.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['onpremiseservers/'], {
        queryParams: {
          id: value,
          pageIndex: 0
        },
        queryParamsHandling: 'merge'
      });
    });

    this.nameFilter.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['onpremiseservers/'], {
        queryParams: {
          name: value,
          pageIndex: 0
        },
        queryParamsHandling: 'merge'
      });
    });


    this.locationFilter.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['onpremiseservers/'], {
        queryParams: {
          location: value,
          pageIndex: 0
        },
        queryParamsHandling: 'merge'
      });
    });

    this.onpremiseServerStatus.valueChanges
      .subscribe(value => {
        this.router.navigate(['onpremiseservers/'], {
          queryParams: {
            status: value,
            pageIndex: 0
          },
          queryParamsHandling: 'merge'
        });
      });


    this.sort.sortChange
      .subscribe(() => {
        this.router.navigate(['onpremiseservers/'], {
          queryParams: {
            sortDirection: this.sort.direction,
            sortActive: this.sort.active,
            pageIndex: 0,
          },
          queryParamsHandling: 'merge'
        });
      });

    this.paginator.page
      .subscribe(() => {
        this.router.navigate(['onpremiseservers/'], {
          queryParams: {
            pageIndex: this.paginator.pageIndex,
            pageSize: this.paginator.pageSize
          },
          queryParamsHandling: 'merge'
        });
      });

    /* Dates */
    this.lastUpdateFrom.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged()
    ).subscribe(value => {
      this.router.navigate(['onpremiseservers/'], {
        queryParams: {
          pageIndex: 0,
          last_update_from: value ? value.format('YYYY-MM-DD') : null
        },
        queryParamsHandling: 'merge'
      });
    });

    this.lastUpdateTo.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged()
    ).subscribe(value => {
      this.router.navigate(['onpremiseservers/'], {
        queryParams: {
          pageIndex: 0,
          last_update_to: value ? value.format('YYYY-MM-DD') : null
        },
        queryParamsHandling: 'merge'
      });
    });
  }

  loadOnpremiseServersPage(): void {
    /**
     * Load a page of onpremise servers based on current parameters
     */

    /* Pagination */
    this.searchParams['offset'] = this.pageIndex * this.pageSize;
    this.searchParams['limit'] = this.pageSize;

    /* Sorting */
    this.searchParams['order'] = this.sortDirection;
    this.searchParams['sort'] = this.sortActive as 'id' | 'name' | 'location' | 'status' | 'last_update';


    /* Filters */
    this.searchParams['id'] = this.idFilter.value ? this.idFilter.value: null;
    this.searchParams['name'] = this.nameFilter.value ? this.nameFilter.value: null;
    this.searchParams['location'] = this.locationFilter.value ? this.locationFilter.value: null;

    /* Dates */
    this.searchParams['last_update_from'] = this.lastUpdateFrom.value ? this.lastUpdateFrom.value.format('YYYY-MM-DD') : null;
    this.searchParams['last_update_to'] = this.lastUpdateTo.value ? this.lastUpdateTo.value.format('YYYY-MM-DD') : null;

    /* Tasks */
    this.searchParams['status'] = this.onpremiseServerStatus.value;

    /* Run */
    this.dataSource.loadOnpremiseServers(this.searchParams);
  }

}
