import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { RetrieveService } from '../api/services/retrieve.service';
import { Content } from '../api/models/content';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Match } from '../api/models/match';
import { CountGroup } from '../api/models/count-group';
import { ContentDownloadService } from '../content-download.service'
import { FormControl } from '@angular/forms';
import { Type } from '../api/models/type'
import moment from 'moment';
import { environment } from '../../environments/environment';

export interface MatchesSearchParams {
  type?: Type,
  publishedFrom?: string;
  publishedTo?: string;
  onairFrom?: string;
  onairTo?: string;
  minDuration?: number;
}

class Node {
  startOndemand: number;
  endOndemand: number;
  startLinear: number;
  endLinear: number;

  constructor(startOndemand: number, endOndemand: number, startLinear: number, endLinear: number) {
    this.startOndemand = startOndemand;
    this.endOndemand = endOndemand;
    this.startLinear = startLinear;
    this.endLinear = endLinear;
  }

  ondemandDuration() {
    return this.endOndemand - this.startOndemand;
  }

  linearDuration() {
    return this.endLinear - this.startLinear;
  }

  isEqual(node: Node) {
    return (node.startLinear == this.startLinear && node.endLinear == this.endLinear &&
      node.startOndemand == this.startOndemand && node.endOndemand == this.endOndemand)
  }
}

interface nodeSearchResult {
  nextIdx: number;
  nextNode: Node;
}

interface nodeGroupResult {
  idxGroup: Array<Array<number>>;
  groups: Array<Node>;
}

interface MatchTime {
  startTimeLinear: string;
  endTimeLinear: string;
  startTimeOndemand: string;
  endTimeOndemand: string;
  isMain: boolean;
}

interface matchGroupByDate {
  [date: string]: Array<MatchTime>
}

function groupMatchByDate(groups: Array<Node>, minGroupDuration = 30, mainGroup: Array<Node> = null) {
  let results: matchGroupByDate = {};
  let allDates: Array<string> = [];

  groups.forEach((group) => {
    if (group.ondemandDuration() > minGroupDuration) {
      let isMainGroup = false;
      if (mainGroup != null) {
        mainGroup.forEach((mG) => {
          if (mG.isEqual(group)) {
            isMainGroup = true
          }
        });
      }

      let isOverlap = false
      if (!isMainGroup && mainGroup != null) {
        mainGroup.forEach((mG) => {
          if (isOverlapped(mG, group)) {
            isOverlap = true
          }
        });
      }
      if (mainGroup == null || isMainGroup || !isOverlap) {
        let onairDate = moment.unix(group.startLinear).format("DD-MM-YYYY");
        if (!allDates.includes(onairDate)) {
          results[onairDate] = [];
          allDates.push(onairDate);
        }
        let item: MatchTime = {
          startTimeLinear: moment.unix(group.startLinear).format('HH:mm:ss'),
          endTimeLinear: moment.unix(group.endLinear).format('HH:mm:ss'),
          startTimeOndemand: moment.utc(group.startOndemand * 1000).format('HH:mm:ss'),
          endTimeOndemand: moment.utc(group.endOndemand * 1000).format('HH:mm:ss'),
          isMain: isMainGroup
        }
        results[onairDate].push(item)
      }
    }
  });

  return results
}

function searchNextNode(node: Node, nodes: Array<Node>, ondemandTh: number, linearTh: number, idxGrouped: Array<number>) {
  let bestNode: Node = null;
  let bestNodeIdx: number = -1

  nodes.forEach((n, i) => {
    if (idxGrouped.includes(i)) {
      if (Math.abs(n.startLinear - node.endLinear) < linearTh &&
        (n.startOndemand - node.endOndemand) > 0 && (n.startOndemand - node.endOndemand) < ondemandTh
        && n.startLinear > node.startLinear) {
        if (bestNode == null || n.ondemandDuration() > bestNode.ondemandDuration()) {
          bestNode = n;
          bestNodeIdx = i;
        }
      }
    }
  });
  let result: nodeSearchResult = {
    nextIdx: bestNodeIdx,
    nextNode: bestNode
  }
  return result
}

function isOverlapped(node1: Node, node2: Node) {
  if ((node1.startLinear > node2.startLinear && node1.endLinear < node2.endLinear) || (node2.startLinear > node1.startLinear && node2.endLinear < node1.endLinear)) {
    return true
  }
  return false
}

function removeItemOnce(arr, value) {
  var index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
}

function groupNodes(nodes: Array<Node>, idxToGroup: Array<number>, ondemandTh: number, linearTh: number) {
  let groups: Array<Node> = [];
  let idxGroup: Array<Array<number>> = [];

  while (idxToGroup.length > 0) {
    let nodeIdx: number = idxToGroup[0];
    let currentGroup = nodes[nodeIdx]
    let currentIdx = [nodeIdx]
    idxToGroup = removeItemOnce(idxToGroup, nodeIdx);

    while (nodeIdx != -1) {

      let searchResult = searchNextNode(currentGroup, nodes, ondemandTh, linearTh, idxToGroup);
      nodeIdx = searchResult.nextIdx;
      let nextNode = searchResult.nextNode;

      if (nodeIdx != -1) {
        currentIdx.push(nodeIdx)
        idxToGroup = removeItemOnce(idxToGroup, nodeIdx);
        currentGroup.endOndemand = nextNode.endOndemand;
        currentGroup.endLinear = nextNode.endLinear;
      } else {
        groups.push(currentGroup);
        idxGroup.push(currentIdx)
      }
    }
  }

  let results: nodeGroupResult = {
    idxGroup: idxGroup,
    groups: groups
  }
  return results
}

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.css']
})
export class ContentComponent implements OnInit, AfterViewInit {

  @ViewChild('mainVideo')
  private videoPlayer: ElementRef;

  fingerprintVersions = environment.fingerprintVersions;

  defaultFingerprintVersion = 1;
  content$: Observable<Content>;
  matches$: Observable<Match[]>;
  linearMatches$: Observable<Match[]>;
  countMatches$: Observable<number>;
  countOndemandMatches$: Observable<number>;
  countLinearMatches$: Observable<CountGroup[]>;
  video_url: string;

  show = false;

  minMatchDuration = 0;
  selectedMatches = 0;

  indexing_keys = ['indexing_error', 'indexing_status', 'indexing_lastUpdate', 'indexing_job', 'index_uri', 'index_pts_uri', 'start_idx', 'end_idx', 'indexing_elapsed']

  types = ['ondemand', 'linear']
  hiddenLink: boolean = false
  matchesSearchParams: MatchesSearchParams = {}

  typeFilter = new FormControl()
  durationFilter = new FormControl()

  /* Datepickers helpers */
  onairFrom = new FormControl();
  onairTo = new FormControl();
  publishedFrom = new FormControl();
  publishedTo = new FormControl();

  fingerprintVersion = new FormControl()
  currentAmid = ''

  videoOnairDate = new FormControl();
  minVideoDate = '';
  maxVideoDate = '';

  groupedMatches = {};
  minGroupDuration = 40;

  constructor(
    private retrieve: RetrieveService,
    private download: ContentDownloadService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  setVideoCurrentTime(): void {
    this.content$.subscribe(
      (x: Content) => {
        let currentTime = moment(this.videoOnairDate.value).diff(moment(x.onair), 'seconds');
        this.videoPlayer.nativeElement.currentTime = currentTime;
      }
    )
  }

  setCurrentTime(data) {
    this.content$.subscribe(
      (x: Content) => {
        if (x.type == 'linear') {
          let currentOnair = moment(x.onair).add(data.target.currentTime, 'seconds')
          this.videoOnairDate.setValue(moment(currentOnair).toISOString(), { emitEvent: false });
        }
      }
    )
  }

  ngOnInit(): void {

    this.route.paramMap.subscribe(
      (params: ParamMap) => {
        this.currentAmid = params.get('amid')
      }
    )

    this.content$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap) =>
        this.retrieve.getContent({ amid: params.get('amid') }))
    );

    this.route.queryParams.subscribe(params => {
      this.fingerprintVersion.setValue(params['version'] ? parseInt(params['version']) : this.defaultFingerprintVersion, { emitEvent: false });
      this.groupedMatches = {};
      this.countMatches$ = this.route.paramMap.pipe(
        switchMap((params: ParamMap) =>
          this.retrieve.countMatches({ amid: params.get('amid'), version: this.fingerprintVersion.value }))
      );

      this.countOndemandMatches$ = this.route.paramMap.pipe(
        switchMap((params: ParamMap) =>
          this.retrieve.countMatches({ amid: params.get('amid'), version: this.fingerprintVersion.value, type: Type.Ondemand }))
      );

      this.countLinearMatches$ = this.route.paramMap.pipe(
        switchMap((params: ParamMap) =>
          this.retrieve.countMatches({ amid: params.get('amid'), version: this.fingerprintVersion.value, type: Type.Linear, groupBy: 'onair' }) as unknown as Array<CountGroup[]>)
      );

      this.content$.subscribe(
        (x: Content) => {
          if (x.type == 'linear') {
            this.videoOnairDate.setValue(params['videoOnairDate'] ? moment(params['videoOnairDate']).toISOString() : moment(x.onair).toISOString(), { emitEvent: false });
            this.minVideoDate = moment(x.onair).toISOString()
            this.maxVideoDate = (moment(x.onair).add(x.download.duration, 'seconds')).toISOString()
            this.setVideoCurrentTime()
          }
        }
      )
      this.matchesSearchParams['minDuration'] = 0
      this.loadMatches();
      this.groupMatches();
    });

    this.content$.subscribe(
      (x: Content) => {
        if (x.download.videoUri) {
          this.video_url = this.download.videoDownload(x.download.videoUri)
        } else if (x.download.audioUri) {
          this.video_url = this.download.videoDownload(x.download.audioUri)
        }
      }
    )
  }

  ngAfterViewInit() {
    this.typeFilter.valueChanges
      .subscribe(value => {
        this.matchesSearchParams['type'] = value
        if (value == 'ondemand') {
          this.onairFrom.disable()
          this.onairTo.disable()
        } else {
          this.onairFrom.enable()
          this.onairTo.enable()
        }

        if (value == 'linear') {
          this.publishedFrom.disable()
          this.publishedTo.disable()
        } else {
          this.publishedFrom.enable()
          this.publishedTo.enable()
        }
        this.loadMatches()
      });

    this.durationFilter.valueChanges.subscribe(
      value => {
        this.matchesSearchParams['minDuration'] = value
      });

    this.onairFrom.valueChanges
      .subscribe(value => {
        this.matchesSearchParams['onairFrom'] = value ? value.format('YYYY-MM-DD') : null;
        if (value) {
          this.onairTo.setValue(moment(value).add(1, 'd'))
        }
        this.loadMatches()
      });

    this.onairTo.valueChanges
      .subscribe(value => {
        this.matchesSearchParams['onairTo'] = value ? value.format('YYYY-MM-DD') : null;
        this.loadMatches()
      });

    this.publishedFrom.valueChanges
      .subscribe(value => {
        this.matchesSearchParams['publishedFrom'] = value ? value.format('YYYY-MM-DD') : null;
        if (value) {
          this.publishedTo.setValue(moment(value).add(1, 'd'))
        }
        this.loadMatches()
      });

    this.publishedTo.valueChanges
      .subscribe(value => {
        this.matchesSearchParams['publishedTo'] = value ? value.format('YYYY-MM-DD') : null;
        this.loadMatches()
      });

    this.fingerprintVersion.valueChanges.pipe(
    ).subscribe(value => {
      this.router.navigate(['content/' + this.currentAmid], {
        queryParams: {
          version: value,
        },
        queryParamsHandling: 'merge'
      });
    });

    this.videoOnairDate.valueChanges.pipe(
    ).subscribe(value => {
      this.router.navigate(['content/' + this.currentAmid], {
        queryParams: {
          videoOnairDate: moment(value).toISOString(),
        },
        queryParamsHandling: 'merge'
      });
    });
  }

  loadMatches() {
    this.matches$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap) =>
        this.retrieve.getMatches({ amid: params.get('amid'), version: this.fingerprintVersion.value, ...this.matchesSearchParams })
      )
    );
    this.onInputChange()
  }

  groupMatches() {
    this.linearMatches$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap) =>
        this.retrieve.getMatches({ amid: params.get('amid'), version: this.fingerprintVersion.value, type: Type.Linear })
      )
    );
    combineLatest([
      this.linearMatches$, this.content$
    ]).subscribe((results) => {


      let content: Content = results[1];
      let allMatches: Array<Match> = results[0];

      let matchesByChannel = {}
      allMatches.forEach((element) => {
        if (!(element.channel in matchesByChannel)) {
          matchesByChannel[element.channel] = []
        }
        matchesByChannel[element.channel].push(element)
      })

      for (const [key, value] of Object.entries(matchesByChannel)) {

        let channel = key;
        let matches = value as Array<Match>;


        if (matches.length == 0) {
          return
        }
        matches.sort(function (a, b) {
          var keyA = moment(a.onair).add(a.otheroffset, 'seconds');
          var keyB = moment(b.onair).add(b.otheroffset, 'seconds');
          // Compare the 2 dates
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        });

        let nodes: Array<Node> = [];
        matches.forEach(element => {
          nodes.push(
            new Node(element.selfoffset, element.selfoffset + element.duration,
              moment(element.onair).add(element.otheroffset, 'seconds').unix(), moment(element.onair).add(element.otheroffset + element.duration, 'seconds').unix())
          )
        });

        let ondemandTh = Math.max(Math.min(content.download.duration / 16, 120), 10);
        let linearTh = 25 * 60;
        let idxToGroup = Array.from({ length: nodes.length }, (x, i) => i);
        let groupResult = groupNodes(nodes, idxToGroup, ondemandTh, linearTh);

        let idxGroup = groupResult.idxGroup;
        let groups = groupResult.groups;

        let foundFullMatch: boolean = false;
        let fullMatchIdx: number;
        let mainGroup: Array<Node> = [];
        let mainGroupIdxs: Array<number> = [];
        groups.forEach((element, idx) => {
          if (element.ondemandDuration() / content.download.duration > 0.85) {
            foundFullMatch = true;
            fullMatchIdx = idx;
            mainGroup.push(element);
            mainGroupIdxs.push(...idxGroup[idx])
          }
        });

        if (foundFullMatch) {
          let nodes: Array<Node> = [];
          matches.forEach(element => {
            nodes.push(
              new Node(element.selfoffset, element.selfoffset + element.duration,
                moment(element.onair).add(element.otheroffset, 'seconds').unix(), moment(element.onair).add(element.otheroffset + element.duration, 'seconds').unix())
            )
          });

          idxToGroup = Array.from({ length: nodes.length }, (x, i) => i);
          mainGroupIdxs.forEach((element) => {
            idxToGroup = removeItemOnce(idxToGroup, element);
          });
          ondemandTh = 10;
          groupResult = groupNodes(nodes, idxToGroup, ondemandTh, linearTh);
          groups = groupResult.groups;
          idxGroup = groupResult.idxGroup;
          groups.push(...mainGroup);
          idxGroup.push(mainGroupIdxs);
        }
        let finalResult = groupMatchByDate(groups, this.minGroupDuration, mainGroup)
        if (Object.keys(finalResult).length > 0) {
          this.groupedMatches[channel] = finalResult;
        }
      }
    })
  }

  onInputChange() {
    this.matches$.subscribe((matches) => {
      this.selectedMatches = 0;
      matches.forEach(match => {
        if (match.duration > this.minMatchDuration) {
          this.selectedMatches++;
        }
      })
      this.hiddenLink = this.selectedMatches == 0
    })
  }

  checkMatch(i: number, match: Match) {
    if (match.duration > this.minMatchDuration) {
      return true
    }
    return false
  }

  getMatchVisualizerEnabled() {
    return this.selectedMatches > 0
  }

  goMatchVisualizer(amid: string) {
    this.router.navigate(['/matchesvisualizer', amid], { queryParams: { version: this.fingerprintVersion.value, ...this.matchesSearchParams } });
  }

  sort_runs(runs) {
    // Create items array
    var items = Object.keys(runs).map(function (key) {
      return [key, runs[key]];
    });

    items.sort((first, second) => {
      var diff: number = moment.duration(moment(first[1]['lastUpdate']).diff(moment(second[1]['lastUpdate']))).asSeconds()
      return diff
    });

    return items
  }

  checkDuration(g) {
    if (moment(g.endTimeOndemand, 'HH:mm:ss').diff(moment(g.startTimeOndemand, 'HH:mm:ss')) > this.minGroupDuration) {
      return true;
    }
    return false;
  }

  onGroupDurationChange() {
    this.groupedMatches = {};
    this.groupMatches();
  }

}
