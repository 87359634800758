import { Component, OnInit, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { NamedSeries } from '../api/models/named-series'
import { Metrics } from '../api/models/metrics'
import { Editor } from '../api/models/editor'
import moment from 'moment';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { RetrieveService } from '../api/services/retrieve.service';
import { Observable, BehaviorSubject, of, combineLatest } from 'rxjs';
import { switchMap, map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { forkJoin, from } from 'rxjs';
import { Type } from '../api/models/type';
import { isNull } from '@angular/compiler/src/output/output_ast';
import { FormControl } from '@angular/forms';


export interface MetricGroup {
  index: number,
  name: string,
}

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-audiomatch-metrics',
  templateUrl: './audiomatch-metrics.component.html',
  styleUrls: ['./audiomatch-metrics.component.css']
})
export class AudiomatchMetricsComponent implements OnInit, AfterViewInit {

  colours = {
    "1": "#FFBF00", "2": "#FF7F50", "3": "#DE3163", "4": "#9FE2BF",
    "5": "#40E0D0", "6": "#6495ED", "7": "#DFFF00", "8": "#CCCCFF"
  }

  metricGroups: MetricGroup[] = [{
    index: 0,
    name: 'Contents Metrics',
  },
  {
    index: 1,
    name: 'Download Metrics'
  },
  {
    index: 2,
    name: 'Audiofeat Metrics'
  },
  {
    index: 3,
    name: 'Audiomatch Metrics'
  },
  {
    index: 4,
    name: 'Showschedule Metrics'
  }];
  selectedGroup = new FormControl(0)

  editors$: Observable<Editor[]>;
  channelBrandDescriptions$: Observable<String[]>;

  ranges: any = {
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 15 Days': [moment().subtract(14, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }

  dateRange = new FormControl({
    endDate: moment(),
    startDate: moment().subtract(14, 'days')
  });

  dataLoading: boolean = true;

  editorStatus = new FormControl();
  channelBrandDescriptionStatus = new FormControl();

  // Contents counts
  count_contents_daily_by_date = [];
  count_contents_cumulated_by_date = [];
  count_contents_tot_by_editor = [];

  // Contents duration
  contents_duration_daily_by_date = []
  contents_duration_cumulated_by_date = []
  contents_duration_tot_by_editor = []

  // Contents size 
  contents_size_daily_by_date = []
  contents_size_cumulated_by_date = []
  contents_size_tot_by_editor = []
  contents_size_tot_by_filetype = []

  // Failed download
  failed_download_daily_by_date = []
  failed_download_cumulated_by_date = []
  failed_download_tot_by_editor = []

  // Failed fingerprint
  failed_fingerprint_daily_by_date = []
  failed_fingerprint_cumulated_by_date = []
  failed_fingerprint_tot_by_editor = []

  charts = [
    {
      groupIndex: 0,
      title: 'Number of Ondemand Daily Contents',
      description: '',
      results: [],
      params: { name: Metrics.CountContentsDailyByDate },
      isDateRangeDependent: true,
      isStatusEditorDependent: false,
      isChannelDependent: false,
      scaleValue: null,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "chart",
      chart_type: 'line-chart'
    },
    {
      groupIndex: 0,
      title: 'Number of Linear Daily Contents',
      description: '',
      results: [],
      params: { name: Metrics.CountContentsDailyByDate },
      isDateRangeDependent: true,
      isStatusEditorDependent: false,
      isChannelDependent: false,
      scaleValue: null,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "chart",
      chart_type: 'line-chart'
    },
    {
      groupIndex: 0,
      title: 'Number of Total Contents by Editor',
      description: '',
      results: [],
      params: { name: Metrics.CountContentsTotByEditor },
      isDateRangeDependent: false,
      isStatusEditorDependent: false,
      isChannelDependent: false,
      scaleValue: null,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "chart",
      chart_type: 'advanced-pie-chart'
    },
    {
      groupIndex: 0,
      title: 'Ondemand Daily Contents Duration (hours)',
      description: '',
      results: [],
      params: { name: Metrics.ContentsDurationDailyByDate },
      isDateRangeDependent: true,
      isStatusEditorDependent: false,
      isChannelDependent: false,
      scaleValue: 3600,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "chart",
      chart_type: 'line-chart'
    },
    {
      groupIndex: 0,
      title: 'Linear Daily Contents Duration (hours)',
      description: '',
      results: [],
      params: { name: Metrics.ContentsDurationDailyByDate },
      isDateRangeDependent: true,
      isStatusEditorDependent: false,
      isChannelDependent: false,
      scaleValue: 3600,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "chart",
      chart_type: 'line-chart'
    },
    {
      groupIndex: 0,
      title: 'Total Contents Duration by Editor (hours)',
      description: '',
      results: [],
      params: { name: Metrics.ContentsDurationTotByEditor },
      isDateRangeDependent: false,
      isStatusEditorDependent: false,
      isChannelDependent: false,
      scaleValue: 3600,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "chart",
      chart_type: 'advanced-pie-chart'
    },
    // {
    //   groupIndex: 0,
    //   title: 'Daily Contents Size (GB)',
    //   description: '',
    //   results: [],
    //   params: { name: Metrics.ContentsSizeDailyByDate },
    //   isDateRangeDependent: true,
    //   isStatusEditorDependent: false,
    //   scaleValue: 1e9,
    //   cols: 12,
    //   rows: 1,
    //   flex: "100%",
    //   class: "chart",
    //   chart_type: 'area-chart-stacked'
    // },
    // {
    //   groupIndex: 0,
    //   title: 'Total Contents Size by Editor (TB)',
    //   description: '',
    //   results: [],
    //   params: { name: Metrics.ContentsSizeTotByEditor },
    //   isDateRangeDependent: false,
    //   isStatusEditorDependent: false,
    //   scaleValue: 1e12,
    //   cols: 12,
    //   rows: 1,
    //   flex: "100%",
    //   class: "chart",
    //   chart_type: 'advanced-pie-chart'
    // },
    {
      groupIndex: 0,
      title: 'Total Contents Size by Type (TB)',
      description: '',
      results: [],
      params: { name: Metrics.ContentsSizeTotByFiletype },
      isDateRangeDependent: false,
      isStatusEditorDependent: false,
      isChannelDependent: false,
      scaleValue: 1e3,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "chart",
      chart_type: 'advanced-pie-chart'
    },
    {
      groupIndex: 1,
      title: 'Ondemand Daily Failed Download',
      description: '',
      xAxisTicks: [],
      results: [],
      params: { name: Metrics.FailedDownloadDailyByDate },
      isDateRangeDependent: true,
      isStatusEditorDependent: false,
      isChannelDependent: false,
      scaleValue: null,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "chart",
      chart_type: 'line-chart'
    },
    {
      groupIndex: 1,
      title: 'Linear Daily Failed Download',
      description: '',
      results: [],
      params: { name: Metrics.FailedDownloadDailyByDate },
      isDateRangeDependent: true,
      isStatusEditorDependent: false,
      isChannelDependent: false,
      scaleValue: null,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "chart",
      chart_type: 'line-chart'
    },
    {
      groupIndex: 1,
      title: 'Failed Download by Editor',
      description: '',
      results: [],
      params: { name: Metrics.FailedDownloadTotByEditor },
      isDateRangeDependent: false,
      isStatusEditorDependent: false,
      isChannelDependent: false,
      scaleValue: null,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "chart",
      chart_type: 'advanced-pie-chart'
    },
    {
      groupIndex: 2,
      title: 'Ondemand Daily Failed Fingerprint',
      description: '',
      results: [],
      params: { name: Metrics.FailedFingerprintDailyByDate },
      isDateRangeDependent: true,
      isStatusEditorDependent: false,
      isChannelDependent: false,
      scaleValue: null,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "chart",
      chart_type: 'line-chart'
    },
    {
      groupIndex: 2,
      title: 'Linear Daily Failed Fingerprint',
      description: '',
      results: [],
      params: { name: Metrics.FailedFingerprintDailyByDate },
      isDateRangeDependent: true,
      isStatusEditorDependent: false,
      isChannelDependent: false,
      scaleValue: null,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "chart",
      chart_type: 'line-chart'
    },
    {
      groupIndex: 2,
      title: 'Failed Fingerprint by Editor',
      description: '',
      results: [],
      params: { name: Metrics.FailedFingerprintTotByEditor },
      isDateRangeDependent: false,
      isStatusEditorDependent: false,
      isChannelDependent: false,
      scaleValue: null,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "chart",
      chart_type: 'advanced-pie-chart'
    },
    {
      groupIndex: 1,
      title: 'Download Status',
      description: '',
      results: [],
      params: { name: Metrics.DownloadStatus },
      isDateRangeDependent: true,
      isStatusEditorDependent: true,
      isChannelDependent: true,
      scaleValue: null,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "chart",
      chart_type: 'pie-chart'
    },
    {
      groupIndex: 2,
      title: 'Audiofeat Status',
      description: '',
      results: [],
      params: { name: Metrics.AudiofeatStatus },
      isDateRangeDependent: true,
      isStatusEditorDependent: true,
      isChannelDependent: true,
      scaleValue: null,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "chart",
      chart_type: 'pie-chart'
    },
    {
      groupIndex: 3,
      title: 'Audiomatch Contents Status',
      description: '',
      results: [],
      params: { name: Metrics.AudiomatchContentsStatus },
      isDateRangeDependent: true,
      isStatusEditorDependent: true,
      isChannelDependent: true,
      scaleValue: null,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "chart",
      chart_type: 'pie-chart'
    },
    {
      groupIndex: 3,
      title: 'Audiomatch Runs Status',
      description: '',
      results: [],
      params: { name: Metrics.AudiomatchRunsStatus },
      isDateRangeDependent: true,
      isStatusEditorDependent: true,
      isChannelDependent: true,
      scaleValue: null,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "chart",
      chart_type: 'pie-chart'
    },
    {
      groupIndex: 1,
      title: 'Download Error by Type',
      description: '',
      results: [],
      params: { name: Metrics.DownloadErrorByType },
      isDateRangeDependent: true,
      isStatusEditorDependent: true,
      isChannelDependent: false,
      scaleValue: null,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "hight-chart",
      chart_type: 'pie-chart'
    },
    {
      groupIndex: 2,
      title: 'Audiofeat Error by Type',
      description: '',
      results: [],
      params: { name: Metrics.AudiofeatErrorByType },
      isDateRangeDependent: true,
      isStatusEditorDependent: true,
      isChannelDependent: false,
      scaleValue: null,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "hight-chart",
      chart_type: 'pie-chart'
    },
    {
      groupIndex: 3,
      title: 'Audiomatch Error by Type',
      description: '',
      results: [],
      params: { name: Metrics.AudiomatchErrorByType },
      isDateRangeDependent: true,
      isStatusEditorDependent: true,
      isChannelDependent: false,
      scaleValue: null,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "hight-chart",
      chart_type: 'pie-chart'
    },
    {
      groupIndex: 4,
      title: 'Showschedule Status',
      description: '',
      results: [],
      params: { name: Metrics.ShowscheduleStatus },
      isDateRangeDependent: true,
      isStatusEditorDependent: true,
      isChannelDependent: false,
      scaleValue: null,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "chart",
      chart_type: 'pie-chart'
    },
    {
      groupIndex: 4,
      title: 'Showschedule Status by Channel',
      description: '',
      results: [],
      params: { name: Metrics.ShowscheduleStatusByChannel },
      isDateRangeDependent: true,
      isStatusEditorDependent: true,
      isChannelDependent: false,
      scaleValue: null,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "chart",
      chart_type: 'pie-chart'
    },
    {
      groupIndex: 4,
      title: 'Showschedule Completeness',
      description: '',
      results: [],
      params: { name: Metrics.ShowscheduleCompleteness },
      isDateRangeDependent: true,
      isStatusEditorDependent: true,
      isChannelDependent: true,
      scaleValue: null,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "chart",
      chart_type: 'pie-chart'
    },
  ];

  filterGroups = [];

  constructor(
    private retrieve: RetrieveService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  scaleValue(data: Array<NamedSeries>, scaleFactor: number): Array<NamedSeries> {
    let temp = []
    data.forEach(
      element => {
        if ('series' in element) {
          let scaledSeries = [];
          element['series'].forEach(
            item => {
              if (item['value'] != null) {
                scaledSeries.push(
                  { 'name': item['name'], 'value': item['value'] / scaleFactor }
                )
              }
            }
          )
          temp.push(
            { 'name': element['name'], 'series': scaledSeries }
          )
        } else {
          if (element['value'] != null) {
            temp.push(
              { 'name': element['name'], 'value': element['value'] / scaleFactor }
            )
          }
        }
      }
    )
    return temp
  }

  ngOnInit(): void {

    this.initFilterGroups();

    this.editors$ = this.retrieve.getEditorsList()

    /* Read query parameters from URL */
    this.route.queryParams.subscribe(params => {
      this.editorStatus.setValue(params['editor'], { emitEvent: false });
      this.channelBrandDescriptionStatus.setValue(params['channel'], { emitEvent: false });
      this.selectedGroup.setValue(params['group'] ? parseInt(params['group']) : 0, { emitEvent: false });
      this.dateRange.setValue({
        startDate: params['startDate'] ? moment(params['startDate']) : moment().subtract(14, 'days'),
        endDate: params['endDate'] ? moment(params['endDate']) : moment(),
      }, { emitEvent: false });
      if (this.editorStatus.value && !this.channelBrandDescriptionStatus.value){
        this.updateStatusMetrics(true);
      }
      this.updateMetrics();
    });
  }

  initFilterGroups() {
    let possibleValues = [true, false]
    for (var i = 0; i < possibleValues.length; i++) {
      for (var j = 0; j < possibleValues.length; j++) {
        for (var k = 0; k < possibleValues.length; k++) {
          let filters = {
            'isDateRangeDependent': possibleValues[i],
            'isStatusEditorDependent': possibleValues[j],
            'isChannelDependent': possibleValues[k],
          }
          this.filterGroups.push(filters);
        }
      }
    }
    console.log(this.filterGroups)
  }

  ngAfterViewInit() {
    this.dateRange.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['metrics/'], {
        queryParams: {
          startDate: value.startDate,
          endDate: value.endDate
        },
        queryParamsHandling: 'merge'
      });
    });

    this.editorStatus.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['metrics/'], {
        queryParams: {
          editor: value,
        },
        queryParamsHandling: 'merge'
      });
    });


    this.channelBrandDescriptionStatus.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['metrics/'], {
        queryParams: {
          channel: value,
        },
        queryParamsHandling: 'merge'
      });
    });


    this.selectedGroup.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['metrics/'], {
        queryParams: {
          group: value,
        },
        queryParamsHandling: 'merge'
      });
    });
  }


  updateMetrics(): void {

    this.dataLoading = true;

    let metricsToUpdate = []
    this.charts.forEach(
      chart => {
        if (chart.groupIndex == parseInt(this.selectedGroup.value)) {
          chart.results = [];
          if (!(chart.title.toLowerCase().includes('daily') && chart.title.toLowerCase().includes('linear'))) {
            metricsToUpdate.push(this.retrieve.getMetrics(Object.assign({}, chart.params, {
              dateFrom: this.dateRange.value.startDate.toISOString(),
              dateTo: this.dateRange.value.endDate.toISOString(),
              editor_status: this.editorStatus.value ? this.editorStatus.value : null,
              channelbranddescription_status: this.channelBrandDescriptionStatus.value ? this.channelBrandDescriptionStatus.value : null
            })))
          }
        }
      }
    )

    forkJoin(
      metricsToUpdate
    ).subscribe(
      (data: Array<NamedSeries[]>) => {
        let idx = 0;
        this.charts.forEach(
          chart => {
            if (chart.groupIndex == parseInt(this.selectedGroup.value)) {
              if (!(chart.title.toLowerCase().includes('daily') && chart.title.toLowerCase().includes('linear'))) {
                if (chart.title.toLowerCase().includes('daily')) {
                  data[idx].forEach((element: any) => {
                    element.series.forEach((item: any) => {
                      item.name = new Date(moment(item.name).toISOString());
                    });
                  });
                }


                if (chart.title.toLowerCase().includes('ondemand')) {
                  let ondemand_data = []
                  let linear_data = []
                  if (chart.title.includes('Daily')) {
                    data[idx].forEach((element: any) => {
                      if (element.name.toLowerCase().includes('ondemand')) {
                        ondemand_data.push(element)
                      } else {
                        linear_data.push(element)
                      }
                    });
                  }
                  chart.results = chart.scaleValue == null ? this.scaleValue(ondemand_data, 1) : this.scaleValue(ondemand_data, chart.scaleValue);
                  const result = this.charts.find(({ title }) => title.toLowerCase() === chart.title.toLowerCase().replace('ondemand', 'linear'));
                  if (result) {
                    result.results = chart.scaleValue == null ? this.scaleValue(linear_data, 1) : this.scaleValue(linear_data, chart.scaleValue);
                  }
                  // chart.xAxisTicks = []
                  // data[idx][0].series.forEach((item: any) => {
                  //     chart.xAxisTicks.push(item.name);
                  //   });

                } else {
                  chart.results = chart.scaleValue == null ? this.scaleValue(data[idx], 1) : this.scaleValue(data[idx], chart.scaleValue);
                }
                idx++;

              }
            }
          }
        )
        this.dataLoading = false;
      }
    )
  }

  dateTickFormatting(val: any): string {
    if (val instanceof Date) {
      return (<Date>val).toLocaleString('it-IT', { year: '2-digit', month: '2-digit', day: '2-digit' });
    }
  }

  updateStatusMetrics(updateChannels: boolean = false): void {
    if (updateChannels) {
      if (this.editorStatus) {
        if (this.selectedGroup.value == 4){
          this.channelBrandDescriptions$ = this.retrieve.getShowScheduleChannel().pipe(
            map((results) => {
              let channels: String[] = [];
              results.forEach(
                (result) => {
                  if (result.editor.toLowerCase() == this.editorStatus.value){
                    channels.push(result.channel);
                  }
                }
              )
              return channels
            })
          )
        }else{
          this.channelBrandDescriptions$ = this.retrieve.getChannelBrandDescriptionList({
            editor: this.editorStatus.value, 
            dateFrom: this.dateRange.value.startDate.toISOString(),
            dateTo: this.dateRange.value.endDate.toISOString(),
          })
        }
      } else {
        this.channelBrandDescriptions$ = of([])
      }
      this.channelBrandDescriptionStatus.setValue('');
    }
  }

  getErrorMessage(title: string): string {
    if (title.toLowerCase().includes('daily') && (title.toLowerCase().includes('failed') || title.toLowerCase().includes('error'))) {
      return "NO ERROR FOUND FOR THE SELECTED TIME INTERVAL"
    } else if (title.toLowerCase().includes('failed') || title.toLowerCase().includes('error')) {
      return "NO ERROR FOUND"
    } else if (title.toLowerCase().includes('status')) {
      return "NO CONTENT INSERTED FOR THE SELECTED TIME INTERVAL"
    } else {
      return "NO DATA FOUND"
    }
  }

  sortData(data) {
    let sortedData = data.sort((a, b) => b.value - a.value);
    return sortedData
  }

  roundValue(val) {
    return Math.round(val * 100) / 100
  }

  numberOfChart(groupIndex: number, isDateRangeDependent: boolean, isStatusEditorDependent: boolean, isChannelDependent: boolean) {
    let n = 0;
    this.charts.forEach((chart) => {
      if (chart.groupIndex == groupIndex
        && chart.isDateRangeDependent == isDateRangeDependent
        && chart.isStatusEditorDependent == isStatusEditorDependent
        && chart.isChannelDependent == isChannelDependent) {
        n++;
      }
    })
    return n
  }

  chartToPlot(groupIndex, filterGroup) {
    let selectedChart = []

    this.charts.forEach((chart) => {
      if (chart.groupIndex == groupIndex
        && chart.isDateRangeDependent == filterGroup.isDateRangeDependent
        && chart.isStatusEditorDependent == filterGroup.isStatusEditorDependent
        && chart.isChannelDependent == filterGroup.isChannelDependent) {
        selectedChart.push(chart)
      }
    })

    return selectedChart
  }

  getCardTitle(filterGroup) {
    let cardTitle = ''
    if (filterGroup.isDateRangeDependent) {
      if (cardTitle) {
        cardTitle += ', Date'
      } else {
        cardTitle += 'Date'
      }
    }

    if (filterGroup.isStatusEditorDependent) {
      if (cardTitle) {
        cardTitle += ', Editor'
      } else {
        cardTitle += 'Editor'
      }
    }

    if (filterGroup.isChannelDependent) {
      if (cardTitle) {
        cardTitle += ', Channel'
      } else {
        cardTitle += 'Channel'
      }
    }

    if (cardTitle) {
      cardTitle += ' dependend graphs'
    } else {
      cardTitle = 'Filter independent graphs'
    }
    return cardTitle
  }

  getHeaderColor(idx) {
    return this.colours[Object.keys(this.colours)[idx]];
  }

  export(metrics) {

    let filename = metrics.title;
    let fileType = 'csv';
    
    let blob
    const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
    let header = []

    let rowFilter = []
    if (metrics.isDateRangeDependent && this.dateRange.value){
      header = ['Date Filter From', 'Date Filter To'];
      rowFilter.push(this.dateRange.value.startDate.toISOString(true));
      rowFilter.push(this.dateRange.value.endDate.toISOString(true));
    }

    if (metrics.isStatusEditorDependent && this.editorStatus.value){
      header.push('Editor Filter');
      rowFilter.push(this.editorStatus.value);
    }

    if (metrics.isChannelDependent && this.channelBrandDescriptionStatus.value) {
      header.push('Channel Filter');
      rowFilter.push(this.channelBrandDescriptionStatus.value);
    }

    let csv = []
    let csvString
    if (metrics.title.toLowerCase().includes('daily')){
      header.push(...['Name', 'Date', 'Value']);
      csv = [header.join(';')] // header row first]

      metrics.results.forEach((element: any) => {
        element.series.forEach((item: any) => {
          csv.push([...rowFilter, element.name, moment(item.name).toISOString(true), item.value].join(';'))
        });
      });
    }else{
      header.push(...['Name', 'Value']);
      csv = [header.join(';')] // header row first]

      metrics.results.forEach((element: any) => {
        csv.push([...rowFilter, element.name, element.value].join(';'))
      });
    }
    csvString = csv.join('\r\n')
    blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    

    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + "." + fileType);
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);

  }
}
