import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { RetrieveService } from '../api/services/retrieve.service';
import { OnpremiseServer } from '../api/models/onpremise-server';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { MatRadioChange } from '@angular/material/radio';
import moment from 'moment';

export const ITEMS = [
  {
    name:'Last 5 min',
    value:'5'
   },
  {
    name:'Last 10 min',
    value:'10'
  },
  {
    name:'Last 30 min',
    value:'30'
  },
  {
    name:'Last hour',
    value:'60'
  },
];

@Component({
  selector: 'app-onpremiseserver',
  templateUrl: './onpremiseserver.component.html',
  styleUrls: ['./onpremiseserver.component.css']
})
export class OnpremiseserverComponent implements OnInit {
  items = ITEMS;
  chosenItem = this.items[1].value;
  
  onpremiseserver$:Observable<OnpremiseServer>;

  system_cpu = [];
  system_ram = [];
  system_net_inbound = [];
  system_net_outbound = [];
  system_swap = [];

  constructor(private retrieve: RetrieveService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.onpremiseserver$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap) =>
        this.retrieve.getOnpremiseServer({id:params.get('id')}))
    );
    this.retrieveMetrics(parseFloat(this.chosenItem));
  }

  retrieveMetrics(range: number): void {
    this.route.paramMap.subscribe(
      paramMap => {
        const id_server = paramMap.get('id')
        this.retrieve.getOnpremiseServerMetrics({id:id_server, 
                                                dateFrom:moment().subtract(range,'minutes').toISOString(),
                                                dateTo:moment().toISOString()
                                              }).subscribe(
          metrics => {
            metrics.forEach(metric=>{
              if (metric.name == 'System_Cpu') {
                 this.system_cpu = [metric];
              }else if (metric.name == 'System_Ram'){
                 this.system_ram = [metric]    
              }else if (metric.name == 'System_Net_Inbound'){
                this.system_net_inbound = [metric]
              }else if (metric.name == 'System_Net_Outbound'){
                this.system_net_outbound = [metric]
              }else if (metric.name == 'System_Swap'){
                this.system_swap = [metric]
              }
            })
          }
        )
      }
    )
  }
}
