import { Component, OnInit } from '@angular/core';

import { Auth } from 'aws-amplify'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'AudioMatch Dashboard';
  isLoggedIn = false;

  ngOnInit() {
    this.isAuthenticated();
  }

  isAuthenticated() {
    Auth.currentAuthenticatedUser()
      .then(user => {
        // console.log({ user });
        this.isLoggedIn = true;
      })
      .catch(err => {
        // console.log(err);
        this.isLoggedIn =  false;
      })
  }

}



