import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { RetrieveService } from '../api/services/retrieve.service';
import { AudioMatchRun } from '../api/models/audio-match-run';

import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { MatRadioChange } from '@angular/material/radio';
import moment from 'moment';

@Component({
  selector: 'app-audiomatchrun',
  templateUrl: './audiomatchrun.component.html',
  styleUrls: ['./audiomatchrun.component.css']
})
export class AudiomatchrunComponent implements OnInit {

  audiomatchruns$:Observable<AudioMatchRun[]>;

  constructor(private retrieve: RetrieveService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.audiomatchruns$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap) =>
        this.retrieve.getAudiomatchRunsList({id:params.get('id')}))
    );
  }

}
