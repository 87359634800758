import { Component, OnInit } from '@angular/core';
import { InsertService } from '../api/services/insert.service';
import { RetrieveService } from '../api/services/retrieve.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl, FormGroup, Validators, AbstractControl, ValidationErrors, NgModel } from '@angular/forms';
import { OperateService } from '../api/services/operate.service';
import { Observable } from 'rxjs';
import { OnpremiseServer } from '../api/models/onpremise-server';
import { OnpremiseServerStatus } from '../api/models/onpremise-server-status';
import { NewMonitoredChannel } from '../api/models/new-monitored-channel'
import { AudiomatchChannel } from '../api/models/audiomatch-channel';
import { Router } from '@angular/router'
import { MatDialog } from '@angular/material/dialog';
import { AddAudiomatchChannelComponent, DialogData } from '../add-audiomatch-channel/add-audiomatch-channel.component';


@Component({
  selector: 'app-add-monitored-channel',
  templateUrl: './add-monitored-channel.component.html',
  styleUrls: ['./add-monitored-channel.component.css']
})
export class AddMonitoredChannelComponent implements OnInit {

  monitoredChannelForm: FormGroup;
  public onpremiseServers = Array<OnpremiseServer>();
  public audiomatchChannels =   Array<AudiomatchChannel>();

  constructor(
    private insert: InsertService,
    private retrieve: RetrieveService,
    private operate: OperateService,
    private snackBar: MatSnackBar,
    private router: Router,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {

    this.monitoredChannelForm = new FormGroup({
      amchid: new FormControl('', [Validators.required]),
      id_server: new FormControl(),
      udp_port: new FormControl('', [Validators.required, Validators.pattern('^()([1-9]|[1-5]?[0-9]{2,4}|6[1-4][0-9]{3}|65[1-4][0-9]{2}|655[1-2][0-9]|6553[1-5])$')]),
    });
    
    this.retrieve.getOnpremiseServersList({status: OnpremiseServerStatus.Running, limit: -1}).subscribe(
      servers =>{
        this.onpremiseServers = servers;
      }
    )
    
    this.retrieve.getAudiomatchChannels({limit: -1}).subscribe(
      channels => {
        this.audiomatchChannels = channels;
      }
    )
  }

  get controls() { return this.monitoredChannelForm.controls; }

  submit(): void {

    const monitoredChannel: NewMonitoredChannel = {
      amchid: this.monitoredChannelForm.get('amchid').value,
      id_server: this.monitoredChannelForm.get('id_server').value,
      udp_port: this.monitoredChannelForm.get('udp_port').value,
    };

    this.insert.addMonitoredChannel({body: monitoredChannel}).subscribe(
      (res) => {this.router.navigateByUrl('/monitoredchannels')},
      (err) => {this.insertError(err)}
    )
  }

  private insertError(err){
    const code = err.error.errorCode;
    const message = err.error.message;
    let snackBarRef = this.snackBar.open(`Error while inserting new monitored channel. Code: ${code}. Error: ${message}`,
      'Close',
      {
        panelClass: ['alert-snack-bar']
      });
  }

  addAudiomatchChannel(){
    const dialogRef = this.dialog.open(AddAudiomatchChannelComponent, {});

    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
        this.retrieve.getAudiomatchChannels({limit: -1}).subscribe(
          channels => {
            console.log(channels)
            this.audiomatchChannels = channels;
          }
        )
    });
  }
}

