import { DataSource, CollectionViewer } from '@angular/cdk/collections';
import { catchError, finalize } from 'rxjs/operators';
import { Observable, of as observableOf, merge, BehaviorSubject, of } from 'rxjs';
import { RetrieveService } from '../api/services/retrieve.service';
import { Content } from '../api/models/content';
import { SearchParams } from './channels-list.component';
import { ChannelBundle } from '../api/models/channel-bundle';

/**
 * Data source for the ChannelsList view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class ChannelsListDataSource implements DataSource<ChannelBundle> {

  private channelsSubject = new BehaviorSubject<ChannelBundle[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private channelsCount = new BehaviorSubject(0);

  public loading$ = this.loadingSubject.asObservable();
  public count$ = this.channelsCount.asObservable();

  constructor(private retrieveService: RetrieveService) {
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(collectionViewer: CollectionViewer): Observable<ChannelBundle[]> {
    return this.channelsSubject.asObservable();
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect(collectionViewer: CollectionViewer): void {
    this.channelsSubject.complete();
    this.loadingSubject.complete();
  }

  loadChannels(params: SearchParams) {

    this.loadingSubject.next(true);

    /* Retrieve channels */
    this.retrieveService.getChannelsList(params).pipe(
      catchError(() => {
        // console.error('getChannelsList ERROR');
        return of([]);
      }),
      finalize(() => {
        // console.log('getChannelsList OK');
        this.loadingSubject.next(false);
      })
    ).subscribe(channels => {
      // console.log(channels);
      this.channelsSubject.next(channels);
    });

    /* Count channels */
    this.retrieveService.getNumChannels(params).pipe(
      catchError(() => {
        return of([]);
      }),
      finalize(() => {
        this.loadingSubject.next(false);
      })
    ).subscribe(count => {
      this.channelsCount.next(count as number);
    });
  }

}

