/* tslint:disable */
/* eslint-disable */

/**
 * ShowSchedule status
 */
export enum ShowScheduleStatus {
  Off = 'off',
  On = 'on'
}
