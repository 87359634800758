import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Provider, forwardRef } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTabsModule } from '@angular/material/tabs';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { ContentsListComponent } from './contents-list/contents-list.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatDatepickerModule } from '@angular/material/datepicker'
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatSliderModule } from '@angular/material/slider'

import { ApiModule } from './api/api.module'
import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiInterceptor } from './api.interceptor';

import { ComponentsModule } from './components/components.module';

/* Add Amplify imports */
import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
import { Amplify } from 'aws-amplify';
import { RetrieveService } from './api/services/retrieve.service';
import { InsertService } from './api/services/insert.service';
import { OperateService } from './api/services/operate.service';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMomentDateModule, MomentDateAdapter, MAT_MOMENT_DATE_FORMATS, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS, MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { TaskInListComponent } from './task-in-list/task-in-list.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ContentComponent } from './content/content.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatchSettingsComponent } from './match-settings/match-settings.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AddOndemandComponent } from './add-ondemand/add-ondemand.component';
import { MaterialFileInputModule } from '@saritasa/ngx-material-file-input';
import { ChannelsListComponent } from './channels-list/channels-list.component';
import { ChannelComponent } from './channel/channel.component';
import { IdPipe } from './id.pipe';
import { DurationPipe } from './duration.pipe';
import { OnpremiseserverComponent } from './onpremiseserver/onpremiseserver.component';
import { OnpremiseserversListComponent } from './onpremiseservers-list/onpremiseservers-list.component';
import { MonitoredchannelComponent } from './monitoredchannel/monitoredchannel.component';
import { MonitoredchannelsListComponent } from './monitoredchannels-list/monitoredchannels-list.component';
import { AddMonitoredChannelComponent } from './add-monitored-channel/add-monitored-channel.component';
import { AudiomatchMetricsComponent } from './audiomatch-metrics/audiomatch-metrics.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MatchesVisualizerComponent } from './matches-visualizer/matches-visualizer.component';
import { MatTableExporterModule } from 'mat-table-exporter';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { FlexLayoutModule } from '@angular/flex-layout';

/* Wavesurfer */
import WaveSurfer from 'wavesurfer.js';
import { PartialfullListComponent } from './partialfull-list/partialfull-list.component';
import { ShowscheduleListComponent } from './showschedule-list/showschedule-list.component';
import { CalendarModule, DateAdapter as CalendarDateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import * as moment from 'moment';
import { ViewShowscheduleComponent } from './view-showschedule/view-showschedule.component';
import { ShowscheduleMatchSettingsComponent } from './showschedule-match-settings/showschedule-match-settings.component';
import { MonitoredchannelConfigurationSettingsComponent } from './monitoredchannel-configuration-settings/monitoredchannel-configuration-settings.component';
import { AudiomatchrunsListComponent } from './audiomatchruns-list/audiomatchruns-list.component';
import { AudiomatchjobsListComponent } from './audiomatchjobs-list/audiomatchjobs-list.component';
import { AudiomatchjobcontentsListComponent } from './audiomatchjobcontents-list/audiomatchjobcontents-list.component';
import { AudiomatchrunComponent } from './audiomatchrun/audiomatchrun.component';
import { AudiomatchchannelsListComponent } from './audiomatchchannels-list/audiomatchchannels-list.component';
import { AddAudiomatchChannelComponent } from './add-audiomatch-channel/add-audiomatch-channel.component';
import { AudiomatchrunsByDateComponent } from './audiomatchruns-by-date/audiomatchruns-by-date.component';

import { environment } from '../environments/environment';
import { AdvsListComponent } from './advs-list/advs-list.component';

export function momentAdapterFactory() {
  return adapterFactory(moment);
};

/* Configure Amplify resources */
Amplify.configure({
  Auth: {

    // REQUIRED - Amazon Cognito Region
    region: 'eu-central-1',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'eu-central-1_tujUYWcwn',

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: '31qj8ub7brk4dim6mjiq7qeov0',

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,

    // // OPTIONAL - Configuration for cookie storage
    // // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    // cookieStorage: {
    //   // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    //   domain: 'localhost',
    //   // OPTIONAL - Cookie path
    //   path: '/',
    //   // OPTIONAL - Cookie expiration in days
    //   expires: 7,
    //   // OPTIONAL - Cookie secure flag
    //   // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
    //   secure: true
    // },

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: 'USER_SRP_AUTH',

    // OPTIONAL - Hosted UI configuration
    oauth: {
      domain: 'audiomatch.auth.eu-central-1.amazoncognito.com',
      scope: ['openid',],
      redirectSignIn: window.location.origin + '/',
      redirectSignOut: window.location.origin + '/',
      responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
  }
});

export const API_INTERCEPTOR_PROVIDER: Provider = {
  provide: HTTP_INTERCEPTORS,
  useExisting: forwardRef(() => ApiInterceptor),
  multi: true
};

@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    ContentsListComponent,
    DashboardComponent,
    PageNotFoundComponent,
    TaskInListComponent,
    ContentComponent,
    MatchSettingsComponent,
    AddOndemandComponent,
    ChannelsListComponent,
    ChannelComponent,
    IdPipe,
    DurationPipe,
    OnpremiseserverComponent,
    OnpremiseserversListComponent,
    MonitoredchannelComponent,
    MonitoredchannelsListComponent,
    AddMonitoredChannelComponent,
    AudiomatchMetricsComponent,
    MatchesVisualizerComponent,
    PartialfullListComponent,
    ShowscheduleListComponent,
    ViewShowscheduleComponent,
    ShowscheduleMatchSettingsComponent,
    MonitoredchannelConfigurationSettingsComponent,
    AudiomatchrunsListComponent,
    AudiomatchjobsListComponent,
    AudiomatchjobcontentsListComponent,
    AudiomatchrunComponent,
    AudiomatchchannelsListComponent,
    AddAudiomatchChannelComponent,
    AudiomatchrunsByDateComponent,
    AdvsListComponent,
  ],
  imports: [
    FlexLayoutModule,
    ComponentsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    LayoutModule,
    MatTabsModule,
    MatButtonModule,
    MatDatepickerModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatTableModule,
    MatTableExporterModule,
    MatCheckboxModule,
    ScrollingModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatMomentDateModule,
    MatCardModule,
    MatMenuModule,
    MatSliderModule,
    ApiModule.forRoot({ rootUrl: environment.apiEndpoint }),
    HttpClientModule,
    AmplifyUIAngularModule,
    ReactiveFormsModule,
    FormsModule,
    MatSelectModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatSnackBarModule,
    MatDialogModule,
    MatSlideToggleModule,
    NgbModule,
    MaterialFileInputModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxDaterangepickerMd.forRoot(),
    NgxChartsModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatRadioModule,
    CalendarModule.forRoot({ provide: CalendarDateAdapter, useFactory: momentAdapterFactory })
  ],
  providers: [
    HttpClientModule,
    RetrieveService,
    InsertService,
    OperateService,
    ApiInterceptor,
    API_INTERCEPTOR_PROVIDER,
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true, strict: true } }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    MatchSettingsComponent
  ]
})
export class AppModule { }
