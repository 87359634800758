import { DataSource, CollectionViewer } from '@angular/cdk/collections';
import { catchError, finalize } from 'rxjs/operators';
import { Observable, of as observableOf, merge, BehaviorSubject, of } from 'rxjs';
import { RetrieveService } from '../api/services/retrieve.service';
import { ShowSchedule } from '../api/models/show-schedule';
import { SearchParams } from './showschedule-list.component';

/**
 * Data source for the ShowSchedule view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class ShowScheduleListDataSource implements DataSource<ShowSchedule> {

  private showScheduleSubject = new BehaviorSubject<ShowSchedule[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private showScheduleCount = new BehaviorSubject(0);

  public loading$ = this.loadingSubject.asObservable();
  public count$ = this.showScheduleCount.asObservable();

  constructor(private retrieveService: RetrieveService) {
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(collectionViewer: CollectionViewer): Observable<ShowSchedule[]> {
    return this.showScheduleSubject.asObservable();
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect(collectionViewer: CollectionViewer): void {
    this.showScheduleSubject.complete();
    this.loadingSubject.complete();
  }

  loadContents(params: SearchParams) {

    this.loadingSubject.next(true);

    /* Retrieve contents */
    this.retrieveService.getShowScheduleList(params).pipe(
      catchError(() => {
        // console.error('getContentsList ERROR');
        return of([]);
      }),
      finalize(() => {
        // console.log('getContentsList OK');
        this.loadingSubject.next(false);
      })
    ).subscribe(pf => {
      // console.log(contents);
      this.showScheduleSubject.next(pf);
    });

    /* Count contents */
    this.retrieveService.countShowSchedule(params).pipe(
      catchError(() => {
        return of([]);
      }),
      finalize(() => {
        this.loadingSubject.next(false);
      })
    ).subscribe(count => {
      this.showScheduleCount.next(count as number);
    });
  }
}

