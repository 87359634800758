import { DataSource, CollectionViewer } from '@angular/cdk/collections';
import { catchError, finalize } from 'rxjs/operators';
import { Observable, of as observableOf, merge, BehaviorSubject, of } from 'rxjs';
import { RetrieveService } from '../api/services/retrieve.service';
import { Content } from '../api/models/content';
import { SearchParams } from './contents-list.component';

/**
 * Data source for the ContentsList view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class ContentsListDataSource implements DataSource<Content> {

  private contentsSubject = new BehaviorSubject<Content[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private contentsCount = new BehaviorSubject(0);

  public loading$ = this.loadingSubject.asObservable();
  public count$ = this.contentsCount.asObservable();

  constructor(private retrieveService: RetrieveService) {
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(collectionViewer: CollectionViewer): Observable<Content[]> {
    return this.contentsSubject.asObservable();
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect(collectionViewer: CollectionViewer): void {
    this.contentsSubject.complete();
    this.loadingSubject.complete();
  }

  loadContents(params: SearchParams) {

    this.loadingSubject.next(true);

    /* Retrieve contents */
    this.retrieveService.getContentsList(params).pipe(
      catchError(() => {
        // console.error('getContentsList ERROR');
        return of([]);
      }),
      finalize(() => {
        // console.log('getContentsList OK');
        this.loadingSubject.next(false);
      })
    ).subscribe(contents => {
      // console.log(contents);
      this.contentsSubject.next(contents);
    });

    /* Count contents */
    this.retrieveService.countContents(params).pipe(
      catchError(() => {
        return of([]);
      }),
      finalize(() => {
        this.loadingSubject.next(false);
      })
    ).subscribe(count => {
      this.contentsCount.next(count as number);
    });
  }

  updateContent(amid: string): void {

    this.loadingSubject.next(true);

    let currentContents = this.contentsSubject.getValue();
    let idx: number;
    for (idx = 0; currentContents[idx].amid != amid; idx++);

    this.retrieveService.getContent({ amid: amid })
      .subscribe(
        (content) => {
          currentContents[idx] = content;
          this.contentsSubject.next(currentContents);
        },
        () => { },
        () => {
          this.loadingSubject.next(false);
        }
      );
  }

}

