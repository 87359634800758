/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Amchid } from '../models/amchid';
import { Amid } from '../models/amid';
import { AudioMatchJob } from '../models/audio-match-job';
import { AudioMatchJobContents } from '../models/audio-match-job-contents';
import { AudioMatchRun } from '../models/audio-match-run';
import { AudiomatchChannel } from '../models/audiomatch-channel';
import { AudiomatchTaskStatus } from '../models/audiomatch-task-status';
import { Channel } from '../models/channel';
import { ChannelBundle } from '../models/channel-bundle';
import { Content } from '../models/content';
import { ContentTitle } from '../models/content-title';
import { Count } from '../models/count';
import { DateTime } from '../models/date-time';
import { DownloadTaskStatus } from '../models/download-task-status';
import { Editor } from '../models/editor';
import { ExportShowSchedule } from '../models/export-show-schedule';
import { FieldName } from '../models/field-name';
import { FingerprintVersion } from '../models/fingerprint-version';
import { Id } from '../models/id';
import { Index } from '../models/index';
import { IndexUri } from '../models/index-uri';
import { Match } from '../models/match';
import { Metrics } from '../models/metrics';
import { MonitoredChannel } from '../models/monitored-channel';
import { MonitoredChannelChannel } from '../models/monitored-channel-channel';
import { MonitoredChannelEditor } from '../models/monitored-channel-editor';
import { MonitoredChannelStatus } from '../models/monitored-channel-status';
import { MonitoredChannelUdPport } from '../models/monitored-channel-ud-pport';
import { NamedSeries } from '../models/named-series';
import { OnpremiseServer } from '../models/onpremise-server';
import { OnpremiseServerId } from '../models/onpremise-server-id';
import { OnpremiseServerLocation } from '../models/onpremise-server-location';
import { OnpremiseServerName } from '../models/onpremise-server-name';
import { OnpremiseServerStatus } from '../models/onpremise-server-status';
import { OnpremiseServersMetrics } from '../models/onpremise-servers-metrics';
import { PartialFull } from '../models/partial-full';
import { Reference } from '../models/reference';
import { ShowSchedule } from '../models/show-schedule';
import { ShowScheduleAnomaly } from '../models/show-schedule-anomaly';
import { ShowScheduleStatus } from '../models/show-schedule-status';
import { TaskStatus } from '../models/task-status';
import { Type } from '../models/type';


/**
 * Retrieve information
 */
@Injectable({
  providedIn: 'root',
})
export class RetrieveService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getNumChannels
   */
  static readonly GetNumChannelsPath = '/count/channels';

  /**
   * Number of channels.
   *
   * Retrieve the number of channels
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNumChannels()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNumChannels$Response(params?: {

    /**
     * Channel ID
     */
    channel?: Channel;

    /**
     * Content editor
     */
    editor?: Editor;
  }): Observable<StrictHttpResponse<Count>> {

    const rb = new RequestBuilder(this.rootUrl, RetrieveService.GetNumChannelsPath, 'get');
    if (params) {
      rb.query('channel', params.channel, {});
      rb.query('editor', params.editor, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Count>;
      })
    );
  }

  /**
   * Number of channels.
   *
   * Retrieve the number of channels
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getNumChannels$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNumChannels(params?: {

    /**
     * Channel ID
     */
    channel?: Channel;

    /**
     * Content editor
     */
    editor?: Editor;
  }): Observable<Count> {

    return this.getNumChannels$Response(params).pipe(
      map((r: StrictHttpResponse<Count>) => r.body as Count)
    );
  }

  /**
   * Path part for operation countContents
   */
  static readonly CountContentsPath = '/count/contents/{type}';

  /**
   * Count contents.
   *
   * Retrieve the number of records satisfying the provided query parameters
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countContents()` instead.
   *
   * This method doesn't expect any request body.
   */
  countContents$Response(params: {

    /**
     * Content&#x27;s type
     */
    type: Type;

    /**
     * Content editor
     */
    editor?: Editor;

    /**
     * Channel
     */
    channel?: Channel;

    /**
     * Content Title
     */
    title?: ContentTitle;

    /**
     * ID of the server that has downloaded the content (for linear content)
     */
    id_server?: OnpremiseServerId;

    /**
     * UDP port from which the content has been downloaded (for linear content)
     */
    udp_port?: MonitoredChannelUdPport;

    /**
     * onair start interval
     */
    onairFrom?: DateTime;

    /**
     * onair end interval
     */
    onairTo?: DateTime;

    /**
     * published start interval
     */
    publishedFrom?: DateTime;

    /**
     * published end interval
     */
    publishedTo?: DateTime;

    /**
     * inserted start interval
     */
    insertedFrom?: DateTime;

    /**
     * inserted end interval
     */
    insertedTo?: DateTime;

    /**
     * Download task status filter
     */
    download?: DownloadTaskStatus;

    /**
     * Audio feature extraction task status filter
     */
    audiofeat?: TaskStatus;

    /**
     * Indexing of audio featuretask status filter
     */
    indexing?: TaskStatus;

    /**
     * Audio feature matching task status filter
     */
    audiomatch?: AudiomatchTaskStatus;

    /**
     * Job Id of the audiomatch batch job. If specified together with audiomatch it is used for filtering contents
     */
    audiomatchJobId?: string;

    /**
     * Audiomatch policy boolean value
     */
    includeLinear?: boolean;

    /**
     * Audiomatch policy boolean value
     */
    includeOndemand?: boolean;

    /**
     * Audiomatch policy boolean value
     */
    includeListOfReferences?: boolean;

    /**
     * Audiomatch policy editor
     */
    policyEditor?: Editor;

    /**
     * Audiomatch policy channel
     */
    policyChannel?: Channel;

    /**
     * Audiomatch policy inserted from
     */
    policyInsertedFrom?: DateTime;

    /**
     * Audiomatch policy inserted to
     */
    policyInsertedTo?: DateTime;

    /**
     * Audiomatch policy onair from
     */
    policyOnairFrom?: DateTime;

    /**
     * Audiomatch policy onair to
     */
    policyOnairTo?: DateTime;

    /**
     * Audiomatch policy published from
     */
    policyPublishedFrom?: DateTime;

    /**
     * Audiomatch policy published to
     */
    policyPublishedTo?: DateTime;

    /**
     * Audiomatch policy published to
     */
    policySearchOption?: 'exists' | 'last';

    /**
     * Select only contents that have some match
     */
    withMatches?: boolean;

    /**
     * Select only contents that have at least one audiomatch run
     */
    hasAudiomatchRuns?: boolean;

    /**
     * audiomath run status
     */
    audiomatchRunStatus?: TaskStatus;

    /**
     * audiomath run last update from
     */
    audiomatchRunLastUpdateFrom?: DateTime;

    /**
     * audiomath run last update to
     */
    audiomatchRunLastUpdateTo?: DateTime;

    /**
     * Fingerprint version (required if audiofeat or audiomatch filters are used)
     */
    version?: FingerprintVersion;

    /**
     * Content duration value
     */
    durationValue?: number;

    /**
     * Content duration type (less than (lt), greater than (gt), equal (eq))
     */
    durationType?: 'gt' | 'lt' | 'eq';
    durationFrom?: number;
    durationTo?: number;

    /**
     * Program Description similarity threshold
     */
    descriptionSimilarityThreshold?: number;

    /**
     * Program description for similarity check
     */
    descriptionSimilarityValue?: string;

    /**
     * channel brand description as given in libreria dei contenuti
     */
    channelBrandDescription?: string;

    /**
     * initial channel description as given in libreria dei contenuti
     */
    initialChannelDescription?: string;

    /**
     * time initial broadcast as given in libreria dei contenuti
     */
    timeInitialBroadcastFrom?: DateTime;

    /**
     * time initial broadcast as given in libreria dei contenuti
     */
    timeInitialBroadcastTo?: DateTime;

    /**
     * Description of adv content (only for content with type adv)
     */
    advSubject?: string;
  }): Observable<StrictHttpResponse<Count>> {

    const rb = new RequestBuilder(this.rootUrl, RetrieveService.CountContentsPath, 'get');
    if (params) {
      rb.path('type', params.type, {});
      rb.query('editor', params.editor, {});
      rb.query('channel', params.channel, {});
      rb.query('title', params.title, {});
      rb.query('id_server', params.id_server, {});
      rb.query('udp_port', params.udp_port, {});
      rb.query('onairFrom', params.onairFrom, {});
      rb.query('onairTo', params.onairTo, {});
      rb.query('publishedFrom', params.publishedFrom, {});
      rb.query('publishedTo', params.publishedTo, {});
      rb.query('insertedFrom', params.insertedFrom, {});
      rb.query('insertedTo', params.insertedTo, {});
      rb.query('download', params.download, {});
      rb.query('audiofeat', params.audiofeat, {});
      rb.query('indexing', params.indexing, {});
      rb.query('audiomatch', params.audiomatch, {});
      rb.query('audiomatchJobId', params.audiomatchJobId, {});
      rb.query('includeLinear', params.includeLinear, {});
      rb.query('includeOndemand', params.includeOndemand, {});
      rb.query('includeListOfReferences', params.includeListOfReferences, {});
      rb.query('policyEditor', params.policyEditor, {});
      rb.query('policyChannel', params.policyChannel, {});
      rb.query('policyInsertedFrom', params.policyInsertedFrom, {});
      rb.query('policyInsertedTo', params.policyInsertedTo, {});
      rb.query('policyOnairFrom', params.policyOnairFrom, {});
      rb.query('policyOnairTo', params.policyOnairTo, {});
      rb.query('policyPublishedFrom', params.policyPublishedFrom, {});
      rb.query('policyPublishedTo', params.policyPublishedTo, {});
      rb.query('policySearchOption', params.policySearchOption, {});
      rb.query('withMatches', params.withMatches, {});
      rb.query('hasAudiomatchRuns', params.hasAudiomatchRuns, {});
      rb.query('audiomatchRunStatus', params.audiomatchRunStatus, {});
      rb.query('audiomatchRunLastUpdateFrom', params.audiomatchRunLastUpdateFrom, {});
      rb.query('audiomatchRunLastUpdateTo', params.audiomatchRunLastUpdateTo, {});
      rb.query('version', params.version, {});
      rb.query('durationValue', params.durationValue, {});
      rb.query('durationType', params.durationType, {});
      rb.query('durationFrom', params.durationFrom, {});
      rb.query('durationTo', params.durationTo, {});
      rb.query('descriptionSimilarityThreshold', params.descriptionSimilarityThreshold, {});
      rb.query('descriptionSimilarityValue', params.descriptionSimilarityValue, {});
      rb.query('channelBrandDescription', params.channelBrandDescription, {});
      rb.query('initialChannelDescription', params.initialChannelDescription, {});
      rb.query('timeInitialBroadcastFrom', params.timeInitialBroadcastFrom, {});
      rb.query('timeInitialBroadcastTo', params.timeInitialBroadcastTo, {});
      rb.query('advSubject', params.advSubject, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Count>;
      })
    );
  }

  /**
   * Count contents.
   *
   * Retrieve the number of records satisfying the provided query parameters
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `countContents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  countContents(params: {

    /**
     * Content&#x27;s type
     */
    type: Type;

    /**
     * Content editor
     */
    editor?: Editor;

    /**
     * Channel
     */
    channel?: Channel;

    /**
     * Content Title
     */
    title?: ContentTitle;

    /**
     * ID of the server that has downloaded the content (for linear content)
     */
    id_server?: OnpremiseServerId;

    /**
     * UDP port from which the content has been downloaded (for linear content)
     */
    udp_port?: MonitoredChannelUdPport;

    /**
     * onair start interval
     */
    onairFrom?: DateTime;

    /**
     * onair end interval
     */
    onairTo?: DateTime;

    /**
     * published start interval
     */
    publishedFrom?: DateTime;

    /**
     * published end interval
     */
    publishedTo?: DateTime;

    /**
     * inserted start interval
     */
    insertedFrom?: DateTime;

    /**
     * inserted end interval
     */
    insertedTo?: DateTime;

    /**
     * Download task status filter
     */
    download?: DownloadTaskStatus;

    /**
     * Audio feature extraction task status filter
     */
    audiofeat?: TaskStatus;

    /**
     * Indexing of audio featuretask status filter
     */
    indexing?: TaskStatus;

    /**
     * Audio feature matching task status filter
     */
    audiomatch?: AudiomatchTaskStatus;

    /**
     * Job Id of the audiomatch batch job. If specified together with audiomatch it is used for filtering contents
     */
    audiomatchJobId?: string;

    /**
     * Audiomatch policy boolean value
     */
    includeLinear?: boolean;

    /**
     * Audiomatch policy boolean value
     */
    includeOndemand?: boolean;

    /**
     * Audiomatch policy boolean value
     */
    includeListOfReferences?: boolean;

    /**
     * Audiomatch policy editor
     */
    policyEditor?: Editor;

    /**
     * Audiomatch policy channel
     */
    policyChannel?: Channel;

    /**
     * Audiomatch policy inserted from
     */
    policyInsertedFrom?: DateTime;

    /**
     * Audiomatch policy inserted to
     */
    policyInsertedTo?: DateTime;

    /**
     * Audiomatch policy onair from
     */
    policyOnairFrom?: DateTime;

    /**
     * Audiomatch policy onair to
     */
    policyOnairTo?: DateTime;

    /**
     * Audiomatch policy published from
     */
    policyPublishedFrom?: DateTime;

    /**
     * Audiomatch policy published to
     */
    policyPublishedTo?: DateTime;

    /**
     * Audiomatch policy published to
     */
    policySearchOption?: 'exists' | 'last';

    /**
     * Select only contents that have some match
     */
    withMatches?: boolean;

    /**
     * Select only contents that have at least one audiomatch run
     */
    hasAudiomatchRuns?: boolean;

    /**
     * audiomath run status
     */
    audiomatchRunStatus?: TaskStatus;

    /**
     * audiomath run last update from
     */
    audiomatchRunLastUpdateFrom?: DateTime;

    /**
     * audiomath run last update to
     */
    audiomatchRunLastUpdateTo?: DateTime;

    /**
     * Fingerprint version (required if audiofeat or audiomatch filters are used)
     */
    version?: FingerprintVersion;

    /**
     * Content duration value
     */
    durationValue?: number;

    /**
     * Content duration type (less than (lt), greater than (gt), equal (eq))
     */
    durationType?: 'gt' | 'lt' | 'eq';
    durationFrom?: number;
    durationTo?: number;

    /**
     * Program Description similarity threshold
     */
    descriptionSimilarityThreshold?: number;

    /**
     * Program description for similarity check
     */
    descriptionSimilarityValue?: string;

    /**
     * channel brand description as given in libreria dei contenuti
     */
    channelBrandDescription?: string;

    /**
     * initial channel description as given in libreria dei contenuti
     */
    initialChannelDescription?: string;

    /**
     * time initial broadcast as given in libreria dei contenuti
     */
    timeInitialBroadcastFrom?: DateTime;

    /**
     * time initial broadcast as given in libreria dei contenuti
     */
    timeInitialBroadcastTo?: DateTime;

    /**
     * Description of adv content (only for content with type adv)
     */
    advSubject?: string;
  }): Observable<Count> {

    return this.countContents$Response(params).pipe(
      map((r: StrictHttpResponse<Count>) => r.body as Count)
    );
  }

  /**
   * Path part for operation countReferences
   */
  static readonly CountReferencesPath = '/count/references/{amid}/{type}';

  /**
   * Count reference contents.
   *
   * Count the number of references for matching in the system for the given type. Optionally filter by partial match with 'editor'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countReferences()` instead.
   *
   * This method doesn't expect any request body.
   */
  countReferences$Response(params: {

    /**
     * Content&#x27;s type
     */
    type: Type;

    /**
     * Query AMID
     */
    amid: Amid;

    /**
     * Fingerprint version
     */
    version: FingerprintVersion;

    /**
     * Content editor
     */
    editor?: Editor;

    /**
     * Channel ID
     */
    channel?: Channel;

    /**
     * ID of the server that has downloaded the content (for linear content)
     */
    idServer?: OnpremiseServerId;

    /**
     * UDP port from which the content has been downloaded (for linear content)
     */
    udpPort?: MonitoredChannelUdPport;

    /**
     * onair start interval
     */
    onairFrom?: DateTime;

    /**
     * onair end interval
     */
    onairTo?: DateTime;

    /**
     * published start interval
     */
    publishedFrom?: DateTime;

    /**
     * published end interval
     */
    publishedTo?: DateTime;

    /**
     * inserted start interval
     */
    insertedFrom?: DateTime;

    /**
     * inserted end interval
     */
    insertedTo?: DateTime;
  }): Observable<StrictHttpResponse<Count>> {

    const rb = new RequestBuilder(this.rootUrl, RetrieveService.CountReferencesPath, 'get');
    if (params) {
      rb.path('type', params.type, {});
      rb.path('amid', params.amid, {});
      rb.query('version', params.version, {});
      rb.query('editor', params.editor, {});
      rb.query('channel', params.channel, {});
      rb.query('idServer', params.idServer, {});
      rb.query('udpPort', params.udpPort, {});
      rb.query('onairFrom', params.onairFrom, {});
      rb.query('onairTo', params.onairTo, {});
      rb.query('publishedFrom', params.publishedFrom, {});
      rb.query('publishedTo', params.publishedTo, {});
      rb.query('insertedFrom', params.insertedFrom, {});
      rb.query('insertedTo', params.insertedTo, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Count>;
      })
    );
  }

  /**
   * Count reference contents.
   *
   * Count the number of references for matching in the system for the given type. Optionally filter by partial match with 'editor'
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `countReferences$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  countReferences(params: {

    /**
     * Content&#x27;s type
     */
    type: Type;

    /**
     * Query AMID
     */
    amid: Amid;

    /**
     * Fingerprint version
     */
    version: FingerprintVersion;

    /**
     * Content editor
     */
    editor?: Editor;

    /**
     * Channel ID
     */
    channel?: Channel;

    /**
     * ID of the server that has downloaded the content (for linear content)
     */
    idServer?: OnpremiseServerId;

    /**
     * UDP port from which the content has been downloaded (for linear content)
     */
    udpPort?: MonitoredChannelUdPport;

    /**
     * onair start interval
     */
    onairFrom?: DateTime;

    /**
     * onair end interval
     */
    onairTo?: DateTime;

    /**
     * published start interval
     */
    publishedFrom?: DateTime;

    /**
     * published end interval
     */
    publishedTo?: DateTime;

    /**
     * inserted start interval
     */
    insertedFrom?: DateTime;

    /**
     * inserted end interval
     */
    insertedTo?: DateTime;
  }): Observable<Count> {

    return this.countReferences$Response(params).pipe(
      map((r: StrictHttpResponse<Count>) => r.body as Count)
    );
  }

  /**
   * Path part for operation getContentsList
   */
  static readonly GetContentsListPath = '/contents/{type}';

  /**
   * List contents.
   *
   * Retrieve a list of contents in the system for the given type. At most 25 records are returned for each call. Use the **offset** query parameter for pagination. Optionally filter by partial match with 'editor' and 'id'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getContentsList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContentsList$Response(params: {

    /**
     * Content&#x27;s type
     */
    type: Type;

    /**
     * List of contents table columns to be returned
     */
    fields?: Array<FieldName>;

    /**
     * Content ID
     */
    id?: Id;

    /**
     * Content Title
     */
    title?: ContentTitle;

    /**
     * Content editor
     */
    editor?: Editor;

    /**
     * Channel
     */
    channel?: Channel;

    /**
     * ID of the server that has downloaded the content (for linear content)
     */
    id_server?: OnpremiseServerId;

    /**
     * UDP port from which the content has been downloaded (for linear content)
     */
    udp_port?: MonitoredChannelUdPport;

    /**
     * onair start interval
     */
    onairFrom?: DateTime;

    /**
     * onair end interval
     */
    onairTo?: DateTime;

    /**
     * published start interval
     */
    publishedFrom?: DateTime;

    /**
     * published end interval
     */
    publishedTo?: DateTime;

    /**
     * inserted start interval
     */
    insertedFrom?: DateTime;

    /**
     * inserted end interval
     */
    insertedTo?: DateTime;

    /**
     * Download task status filter
     */
    download?: DownloadTaskStatus;

    /**
     * Audio feature extraction task status filter
     */
    audiofeat?: TaskStatus;

    /**
     * Indexing of the audio feature task status filter
     */
    indexing?: TaskStatus;

    /**
     * Audio feature matching task status filter
     */
    audiomatch?: AudiomatchTaskStatus;

    /**
     * Job Id of the audiomatch batch job. If specified together with audiomatch it is used for filtering contents
     */
    audiomatchJobId?: string;

    /**
     * Audiomatch policy boolean value
     */
    includeLinear?: boolean;

    /**
     * Audiomatch policy boolean value
     */
    includeOndemand?: boolean;

    /**
     * Audiomatch policy boolean value
     */
    includeListOfReferences?: boolean;

    /**
     * Audiomatch policy editor
     */
    policyEditor?: Editor;

    /**
     * Audiomatch policy channel
     */
    policyChannel?: Channel;

    /**
     * Audiomatch policy inserted from
     */
    policyInsertedFrom?: DateTime;

    /**
     * Audiomatch policy inserted to
     */
    policyInsertedTo?: DateTime;

    /**
     * Audiomatch policy onair from
     */
    policyOnairFrom?: DateTime;

    /**
     * Audiomatch policy onair to
     */
    policyOnairTo?: DateTime;

    /**
     * Audiomatch policy published from
     */
    policyPublishedFrom?: DateTime;

    /**
     * Audiomatch policy published to
     */
    policyPublishedTo?: DateTime;

    /**
     * Audiomatch policy published to
     */
    policySearchOption?: 'exists' | 'last';

    /**
     * Select only contents that have some match
     */
    withMatches?: boolean;

    /**
     * Select only contents that have at least one audiomatch run
     */
    hasAudiomatchRuns?: boolean;

    /**
     * audiomath run status
     */
    audiomatchRunStatus?: TaskStatus;

    /**
     * audiomath run last update from
     */
    audiomatchRunLastUpdateFrom?: DateTime;

    /**
     * audiomath run last update to
     */
    audiomatchRunLastUpdateTo?: DateTime;

    /**
     * Fingerprint version (required if audiofeat or audiomatch filters are used)
     */
    version?: FingerprintVersion;

    /**
     * Content duration value
     */
    durationValue?: number;

    /**
     * Content duration type (less than (lt), greater than (gt), equal (eq))
     */
    durationType?: 'gt' | 'lt' | 'eq';
    durationFrom?: number;
    durationTo?: number;

    /**
     * Program Description similarity threshold
     */
    descriptionSimilarityThreshold?: number;

    /**
     * Program description for similarity check
     */
    descriptionSimilarityValue?: string;

    /**
     * channel brand description as given in libreria dei contenuti
     */
    channelBrandDescription?: string;

    /**
     * initial channel description as given in libreria dei contenuti
     */
    initialChannelDescription?: string;

    /**
     * time initial broadcast as given in libreria dei contenuti
     */
    timeInitialBroadcastFrom?: DateTime;

    /**
     * time initial broadcast as given in libreria dei contenuti
     */
    timeInitialBroadcastTo?: DateTime;

    /**
     * Description of adv content (only for content with type adv)
     */
    advSubject?: string;

    /**
     * if true all contents are returned without limit
     */
    forExporting?: boolean;

    /**
     * Attribute to sort
     */
    sort?: 'id' | 'editor' | 'published' | 'onair' | 'inserted' | 'download' | 'audiofeat' | 'indexing' | 'audiomatch' | 'duration';

    /**
     * Sorting order
     */
    order?: 'asc' | 'desc';

    /**
     * List offset for pagination
     */
    offset?: number;

    /**
     * Limit the number of retrieved contents
     */
    limit?: number;
  }): Observable<StrictHttpResponse<Array<Content>>> {

    const rb = new RequestBuilder(this.rootUrl, RetrieveService.GetContentsListPath, 'get');
    if (params) {
      rb.path('type', params.type, {});
      rb.query('fields', params.fields, {"style":"pipeDelimited","explode":false});
      rb.query('id', params.id, {});
      rb.query('title', params.title, {});
      rb.query('editor', params.editor, {});
      rb.query('channel', params.channel, {});
      rb.query('id_server', params.id_server, {});
      rb.query('udp_port', params.udp_port, {});
      rb.query('onairFrom', params.onairFrom, {});
      rb.query('onairTo', params.onairTo, {});
      rb.query('publishedFrom', params.publishedFrom, {});
      rb.query('publishedTo', params.publishedTo, {});
      rb.query('insertedFrom', params.insertedFrom, {});
      rb.query('insertedTo', params.insertedTo, {});
      rb.query('download', params.download, {});
      rb.query('audiofeat', params.audiofeat, {});
      rb.query('indexing', params.indexing, {});
      rb.query('audiomatch', params.audiomatch, {});
      rb.query('audiomatchJobId', params.audiomatchJobId, {});
      rb.query('includeLinear', params.includeLinear, {});
      rb.query('includeOndemand', params.includeOndemand, {});
      rb.query('includeListOfReferences', params.includeListOfReferences, {});
      rb.query('policyEditor', params.policyEditor, {});
      rb.query('policyChannel', params.policyChannel, {});
      rb.query('policyInsertedFrom', params.policyInsertedFrom, {});
      rb.query('policyInsertedTo', params.policyInsertedTo, {});
      rb.query('policyOnairFrom', params.policyOnairFrom, {});
      rb.query('policyOnairTo', params.policyOnairTo, {});
      rb.query('policyPublishedFrom', params.policyPublishedFrom, {});
      rb.query('policyPublishedTo', params.policyPublishedTo, {});
      rb.query('policySearchOption', params.policySearchOption, {});
      rb.query('withMatches', params.withMatches, {});
      rb.query('hasAudiomatchRuns', params.hasAudiomatchRuns, {});
      rb.query('audiomatchRunStatus', params.audiomatchRunStatus, {});
      rb.query('audiomatchRunLastUpdateFrom', params.audiomatchRunLastUpdateFrom, {});
      rb.query('audiomatchRunLastUpdateTo', params.audiomatchRunLastUpdateTo, {});
      rb.query('version', params.version, {});
      rb.query('durationValue', params.durationValue, {});
      rb.query('durationType', params.durationType, {});
      rb.query('durationFrom', params.durationFrom, {});
      rb.query('durationTo', params.durationTo, {});
      rb.query('descriptionSimilarityThreshold', params.descriptionSimilarityThreshold, {});
      rb.query('descriptionSimilarityValue', params.descriptionSimilarityValue, {});
      rb.query('channelBrandDescription', params.channelBrandDescription, {});
      rb.query('initialChannelDescription', params.initialChannelDescription, {});
      rb.query('timeInitialBroadcastFrom', params.timeInitialBroadcastFrom, {});
      rb.query('timeInitialBroadcastTo', params.timeInitialBroadcastTo, {});
      rb.query('advSubject', params.advSubject, {});
      rb.query('forExporting', params.forExporting, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
      rb.query('offset', params.offset, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Content>>;
      })
    );
  }

  /**
   * List contents.
   *
   * Retrieve a list of contents in the system for the given type. At most 25 records are returned for each call. Use the **offset** query parameter for pagination. Optionally filter by partial match with 'editor' and 'id'
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getContentsList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContentsList(params: {

    /**
     * Content&#x27;s type
     */
    type: Type;

    /**
     * List of contents table columns to be returned
     */
    fields?: Array<FieldName>;

    /**
     * Content ID
     */
    id?: Id;

    /**
     * Content Title
     */
    title?: ContentTitle;

    /**
     * Content editor
     */
    editor?: Editor;

    /**
     * Channel
     */
    channel?: Channel;

    /**
     * ID of the server that has downloaded the content (for linear content)
     */
    id_server?: OnpremiseServerId;

    /**
     * UDP port from which the content has been downloaded (for linear content)
     */
    udp_port?: MonitoredChannelUdPport;

    /**
     * onair start interval
     */
    onairFrom?: DateTime;

    /**
     * onair end interval
     */
    onairTo?: DateTime;

    /**
     * published start interval
     */
    publishedFrom?: DateTime;

    /**
     * published end interval
     */
    publishedTo?: DateTime;

    /**
     * inserted start interval
     */
    insertedFrom?: DateTime;

    /**
     * inserted end interval
     */
    insertedTo?: DateTime;

    /**
     * Download task status filter
     */
    download?: DownloadTaskStatus;

    /**
     * Audio feature extraction task status filter
     */
    audiofeat?: TaskStatus;

    /**
     * Indexing of the audio feature task status filter
     */
    indexing?: TaskStatus;

    /**
     * Audio feature matching task status filter
     */
    audiomatch?: AudiomatchTaskStatus;

    /**
     * Job Id of the audiomatch batch job. If specified together with audiomatch it is used for filtering contents
     */
    audiomatchJobId?: string;

    /**
     * Audiomatch policy boolean value
     */
    includeLinear?: boolean;

    /**
     * Audiomatch policy boolean value
     */
    includeOndemand?: boolean;

    /**
     * Audiomatch policy boolean value
     */
    includeListOfReferences?: boolean;

    /**
     * Audiomatch policy editor
     */
    policyEditor?: Editor;

    /**
     * Audiomatch policy channel
     */
    policyChannel?: Channel;

    /**
     * Audiomatch policy inserted from
     */
    policyInsertedFrom?: DateTime;

    /**
     * Audiomatch policy inserted to
     */
    policyInsertedTo?: DateTime;

    /**
     * Audiomatch policy onair from
     */
    policyOnairFrom?: DateTime;

    /**
     * Audiomatch policy onair to
     */
    policyOnairTo?: DateTime;

    /**
     * Audiomatch policy published from
     */
    policyPublishedFrom?: DateTime;

    /**
     * Audiomatch policy published to
     */
    policyPublishedTo?: DateTime;

    /**
     * Audiomatch policy published to
     */
    policySearchOption?: 'exists' | 'last';

    /**
     * Select only contents that have some match
     */
    withMatches?: boolean;

    /**
     * Select only contents that have at least one audiomatch run
     */
    hasAudiomatchRuns?: boolean;

    /**
     * audiomath run status
     */
    audiomatchRunStatus?: TaskStatus;

    /**
     * audiomath run last update from
     */
    audiomatchRunLastUpdateFrom?: DateTime;

    /**
     * audiomath run last update to
     */
    audiomatchRunLastUpdateTo?: DateTime;

    /**
     * Fingerprint version (required if audiofeat or audiomatch filters are used)
     */
    version?: FingerprintVersion;

    /**
     * Content duration value
     */
    durationValue?: number;

    /**
     * Content duration type (less than (lt), greater than (gt), equal (eq))
     */
    durationType?: 'gt' | 'lt' | 'eq';
    durationFrom?: number;
    durationTo?: number;

    /**
     * Program Description similarity threshold
     */
    descriptionSimilarityThreshold?: number;

    /**
     * Program description for similarity check
     */
    descriptionSimilarityValue?: string;

    /**
     * channel brand description as given in libreria dei contenuti
     */
    channelBrandDescription?: string;

    /**
     * initial channel description as given in libreria dei contenuti
     */
    initialChannelDescription?: string;

    /**
     * time initial broadcast as given in libreria dei contenuti
     */
    timeInitialBroadcastFrom?: DateTime;

    /**
     * time initial broadcast as given in libreria dei contenuti
     */
    timeInitialBroadcastTo?: DateTime;

    /**
     * Description of adv content (only for content with type adv)
     */
    advSubject?: string;

    /**
     * if true all contents are returned without limit
     */
    forExporting?: boolean;

    /**
     * Attribute to sort
     */
    sort?: 'id' | 'editor' | 'published' | 'onair' | 'inserted' | 'download' | 'audiofeat' | 'indexing' | 'audiomatch' | 'duration';

    /**
     * Sorting order
     */
    order?: 'asc' | 'desc';

    /**
     * List offset for pagination
     */
    offset?: number;

    /**
     * Limit the number of retrieved contents
     */
    limit?: number;
  }): Observable<Array<Content>> {

    return this.getContentsList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Content>>) => r.body as Array<Content>)
    );
  }

  /**
   * Path part for operation getChannelsList
   */
  static readonly GetChannelsListPath = '/channels';

  /**
   * List channels.
   *
   * Retrieve a list of channels
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChannelsList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChannelsList$Response(params?: {

    /**
     * Channel ID
     */
    channel?: Channel;

    /**
     * Content editor
     */
    editor?: Editor;

    /**
     * Attribute to sort
     */
    sort?: 'channel' | 'editor';

    /**
     * Sorting order
     */
    order?: 'asc' | 'desc';

    /**
     * List offset for pagination
     */
    offset?: number;

    /**
     * Limit the number of retrieved contents
     */
    limit?: number;
  }): Observable<StrictHttpResponse<Array<ChannelBundle>>> {

    const rb = new RequestBuilder(this.rootUrl, RetrieveService.GetChannelsListPath, 'get');
    if (params) {
      rb.query('channel', params.channel, {});
      rb.query('editor', params.editor, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
      rb.query('offset', params.offset, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ChannelBundle>>;
      })
    );
  }

  /**
   * List channels.
   *
   * Retrieve a list of channels
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getChannelsList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChannelsList(params?: {

    /**
     * Channel ID
     */
    channel?: Channel;

    /**
     * Content editor
     */
    editor?: Editor;

    /**
     * Attribute to sort
     */
    sort?: 'channel' | 'editor';

    /**
     * Sorting order
     */
    order?: 'asc' | 'desc';

    /**
     * List offset for pagination
     */
    offset?: number;

    /**
     * Limit the number of retrieved contents
     */
    limit?: number;
  }): Observable<Array<ChannelBundle>> {

    return this.getChannelsList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ChannelBundle>>) => r.body as Array<ChannelBundle>)
    );
  }

  /**
   * Path part for operation getContent
   */
  static readonly GetContentPath = '/content/{amid}';

  /**
   * Get a content.
   *
   * Retrieve all the information about a content
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getContent()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContent$Response(params: {

    /**
     * AudioMatch ID of the content
     */
    amid: Amid;
  }): Observable<StrictHttpResponse<Content>> {

    const rb = new RequestBuilder(this.rootUrl, RetrieveService.GetContentPath, 'get');
    if (params) {
      rb.path('amid', params.amid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Content>;
      })
    );
  }

  /**
   * Get a content.
   *
   * Retrieve all the information about a content
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getContent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContent(params: {

    /**
     * AudioMatch ID of the content
     */
    amid: Amid;
  }): Observable<Content> {

    return this.getContent$Response(params).pipe(
      map((r: StrictHttpResponse<Content>) => r.body as Content)
    );
  }

  /**
   * Path part for operation getReferencesList
   */
  static readonly GetReferencesListPath = '/references/{amid}/{type}';

  /**
   * List reference contents.
   *
   * Retrieve a list of references for matching in the system for the given type. Optionally filter by partial match with 'editor'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReferencesList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReferencesList$Response(params: {

    /**
     * Content&#x27;s type
     */
    type: Type;

    /**
     * Query AMID
     */
    amid: Amid;

    /**
     * Fingerprint version
     */
    version: FingerprintVersion;

    /**
     * Content editor
     */
    editor?: Editor;

    /**
     * Channel ID
     */
    channel?: Channel;

    /**
     * ID of the server that has downloaded the content (for linear content)
     */
    idServer?: OnpremiseServerId;

    /**
     * UDP port from which the content has been downloaded (for linear content)
     */
    udpPort?: MonitoredChannelUdPport;

    /**
     * onair start interval
     */
    onairFrom?: DateTime;

    /**
     * onair end interval
     */
    onairTo?: DateTime;

    /**
     * published start interval
     */
    publishedFrom?: DateTime;

    /**
     * published end interval
     */
    publishedTo?: DateTime;

    /**
     * inserted start interval
     */
    insertedFrom?: DateTime;

    /**
     * inserted end interval
     */
    insertedTo?: DateTime;
  }): Observable<StrictHttpResponse<Array<Reference>>> {

    const rb = new RequestBuilder(this.rootUrl, RetrieveService.GetReferencesListPath, 'get');
    if (params) {
      rb.path('type', params.type, {});
      rb.path('amid', params.amid, {});
      rb.query('version', params.version, {});
      rb.query('editor', params.editor, {});
      rb.query('channel', params.channel, {});
      rb.query('idServer', params.idServer, {});
      rb.query('udpPort', params.udpPort, {});
      rb.query('onairFrom', params.onairFrom, {});
      rb.query('onairTo', params.onairTo, {});
      rb.query('publishedFrom', params.publishedFrom, {});
      rb.query('publishedTo', params.publishedTo, {});
      rb.query('insertedFrom', params.insertedFrom, {});
      rb.query('insertedTo', params.insertedTo, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Reference>>;
      })
    );
  }

  /**
   * List reference contents.
   *
   * Retrieve a list of references for matching in the system for the given type. Optionally filter by partial match with 'editor'
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getReferencesList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReferencesList(params: {

    /**
     * Content&#x27;s type
     */
    type: Type;

    /**
     * Query AMID
     */
    amid: Amid;

    /**
     * Fingerprint version
     */
    version: FingerprintVersion;

    /**
     * Content editor
     */
    editor?: Editor;

    /**
     * Channel ID
     */
    channel?: Channel;

    /**
     * ID of the server that has downloaded the content (for linear content)
     */
    idServer?: OnpremiseServerId;

    /**
     * UDP port from which the content has been downloaded (for linear content)
     */
    udpPort?: MonitoredChannelUdPport;

    /**
     * onair start interval
     */
    onairFrom?: DateTime;

    /**
     * onair end interval
     */
    onairTo?: DateTime;

    /**
     * published start interval
     */
    publishedFrom?: DateTime;

    /**
     * published end interval
     */
    publishedTo?: DateTime;

    /**
     * inserted start interval
     */
    insertedFrom?: DateTime;

    /**
     * inserted end interval
     */
    insertedTo?: DateTime;
  }): Observable<Array<Reference>> {

    return this.getReferencesList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Reference>>) => r.body as Array<Reference>)
    );
  }

  /**
   * Path part for operation getReferencesListInIndex
   */
  static readonly GetReferencesListInIndexPath = '/references/{index_uri}';

  /**
   * List reference contents in index.
   *
   * Retrieve a list of references contained in the index given by index_uri for a given fingerprint version
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReferencesListInIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReferencesListInIndex$Response(params: {

    /**
     * index_uri
     */
    index_uri: IndexUri;

    /**
     * Fingerprint version
     */
    version: FingerprintVersion;
  }): Observable<StrictHttpResponse<Array<Reference>>> {

    const rb = new RequestBuilder(this.rootUrl, RetrieveService.GetReferencesListInIndexPath, 'get');
    if (params) {
      rb.path('index_uri', params.index_uri, {});
      rb.query('version', params.version, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Reference>>;
      })
    );
  }

  /**
   * List reference contents in index.
   *
   * Retrieve a list of references contained in the index given by index_uri for a given fingerprint version
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getReferencesListInIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReferencesListInIndex(params: {

    /**
     * index_uri
     */
    index_uri: IndexUri;

    /**
     * Fingerprint version
     */
    version: FingerprintVersion;
  }): Observable<Array<Reference>> {

    return this.getReferencesListInIndex$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Reference>>) => r.body as Array<Reference>)
    );
  }

  /**
   * Path part for operation countContentsInIndex
   */
  static readonly CountContentsInIndexPath = '/count/references/{index_uri}';

  /**
   * Count contents in index.
   *
   * Retrieve the number of references contained in the index given by index_uri for a given fingerprint version
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countContentsInIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  countContentsInIndex$Response(params: {

    /**
     * index_uri
     */
    index_uri: IndexUri;

    /**
     * Fingerprint version
     */
    version: FingerprintVersion;
  }): Observable<StrictHttpResponse<Count>> {

    const rb = new RequestBuilder(this.rootUrl, RetrieveService.CountContentsInIndexPath, 'get');
    if (params) {
      rb.path('index_uri', params.index_uri, {});
      rb.query('version', params.version, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Count>;
      })
    );
  }

  /**
   * Count contents in index.
   *
   * Retrieve the number of references contained in the index given by index_uri for a given fingerprint version
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `countContentsInIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  countContentsInIndex(params: {

    /**
     * index_uri
     */
    index_uri: IndexUri;

    /**
     * Fingerprint version
     */
    version: FingerprintVersion;
  }): Observable<Count> {

    return this.countContentsInIndex$Response(params).pipe(
      map((r: StrictHttpResponse<Count>) => r.body as Count)
    );
  }

  /**
   * Path part for operation getIndex
   */
  static readonly GetIndexPath = '/content/{amid}/indexing/index';

  /**
   * get index_uri and index_pts_uri for indexing.
   *
   * get index_uri and index_pts_uri for indexing
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIndex$Response(params: {

    /**
     * AudioMatch ID of the content
     */
    amid: Amid;

    /**
     * Fingerprint algorithm version
     */
    version: FingerprintVersion;
  }): Observable<StrictHttpResponse<Index>> {

    const rb = new RequestBuilder(this.rootUrl, RetrieveService.GetIndexPath, 'get');
    if (params) {
      rb.path('amid', params.amid, {});
      rb.query('version', params.version, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Index>;
      })
    );
  }

  /**
   * get index_uri and index_pts_uri for indexing.
   *
   * get index_uri and index_pts_uri for indexing
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIndex(params: {

    /**
     * AudioMatch ID of the content
     */
    amid: Amid;

    /**
     * Fingerprint algorithm version
     */
    version: FingerprintVersion;
  }): Observable<Index> {

    return this.getIndex$Response(params).pipe(
      map((r: StrictHttpResponse<Index>) => r.body as Index)
    );
  }

  /**
   * Path part for operation getEditorsList
   */
  static readonly GetEditorsListPath = '/editors';

  /**
   * List of editors.
   *
   * Retrieve a list of distinct editors from the contents table
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEditorsList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEditorsList$Response(params?: {
  }): Observable<StrictHttpResponse<Array<Editor>>> {

    const rb = new RequestBuilder(this.rootUrl, RetrieveService.GetEditorsListPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Editor>>;
      })
    );
  }

  /**
   * List of editors.
   *
   * Retrieve a list of distinct editors from the contents table
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEditorsList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEditorsList(params?: {
  }): Observable<Array<Editor>> {

    return this.getEditorsList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Editor>>) => r.body as Array<Editor>)
    );
  }

  /**
   * Path part for operation getChannelBrandDescriptionList
   */
  static readonly GetChannelBrandDescriptionListPath = '/channelbranddescriptions';

  /**
   * List of channel brand description.
   *
   * Retrieve a list of distinct channel brand description from the contents table
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChannelBrandDescriptionList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChannelBrandDescriptionList$Response(params?: {

    /**
     * Editor
     */
    editor?: Editor;

    /**
     * start date
     */
    dateFrom?: DateTime;

    /**
     * end date
     */
    dateTo?: DateTime;
  }): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, RetrieveService.GetChannelBrandDescriptionListPath, 'get');
    if (params) {
      rb.query('editor', params.editor, {});
      rb.query('dateFrom', params.dateFrom, {});
      rb.query('dateTo', params.dateTo, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * List of channel brand description.
   *
   * Retrieve a list of distinct channel brand description from the contents table
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getChannelBrandDescriptionList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChannelBrandDescriptionList(params?: {

    /**
     * Editor
     */
    editor?: Editor;

    /**
     * start date
     */
    dateFrom?: DateTime;

    /**
     * end date
     */
    dateTo?: DateTime;
  }): Observable<Array<string>> {

    return this.getChannelBrandDescriptionList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation getAudiomatchRunsList
   */
  static readonly GetAudiomatchRunsListPath = '/audiomatchruns';

  /**
   * List audiomatch runs.
   *
   * Retrieve a list of audiomatch runs in the system for the given type. At most 25 records are returned for each call. Use the **offset** query parameter for pagination
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAudiomatchRunsList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAudiomatchRunsList$Response(params?: {

    /**
     * id of the audiomatch run
     */
    id?: string;
    query_amid?: Amid;

    /**
     * Status of the run
     */
    status?: TaskStatus;

    /**
     * Audiofeat version
     */
    version?: FingerprintVersion;

    /**
     * last_update from the audiomatch run
     */
    last_update_from?: DateTime;

    /**
     * last_update to the audiomatch run
     */
    last_update_to?: DateTime;

    /**
     * inserted from the audiomatch run
     */
    inserted_from?: DateTime;

    /**
     * inserted_to the audiomatch run
     */
    inserted_to?: DateTime;

    /**
     * inserted_to the audiomatch run
     */
    count_grouped_by_date?: boolean;

    /**
     * Attribute to sort
     */
    sort?: 'inserted' | 'last_update';

    /**
     * Sorting order
     */
    order?: 'asc' | 'desc';

    /**
     * List offset for pagination
     */
    offset?: number;

    /**
     * Limit the number of retrieved contents
     */
    limit?: number;
  }): Observable<StrictHttpResponse<Array<AudioMatchRun>>> {

    const rb = new RequestBuilder(this.rootUrl, RetrieveService.GetAudiomatchRunsListPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
      rb.query('query_amid', params.query_amid, {});
      rb.query('status', params.status, {});
      rb.query('version', params.version, {});
      rb.query('last_update_from', params.last_update_from, {});
      rb.query('last_update_to', params.last_update_to, {});
      rb.query('inserted_from', params.inserted_from, {});
      rb.query('inserted_to', params.inserted_to, {});
      rb.query('count_grouped_by_date', params.count_grouped_by_date, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
      rb.query('offset', params.offset, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AudioMatchRun>>;
      })
    );
  }

  /**
   * List audiomatch runs.
   *
   * Retrieve a list of audiomatch runs in the system for the given type. At most 25 records are returned for each call. Use the **offset** query parameter for pagination
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAudiomatchRunsList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAudiomatchRunsList(params?: {

    /**
     * id of the audiomatch run
     */
    id?: string;
    query_amid?: Amid;

    /**
     * Status of the run
     */
    status?: TaskStatus;

    /**
     * Audiofeat version
     */
    version?: FingerprintVersion;

    /**
     * last_update from the audiomatch run
     */
    last_update_from?: DateTime;

    /**
     * last_update to the audiomatch run
     */
    last_update_to?: DateTime;

    /**
     * inserted from the audiomatch run
     */
    inserted_from?: DateTime;

    /**
     * inserted_to the audiomatch run
     */
    inserted_to?: DateTime;

    /**
     * inserted_to the audiomatch run
     */
    count_grouped_by_date?: boolean;

    /**
     * Attribute to sort
     */
    sort?: 'inserted' | 'last_update';

    /**
     * Sorting order
     */
    order?: 'asc' | 'desc';

    /**
     * List offset for pagination
     */
    offset?: number;

    /**
     * Limit the number of retrieved contents
     */
    limit?: number;
  }): Observable<Array<AudioMatchRun>> {

    return this.getAudiomatchRunsList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AudioMatchRun>>) => r.body as Array<AudioMatchRun>)
    );
  }

  /**
   * Path part for operation countAudiomatchRuns
   */
  static readonly CountAudiomatchRunsPath = '/count/audiomatchruns';

  /**
   * Count audiomatch runs.
   *
   * Count audiomatch runs in the system for the given type
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countAudiomatchRuns()` instead.
   *
   * This method doesn't expect any request body.
   */
  countAudiomatchRuns$Response(params?: {

    /**
     * id of the audiomatch run
     */
    id?: string;
    query_amid?: Amid;

    /**
     * Status of the run
     */
    status?: TaskStatus;

    /**
     * Audiofeat version
     */
    version?: FingerprintVersion;

    /**
     * last_update from the audiomatch run
     */
    last_update_from?: DateTime;

    /**
     * last_update to the audiomatch run
     */
    last_update_to?: DateTime;

    /**
     * inserted from the audiomatch run
     */
    inserted_from?: DateTime;

    /**
     * inserted_to the audiomatch run
     */
    inserted_to?: DateTime;

    /**
     * inserted_to the audiomatch run
     */
    count_grouped_by_date?: boolean;
  }): Observable<StrictHttpResponse<Count>> {

    const rb = new RequestBuilder(this.rootUrl, RetrieveService.CountAudiomatchRunsPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
      rb.query('query_amid', params.query_amid, {});
      rb.query('status', params.status, {});
      rb.query('version', params.version, {});
      rb.query('last_update_from', params.last_update_from, {});
      rb.query('last_update_to', params.last_update_to, {});
      rb.query('inserted_from', params.inserted_from, {});
      rb.query('inserted_to', params.inserted_to, {});
      rb.query('count_grouped_by_date', params.count_grouped_by_date, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Count>;
      })
    );
  }

  /**
   * Count audiomatch runs.
   *
   * Count audiomatch runs in the system for the given type
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `countAudiomatchRuns$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  countAudiomatchRuns(params?: {

    /**
     * id of the audiomatch run
     */
    id?: string;
    query_amid?: Amid;

    /**
     * Status of the run
     */
    status?: TaskStatus;

    /**
     * Audiofeat version
     */
    version?: FingerprintVersion;

    /**
     * last_update from the audiomatch run
     */
    last_update_from?: DateTime;

    /**
     * last_update to the audiomatch run
     */
    last_update_to?: DateTime;

    /**
     * inserted from the audiomatch run
     */
    inserted_from?: DateTime;

    /**
     * inserted_to the audiomatch run
     */
    inserted_to?: DateTime;

    /**
     * inserted_to the audiomatch run
     */
    count_grouped_by_date?: boolean;
  }): Observable<Count> {

    return this.countAudiomatchRuns$Response(params).pipe(
      map((r: StrictHttpResponse<Count>) => r.body as Count)
    );
  }

  /**
   * Path part for operation getAudiomatchJobsList
   */
  static readonly GetAudiomatchJobsListPath = '/audiomatchjobs';

  /**
   * List audiomatch jobs.
   *
   * Retrieve a list of audiomatch jobs in the system for the given type. At most 25 records are returned for each call. Use the **offset** query parameter for pagination
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAudiomatchJobsList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAudiomatchJobsList$Response(params?: {

    /**
     * id of the audiomatch job
     */
    id?: string;

    /**
     * id of the audiomatch run
     */
    run_id?: string;

    /**
     * Status of the run
     */
    status?: TaskStatus;

    /**
     * Attribute to sort
     */
    sort?: 'id' | 'inserted' | 'last_update';

    /**
     * Sorting order
     */
    order?: 'asc' | 'desc';

    /**
     * List offset for pagination
     */
    offset?: number;

    /**
     * Limit the number of retrieved contents
     */
    limit?: number;
  }): Observable<StrictHttpResponse<Array<AudioMatchJob>>> {

    const rb = new RequestBuilder(this.rootUrl, RetrieveService.GetAudiomatchJobsListPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
      rb.query('run_id', params.run_id, {});
      rb.query('status', params.status, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
      rb.query('offset', params.offset, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AudioMatchJob>>;
      })
    );
  }

  /**
   * List audiomatch jobs.
   *
   * Retrieve a list of audiomatch jobs in the system for the given type. At most 25 records are returned for each call. Use the **offset** query parameter for pagination
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAudiomatchJobsList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAudiomatchJobsList(params?: {

    /**
     * id of the audiomatch job
     */
    id?: string;

    /**
     * id of the audiomatch run
     */
    run_id?: string;

    /**
     * Status of the run
     */
    status?: TaskStatus;

    /**
     * Attribute to sort
     */
    sort?: 'id' | 'inserted' | 'last_update';

    /**
     * Sorting order
     */
    order?: 'asc' | 'desc';

    /**
     * List offset for pagination
     */
    offset?: number;

    /**
     * Limit the number of retrieved contents
     */
    limit?: number;
  }): Observable<Array<AudioMatchJob>> {

    return this.getAudiomatchJobsList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AudioMatchJob>>) => r.body as Array<AudioMatchJob>)
    );
  }

  /**
   * Path part for operation countAudiomatchJobs
   */
  static readonly CountAudiomatchJobsPath = '/count/audiomatchjobs';

  /**
   * Count audiomatch jobs.
   *
   * Count audiomatch jobs in the system for the given type
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countAudiomatchJobs()` instead.
   *
   * This method doesn't expect any request body.
   */
  countAudiomatchJobs$Response(params?: {

    /**
     * id of the audiomatch job
     */
    id?: string;

    /**
     * id of the audiomatch run
     */
    run_id?: string;

    /**
     * Status of the run
     */
    status?: TaskStatus;
  }): Observable<StrictHttpResponse<Count>> {

    const rb = new RequestBuilder(this.rootUrl, RetrieveService.CountAudiomatchJobsPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
      rb.query('run_id', params.run_id, {});
      rb.query('status', params.status, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Count>;
      })
    );
  }

  /**
   * Count audiomatch jobs.
   *
   * Count audiomatch jobs in the system for the given type
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `countAudiomatchJobs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  countAudiomatchJobs(params?: {

    /**
     * id of the audiomatch job
     */
    id?: string;

    /**
     * id of the audiomatch run
     */
    run_id?: string;

    /**
     * Status of the run
     */
    status?: TaskStatus;
  }): Observable<Count> {

    return this.countAudiomatchJobs$Response(params).pipe(
      map((r: StrictHttpResponse<Count>) => r.body as Count)
    );
  }

  /**
   * Path part for operation getAudiomatchJobContentsList
   */
  static readonly GetAudiomatchJobContentsListPath = '/audiomatchjobcontents';

  /**
   * List audiomatch job contents.
   *
   * Retrieve a list of audiomatch job contents in the system for the given type. At most 25 records are returned for each call. Use the **offset** query parameter for pagination
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAudiomatchJobContentsList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAudiomatchJobContentsList$Response(params?: {
    query_amid?: Amid;
    reference_amid?: Amid;

    /**
     * id of the audiomatch job
     */
    job_id?: string;

    /**
     * Status of the run
     */
    status?: TaskStatus;

    /**
     * Attribute to sort
     */
    sort?: 'query_amid' | 'last_update';

    /**
     * Sorting order
     */
    order?: 'asc' | 'desc';

    /**
     * List offset for pagination
     */
    offset?: number;

    /**
     * Limit the number of retrieved contents
     */
    limit?: number;
  }): Observable<StrictHttpResponse<Array<AudioMatchJobContents>>> {

    const rb = new RequestBuilder(this.rootUrl, RetrieveService.GetAudiomatchJobContentsListPath, 'get');
    if (params) {
      rb.query('query_amid', params.query_amid, {});
      rb.query('reference_amid', params.reference_amid, {});
      rb.query('job_id', params.job_id, {});
      rb.query('status', params.status, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
      rb.query('offset', params.offset, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AudioMatchJobContents>>;
      })
    );
  }

  /**
   * List audiomatch job contents.
   *
   * Retrieve a list of audiomatch job contents in the system for the given type. At most 25 records are returned for each call. Use the **offset** query parameter for pagination
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAudiomatchJobContentsList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAudiomatchJobContentsList(params?: {
    query_amid?: Amid;
    reference_amid?: Amid;

    /**
     * id of the audiomatch job
     */
    job_id?: string;

    /**
     * Status of the run
     */
    status?: TaskStatus;

    /**
     * Attribute to sort
     */
    sort?: 'query_amid' | 'last_update';

    /**
     * Sorting order
     */
    order?: 'asc' | 'desc';

    /**
     * List offset for pagination
     */
    offset?: number;

    /**
     * Limit the number of retrieved contents
     */
    limit?: number;
  }): Observable<Array<AudioMatchJobContents>> {

    return this.getAudiomatchJobContentsList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AudioMatchJobContents>>) => r.body as Array<AudioMatchJobContents>)
    );
  }

  /**
   * Path part for operation countAudiomatchJobContents
   */
  static readonly CountAudiomatchJobContentsPath = '/count/audiomatchjobcontents';

  /**
   * Count audiomatch job contents.
   *
   * Count audiomatch job contents in the system for the given type
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countAudiomatchJobContents()` instead.
   *
   * This method doesn't expect any request body.
   */
  countAudiomatchJobContents$Response(params?: {
    query_amid?: Amid;
    reference_amid?: Amid;

    /**
     * id of the audiomatch job
     */
    job_id?: string;

    /**
     * Status of the run
     */
    status?: TaskStatus;
  }): Observable<StrictHttpResponse<Count>> {

    const rb = new RequestBuilder(this.rootUrl, RetrieveService.CountAudiomatchJobContentsPath, 'get');
    if (params) {
      rb.query('query_amid', params.query_amid, {});
      rb.query('reference_amid', params.reference_amid, {});
      rb.query('job_id', params.job_id, {});
      rb.query('status', params.status, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Count>;
      })
    );
  }

  /**
   * Count audiomatch job contents.
   *
   * Count audiomatch job contents in the system for the given type
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `countAudiomatchJobContents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  countAudiomatchJobContents(params?: {
    query_amid?: Amid;
    reference_amid?: Amid;

    /**
     * id of the audiomatch job
     */
    job_id?: string;

    /**
     * Status of the run
     */
    status?: TaskStatus;
  }): Observable<Count> {

    return this.countAudiomatchJobContents$Response(params).pipe(
      map((r: StrictHttpResponse<Count>) => r.body as Count)
    );
  }

  /**
   * Path part for operation getMatches
   */
  static readonly GetMatchesPath = '/matches/{amid}';

  /**
   * Get matches for a content.
   *
   * Retrieve all the mathces for a given content
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMatches()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMatches$Response(params: {

    /**
     * AudioMatch ID of the content
     */
    amid: Amid;

    /**
     * Fingerprint algorithm version
     */
    version: FingerprintVersion;

    /**
     * Retrieve also contents for which a match was searched but not found
     */
    empty?: boolean;

    /**
     * Retrieve only matches with minimum duration greater than minDuration. This parameter is ignored when empty is True
     */
    minDuration?: number;

    /**
     * Content&#x27;s type
     */
    type?: Type;

    /**
     * onair start interval
     */
    onairFrom?: DateTime;

    /**
     * onair end interval
     */
    onairTo?: DateTime;

    /**
     * published start interval
     */
    publishedFrom?: DateTime;

    /**
     * published end interval
     */
    publishedTo?: DateTime;
  }): Observable<StrictHttpResponse<Array<Match>>> {

    const rb = new RequestBuilder(this.rootUrl, RetrieveService.GetMatchesPath, 'get');
    if (params) {
      rb.path('amid', params.amid, {});
      rb.query('version', params.version, {});
      rb.query('empty', params.empty, {});
      rb.query('minDuration', params.minDuration, {});
      rb.query('type', params.type, {});
      rb.query('onairFrom', params.onairFrom, {});
      rb.query('onairTo', params.onairTo, {});
      rb.query('publishedFrom', params.publishedFrom, {});
      rb.query('publishedTo', params.publishedTo, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Match>>;
      })
    );
  }

  /**
   * Get matches for a content.
   *
   * Retrieve all the mathces for a given content
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMatches$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMatches(params: {

    /**
     * AudioMatch ID of the content
     */
    amid: Amid;

    /**
     * Fingerprint algorithm version
     */
    version: FingerprintVersion;

    /**
     * Retrieve also contents for which a match was searched but not found
     */
    empty?: boolean;

    /**
     * Retrieve only matches with minimum duration greater than minDuration. This parameter is ignored when empty is True
     */
    minDuration?: number;

    /**
     * Content&#x27;s type
     */
    type?: Type;

    /**
     * onair start interval
     */
    onairFrom?: DateTime;

    /**
     * onair end interval
     */
    onairTo?: DateTime;

    /**
     * published start interval
     */
    publishedFrom?: DateTime;

    /**
     * published end interval
     */
    publishedTo?: DateTime;
  }): Observable<Array<Match>> {

    return this.getMatches$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Match>>) => r.body as Array<Match>)
    );
  }

  /**
   * Path part for operation countMatches
   */
  static readonly CountMatchesPath = '/count/matches/{amid}';

  /**
   * Count matches for a content.
   *
   * Retrieve all the mathces for a given content
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countMatches()` instead.
   *
   * This method doesn't expect any request body.
   */
  countMatches$Response(params: {

    /**
     * AudioMatch ID of the content
     */
    amid: Amid;

    /**
     * Fingerprint algorithm version
     */
    version: FingerprintVersion;

    /**
     * Retrieve also contents for which a match was searched but not found
     */
    empty?: boolean;

    /**
     * Retrieve only matches with minimum duration greater than minDuration. This parameter is ignored when empty is True
     */
    minDuration?: number;

    /**
     * Content&#x27;s type
     */
    type?: Type;

    /**
     * onair start interval
     */
    onairFrom?: DateTime;

    /**
     * onair end interval
     */
    onairTo?: DateTime;

    /**
     * published start interval
     */
    publishedFrom?: DateTime;

    /**
     * published end interval
     */
    publishedTo?: DateTime;

    /**
     * groupBy count
     */
    groupBy?: 'onair' | 'published';
  }): Observable<StrictHttpResponse<Count>> {

    const rb = new RequestBuilder(this.rootUrl, RetrieveService.CountMatchesPath, 'get');
    if (params) {
      rb.path('amid', params.amid, {});
      rb.query('version', params.version, {});
      rb.query('empty', params.empty, {});
      rb.query('minDuration', params.minDuration, {});
      rb.query('type', params.type, {});
      rb.query('onairFrom', params.onairFrom, {});
      rb.query('onairTo', params.onairTo, {});
      rb.query('publishedFrom', params.publishedFrom, {});
      rb.query('publishedTo', params.publishedTo, {});
      rb.query('groupBy', params.groupBy, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Count>;
      })
    );
  }

  /**
   * Count matches for a content.
   *
   * Retrieve all the mathces for a given content
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `countMatches$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  countMatches(params: {

    /**
     * AudioMatch ID of the content
     */
    amid: Amid;

    /**
     * Fingerprint algorithm version
     */
    version: FingerprintVersion;

    /**
     * Retrieve also contents for which a match was searched but not found
     */
    empty?: boolean;

    /**
     * Retrieve only matches with minimum duration greater than minDuration. This parameter is ignored when empty is True
     */
    minDuration?: number;

    /**
     * Content&#x27;s type
     */
    type?: Type;

    /**
     * onair start interval
     */
    onairFrom?: DateTime;

    /**
     * onair end interval
     */
    onairTo?: DateTime;

    /**
     * published start interval
     */
    publishedFrom?: DateTime;

    /**
     * published end interval
     */
    publishedTo?: DateTime;

    /**
     * groupBy count
     */
    groupBy?: 'onair' | 'published';
  }): Observable<Count> {

    return this.countMatches$Response(params).pipe(
      map((r: StrictHttpResponse<Count>) => r.body as Count)
    );
  }

  /**
   * Path part for operation getOnpremiseServersList
   */
  static readonly GetOnpremiseServersListPath = '/onpremiseservers';

  /**
   * List of onpremise servers.
   *
   * Retrieve a list of onpremise servers in the system. At most 25 records are returned for each call if limit is different from -1. Use the **offset** query parameter for pagination. Optionally filter by partial match with 'name' and 'location'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOnpremiseServersList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOnpremiseServersList$Response(params?: {

    /**
     * ID of the onpremise server
     */
    id?: OnpremiseServerId;

    /**
     * Onpremise Server name
     */
    name?: OnpremiseServerName;

    /**
     * geo-location of the onpremise server
     */
    location?: OnpremiseServerLocation;

    /**
     * status of the onpremise server
     */
    status?: OnpremiseServerStatus;

    /**
     * lsat_update received from the onpremise server
     */
    last_update_from?: DateTime;

    /**
     * lsat_update received from the onpremise server
     */
    last_update_to?: DateTime;

    /**
     * Attribute to sort
     */
    sort?: 'id' | 'name' | 'location' | 'status' | 'last_update';

    /**
     * Sorting order
     */
    order?: 'asc' | 'desc';

    /**
     * List offset for pagination
     */
    offset?: number;

    /**
     * Limit the number of retrieved contents
     */
    limit?: number;
  }): Observable<StrictHttpResponse<Array<OnpremiseServer>>> {

    const rb = new RequestBuilder(this.rootUrl, RetrieveService.GetOnpremiseServersListPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
      rb.query('name', params.name, {});
      rb.query('location', params.location, {});
      rb.query('status', params.status, {});
      rb.query('last_update_from', params.last_update_from, {});
      rb.query('last_update_to', params.last_update_to, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
      rb.query('offset', params.offset, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OnpremiseServer>>;
      })
    );
  }

  /**
   * List of onpremise servers.
   *
   * Retrieve a list of onpremise servers in the system. At most 25 records are returned for each call if limit is different from -1. Use the **offset** query parameter for pagination. Optionally filter by partial match with 'name' and 'location'
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOnpremiseServersList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOnpremiseServersList(params?: {

    /**
     * ID of the onpremise server
     */
    id?: OnpremiseServerId;

    /**
     * Onpremise Server name
     */
    name?: OnpremiseServerName;

    /**
     * geo-location of the onpremise server
     */
    location?: OnpremiseServerLocation;

    /**
     * status of the onpremise server
     */
    status?: OnpremiseServerStatus;

    /**
     * lsat_update received from the onpremise server
     */
    last_update_from?: DateTime;

    /**
     * lsat_update received from the onpremise server
     */
    last_update_to?: DateTime;

    /**
     * Attribute to sort
     */
    sort?: 'id' | 'name' | 'location' | 'status' | 'last_update';

    /**
     * Sorting order
     */
    order?: 'asc' | 'desc';

    /**
     * List offset for pagination
     */
    offset?: number;

    /**
     * Limit the number of retrieved contents
     */
    limit?: number;
  }): Observable<Array<OnpremiseServer>> {

    return this.getOnpremiseServersList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OnpremiseServer>>) => r.body as Array<OnpremiseServer>)
    );
  }

  /**
   * Path part for operation getNumOnpremiseServers
   */
  static readonly GetNumOnpremiseServersPath = '/count/onpremiseservers';

  /**
   * Number of onpremise servers.
   *
   * Retrieve the number of onpremise servers
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNumOnpremiseServers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNumOnpremiseServers$Response(params?: {

    /**
     * ID of the onpremise server
     */
    id?: OnpremiseServerId;

    /**
     * Onpremise Server name
     */
    name?: OnpremiseServerName;

    /**
     * geo-location of the onpremise server
     */
    location?: OnpremiseServerLocation;

    /**
     * status of the onpremise server
     */
    status?: OnpremiseServerStatus;

    /**
     * lsat_update received from the onpremise server
     */
    last_update_from?: DateTime;

    /**
     * lsat_update received from the onpremise server
     */
    last_update_to?: DateTime;
  }): Observable<StrictHttpResponse<Count>> {

    const rb = new RequestBuilder(this.rootUrl, RetrieveService.GetNumOnpremiseServersPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
      rb.query('name', params.name, {});
      rb.query('location', params.location, {});
      rb.query('status', params.status, {});
      rb.query('last_update_from', params.last_update_from, {});
      rb.query('last_update_to', params.last_update_to, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Count>;
      })
    );
  }

  /**
   * Number of onpremise servers.
   *
   * Retrieve the number of onpremise servers
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getNumOnpremiseServers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNumOnpremiseServers(params?: {

    /**
     * ID of the onpremise server
     */
    id?: OnpremiseServerId;

    /**
     * Onpremise Server name
     */
    name?: OnpremiseServerName;

    /**
     * geo-location of the onpremise server
     */
    location?: OnpremiseServerLocation;

    /**
     * status of the onpremise server
     */
    status?: OnpremiseServerStatus;

    /**
     * lsat_update received from the onpremise server
     */
    last_update_from?: DateTime;

    /**
     * lsat_update received from the onpremise server
     */
    last_update_to?: DateTime;
  }): Observable<Count> {

    return this.getNumOnpremiseServers$Response(params).pipe(
      map((r: StrictHttpResponse<Count>) => r.body as Count)
    );
  }

  /**
   * Path part for operation getOnpremiseServer
   */
  static readonly GetOnpremiseServerPath = '/onpremiseserver/{id}';

  /**
   * Get an onpremise server.
   *
   * Retrieve all the information about an onpremise server
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOnpremiseServer()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOnpremiseServer$Response(params: {

    /**
     * OnpremiseServer ID
     */
    id: OnpremiseServerId;
  }): Observable<StrictHttpResponse<OnpremiseServer>> {

    const rb = new RequestBuilder(this.rootUrl, RetrieveService.GetOnpremiseServerPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OnpremiseServer>;
      })
    );
  }

  /**
   * Get an onpremise server.
   *
   * Retrieve all the information about an onpremise server
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOnpremiseServer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOnpremiseServer(params: {

    /**
     * OnpremiseServer ID
     */
    id: OnpremiseServerId;
  }): Observable<OnpremiseServer> {

    return this.getOnpremiseServer$Response(params).pipe(
      map((r: StrictHttpResponse<OnpremiseServer>) => r.body as OnpremiseServer)
    );
  }

  /**
   * Path part for operation getOnpremiseServerMetrics
   */
  static readonly GetOnpremiseServerMetricsPath = '/onpremiseserver/{id}/metrics';

  /**
   * Get an onpremise server metrics.
   *
   * Retrieve all the metrics about an onpremise server
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOnpremiseServerMetrics()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOnpremiseServerMetrics$Response(params: {

    /**
     * OnpremiseServer ID
     */
    id: OnpremiseServerId;

    /**
     * start date
     */
    dateFrom?: DateTime;

    /**
     * end date
     */
    dateTo?: DateTime;

    /**
     * Name of the metric
     */
    name?: OnpremiseServersMetrics;
  }): Observable<StrictHttpResponse<Array<NamedSeries>>> {

    const rb = new RequestBuilder(this.rootUrl, RetrieveService.GetOnpremiseServerMetricsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('dateFrom', params.dateFrom, {});
      rb.query('dateTo', params.dateTo, {});
      rb.query('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<NamedSeries>>;
      })
    );
  }

  /**
   * Get an onpremise server metrics.
   *
   * Retrieve all the metrics about an onpremise server
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOnpremiseServerMetrics$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOnpremiseServerMetrics(params: {

    /**
     * OnpremiseServer ID
     */
    id: OnpremiseServerId;

    /**
     * start date
     */
    dateFrom?: DateTime;

    /**
     * end date
     */
    dateTo?: DateTime;

    /**
     * Name of the metric
     */
    name?: OnpremiseServersMetrics;
  }): Observable<Array<NamedSeries>> {

    return this.getOnpremiseServerMetrics$Response(params).pipe(
      map((r: StrictHttpResponse<Array<NamedSeries>>) => r.body as Array<NamedSeries>)
    );
  }

  /**
   * Path part for operation getAudiomatchChannels
   */
  static readonly GetAudiomatchChannelsPath = '/amchannels';

  /**
   * List of all audiomatch channells.
   *
   * Retrieve a list of all audiomatching channels. Optionally filter by partial match with 'editor' and 'channel'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAudiomatchChannels()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAudiomatchChannels$Response(params?: {

    /**
     * Audiomatch channel id
     */
    amchid?: Amchid;

    /**
     * Channel name
     */
    channel?: MonitoredChannelChannel;

    /**
     * Channel editor
     */
    editor?: MonitoredChannelEditor;

    /**
     * if True return only the channels that are monitored
     */
    isMonitored?: boolean;

    /**
     * status of monitoring
     */
    monitoringStatus?: 'running' | 'error' | 'warning' | 'none';

    /**
     * List offset for pagination
     */
    offset?: number;

    /**
     * Limit the number of retrieved contents
     */
    limit?: number;
  }): Observable<StrictHttpResponse<Array<AudiomatchChannel>>> {

    const rb = new RequestBuilder(this.rootUrl, RetrieveService.GetAudiomatchChannelsPath, 'get');
    if (params) {
      rb.query('amchid', params.amchid, {});
      rb.query('channel', params.channel, {});
      rb.query('editor', params.editor, {});
      rb.query('isMonitored', params.isMonitored, {});
      rb.query('monitoringStatus', params.monitoringStatus, {});
      rb.query('offset', params.offset, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AudiomatchChannel>>;
      })
    );
  }

  /**
   * List of all audiomatch channells.
   *
   * Retrieve a list of all audiomatching channels. Optionally filter by partial match with 'editor' and 'channel'
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAudiomatchChannels$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAudiomatchChannels(params?: {

    /**
     * Audiomatch channel id
     */
    amchid?: Amchid;

    /**
     * Channel name
     */
    channel?: MonitoredChannelChannel;

    /**
     * Channel editor
     */
    editor?: MonitoredChannelEditor;

    /**
     * if True return only the channels that are monitored
     */
    isMonitored?: boolean;

    /**
     * status of monitoring
     */
    monitoringStatus?: 'running' | 'error' | 'warning' | 'none';

    /**
     * List offset for pagination
     */
    offset?: number;

    /**
     * Limit the number of retrieved contents
     */
    limit?: number;
  }): Observable<Array<AudiomatchChannel>> {

    return this.getAudiomatchChannels$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AudiomatchChannel>>) => r.body as Array<AudiomatchChannel>)
    );
  }

  /**
   * Path part for operation countAudiomatchChannels
   */
  static readonly CountAudiomatchChannelsPath = '/count/amchannels';

  /**
   * Count all audiomatch channells.
   *
   * Retrieve the count of all audiomatching channels. Optionally filter by partial match with 'editor' and 'channel'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countAudiomatchChannels()` instead.
   *
   * This method doesn't expect any request body.
   */
  countAudiomatchChannels$Response(params?: {

    /**
     * Audiomatch channel id
     */
    amchid?: Amchid;

    /**
     * Channel name
     */
    channel?: MonitoredChannelChannel;

    /**
     * Channel editor
     */
    editor?: MonitoredChannelEditor;

    /**
     * if True return only the channels that are monitored
     */
    isMonitored?: boolean;

    /**
     * status of monitoring
     */
    monitoringStatus?: 'running' | 'error' | 'warning' | 'none';
  }): Observable<StrictHttpResponse<Count>> {

    const rb = new RequestBuilder(this.rootUrl, RetrieveService.CountAudiomatchChannelsPath, 'get');
    if (params) {
      rb.query('amchid', params.amchid, {});
      rb.query('channel', params.channel, {});
      rb.query('editor', params.editor, {});
      rb.query('isMonitored', params.isMonitored, {});
      rb.query('monitoringStatus', params.monitoringStatus, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Count>;
      })
    );
  }

  /**
   * Count all audiomatch channells.
   *
   * Retrieve the count of all audiomatching channels. Optionally filter by partial match with 'editor' and 'channel'
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `countAudiomatchChannels$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  countAudiomatchChannels(params?: {

    /**
     * Audiomatch channel id
     */
    amchid?: Amchid;

    /**
     * Channel name
     */
    channel?: MonitoredChannelChannel;

    /**
     * Channel editor
     */
    editor?: MonitoredChannelEditor;

    /**
     * if True return only the channels that are monitored
     */
    isMonitored?: boolean;

    /**
     * status of monitoring
     */
    monitoringStatus?: 'running' | 'error' | 'warning' | 'none';
  }): Observable<Count> {

    return this.countAudiomatchChannels$Response(params).pipe(
      map((r: StrictHttpResponse<Count>) => r.body as Count)
    );
  }

  /**
   * Path part for operation getMonitoredChannel
   */
  static readonly GetMonitoredChannelPath = '/monitoredchannel/{amchid}/{id_server}/{udp_port}';

  /**
   * Get a monitored channel.
   *
   * Retrieve all the information about a monitore channel
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMonitoredChannel()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMonitoredChannel$Response(params: {

    /**
     * Audio match channel id parameter
     */
    amchid: Amchid;

    /**
     * MonitoredChannel id server
     */
    id_server: OnpremiseServerId;

    /**
     * MonitoredChannel udp port
     */
    udp_port: MonitoredChannelUdPport;
  }): Observable<StrictHttpResponse<MonitoredChannel>> {

    const rb = new RequestBuilder(this.rootUrl, RetrieveService.GetMonitoredChannelPath, 'get');
    if (params) {
      rb.path('amchid', params.amchid, {});
      rb.path('id_server', params.id_server, {});
      rb.path('udp_port', params.udp_port, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MonitoredChannel>;
      })
    );
  }

  /**
   * Get a monitored channel.
   *
   * Retrieve all the information about a monitore channel
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMonitoredChannel$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMonitoredChannel(params: {

    /**
     * Audio match channel id parameter
     */
    amchid: Amchid;

    /**
     * MonitoredChannel id server
     */
    id_server: OnpremiseServerId;

    /**
     * MonitoredChannel udp port
     */
    udp_port: MonitoredChannelUdPport;
  }): Observable<MonitoredChannel> {

    return this.getMonitoredChannel$Response(params).pipe(
      map((r: StrictHttpResponse<MonitoredChannel>) => r.body as MonitoredChannel)
    );
  }

  /**
   * Path part for operation getMonitoredChannelsList
   */
  static readonly GetMonitoredChannelsListPath = '/monitoredchannels';

  /**
   * List of monitored channels.
   *
   * Retrieve a list of monitored channels in the system. At most 25 records are returned for each call. Use the **offset** query parameter for pagination. Optionally filter by partial match with 'editor' and 'channel'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMonitoredChannelsList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMonitoredChannelsList$Response(params?: {

    /**
     * Audiomatch channel id
     */
    amchid?: Amchid;

    /**
     * Channel name
     */
    channel?: MonitoredChannelChannel;

    /**
     * Channel editor
     */
    editor?: MonitoredChannelEditor;

    /**
     * ID of the server monitoring the channel
     */
    id_server?: OnpremiseServerId;

    /**
     * Location of the server of the monitoring the channel
     */
    server_location?: OnpremiseServerLocation;

    /**
     * UDP port of the server for the monitored channel
     */
    udp_port?: MonitoredChannelUdPport;

    /**
     * status of the monitored channel
     */
    status?: MonitoredChannelStatus;

    /**
     * status of the show schedule audiomatch procedure for the monitored channel
     */
    showscheduleStatus?: ShowScheduleStatus;

    /**
     * inserted start interval
     */
    insertedFrom?: DateTime;

    /**
     * inserted end interval
     */
    insertedTo?: DateTime;

    /**
     * if true return graph data
     */
    statusGraphData?: boolean;

    /**
     * statusGraphData start date
     */
    statusGraphDateFrom?: DateTime;

    /**
     * statusGraphData end date
     */
    statusGraphDateTo?: DateTime;

    /**
     * threshold in seconds under which missing intervals are not considered
     */
    missing_intervals_duration_th?: number;

    /**
     * fingerprint version
     */
    fpversion?: FingerprintVersion;
    excludeMasters?: boolean;

    /**
     * Attribute to sort
     */
    sort?: 'channel' | 'editor' | 'id_server' | 'udp_port' | 'status' | 'inserted';

    /**
     * Sorting order
     */
    order?: 'asc' | 'desc';

    /**
     * List offset for pagination
     */
    offset?: number;

    /**
     * Limit the number of retrieved contents
     */
    limit?: number;
  }): Observable<StrictHttpResponse<Array<MonitoredChannel>>> {

    const rb = new RequestBuilder(this.rootUrl, RetrieveService.GetMonitoredChannelsListPath, 'get');
    if (params) {
      rb.query('amchid', params.amchid, {});
      rb.query('channel', params.channel, {});
      rb.query('editor', params.editor, {});
      rb.query('id_server', params.id_server, {});
      rb.query('server_location', params.server_location, {});
      rb.query('udp_port', params.udp_port, {});
      rb.query('status', params.status, {});
      rb.query('showscheduleStatus', params.showscheduleStatus, {});
      rb.query('insertedFrom', params.insertedFrom, {});
      rb.query('insertedTo', params.insertedTo, {});
      rb.query('statusGraphData', params.statusGraphData, {});
      rb.query('statusGraphDateFrom', params.statusGraphDateFrom, {});
      rb.query('statusGraphDateTo', params.statusGraphDateTo, {});
      rb.query('missing_intervals_duration_th', params.missing_intervals_duration_th, {});
      rb.query('fpversion', params.fpversion, {});
      rb.query('excludeMasters', params.excludeMasters, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
      rb.query('offset', params.offset, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MonitoredChannel>>;
      })
    );
  }

  /**
   * List of monitored channels.
   *
   * Retrieve a list of monitored channels in the system. At most 25 records are returned for each call. Use the **offset** query parameter for pagination. Optionally filter by partial match with 'editor' and 'channel'
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMonitoredChannelsList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMonitoredChannelsList(params?: {

    /**
     * Audiomatch channel id
     */
    amchid?: Amchid;

    /**
     * Channel name
     */
    channel?: MonitoredChannelChannel;

    /**
     * Channel editor
     */
    editor?: MonitoredChannelEditor;

    /**
     * ID of the server monitoring the channel
     */
    id_server?: OnpremiseServerId;

    /**
     * Location of the server of the monitoring the channel
     */
    server_location?: OnpremiseServerLocation;

    /**
     * UDP port of the server for the monitored channel
     */
    udp_port?: MonitoredChannelUdPport;

    /**
     * status of the monitored channel
     */
    status?: MonitoredChannelStatus;

    /**
     * status of the show schedule audiomatch procedure for the monitored channel
     */
    showscheduleStatus?: ShowScheduleStatus;

    /**
     * inserted start interval
     */
    insertedFrom?: DateTime;

    /**
     * inserted end interval
     */
    insertedTo?: DateTime;

    /**
     * if true return graph data
     */
    statusGraphData?: boolean;

    /**
     * statusGraphData start date
     */
    statusGraphDateFrom?: DateTime;

    /**
     * statusGraphData end date
     */
    statusGraphDateTo?: DateTime;

    /**
     * threshold in seconds under which missing intervals are not considered
     */
    missing_intervals_duration_th?: number;

    /**
     * fingerprint version
     */
    fpversion?: FingerprintVersion;
    excludeMasters?: boolean;

    /**
     * Attribute to sort
     */
    sort?: 'channel' | 'editor' | 'id_server' | 'udp_port' | 'status' | 'inserted';

    /**
     * Sorting order
     */
    order?: 'asc' | 'desc';

    /**
     * List offset for pagination
     */
    offset?: number;

    /**
     * Limit the number of retrieved contents
     */
    limit?: number;
  }): Observable<Array<MonitoredChannel>> {

    return this.getMonitoredChannelsList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MonitoredChannel>>) => r.body as Array<MonitoredChannel>)
    );
  }

  /**
   * Path part for operation getNumMonitoredChannels
   */
  static readonly GetNumMonitoredChannelsPath = '/count/monitoredchannels';

  /**
   * Number of monitored channels.
   *
   * Retrieve the number of monitored channels
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNumMonitoredChannels()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNumMonitoredChannels$Response(params?: {

    /**
     * Audiomatch channel id
     */
    amchid?: Amchid;

    /**
     * Channel name
     */
    channel?: MonitoredChannelChannel;

    /**
     * Channel editor
     */
    editor?: MonitoredChannelEditor;

    /**
     * ID of the server monitoring the channel
     */
    id_server?: OnpremiseServerId;

    /**
     * Location of the server of the monitoring the channel
     */
    server_location?: OnpremiseServerLocation;

    /**
     * UDP port of the server for the monitored channel
     */
    udp_port?: MonitoredChannelUdPport;

    /**
     * status of the monitored channel
     */
    status?: MonitoredChannelStatus;

    /**
     * status of the show schedule audiomatch procedure for the monitored channel
     */
    showscheduleStatus?: ShowScheduleStatus;

    /**
     * inserted start interval
     */
    insertedFrom?: DateTime;

    /**
     * inserted end interval
     */
    insertedTo?: DateTime;
    excludeMasters?: boolean;

    /**
     * fingerprint version
     */
    fpversion?: FingerprintVersion;
  }): Observable<StrictHttpResponse<Count>> {

    const rb = new RequestBuilder(this.rootUrl, RetrieveService.GetNumMonitoredChannelsPath, 'get');
    if (params) {
      rb.query('amchid', params.amchid, {});
      rb.query('channel', params.channel, {});
      rb.query('editor', params.editor, {});
      rb.query('id_server', params.id_server, {});
      rb.query('server_location', params.server_location, {});
      rb.query('udp_port', params.udp_port, {});
      rb.query('status', params.status, {});
      rb.query('showscheduleStatus', params.showscheduleStatus, {});
      rb.query('insertedFrom', params.insertedFrom, {});
      rb.query('insertedTo', params.insertedTo, {});
      rb.query('excludeMasters', params.excludeMasters, {});
      rb.query('fpversion', params.fpversion, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Count>;
      })
    );
  }

  /**
   * Number of monitored channels.
   *
   * Retrieve the number of monitored channels
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getNumMonitoredChannels$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNumMonitoredChannels(params?: {

    /**
     * Audiomatch channel id
     */
    amchid?: Amchid;

    /**
     * Channel name
     */
    channel?: MonitoredChannelChannel;

    /**
     * Channel editor
     */
    editor?: MonitoredChannelEditor;

    /**
     * ID of the server monitoring the channel
     */
    id_server?: OnpremiseServerId;

    /**
     * Location of the server of the monitoring the channel
     */
    server_location?: OnpremiseServerLocation;

    /**
     * UDP port of the server for the monitored channel
     */
    udp_port?: MonitoredChannelUdPport;

    /**
     * status of the monitored channel
     */
    status?: MonitoredChannelStatus;

    /**
     * status of the show schedule audiomatch procedure for the monitored channel
     */
    showscheduleStatus?: ShowScheduleStatus;

    /**
     * inserted start interval
     */
    insertedFrom?: DateTime;

    /**
     * inserted end interval
     */
    insertedTo?: DateTime;
    excludeMasters?: boolean;

    /**
     * fingerprint version
     */
    fpversion?: FingerprintVersion;
  }): Observable<Count> {

    return this.getNumMonitoredChannels$Response(params).pipe(
      map((r: StrictHttpResponse<Count>) => r.body as Count)
    );
  }

  /**
   * Path part for operation getMastersMonitoredChannelsList
   */
  static readonly GetMastersMonitoredChannelsListPath = '/monitoredchannels/masters';

  /**
   * List of master monitored channels.
   *
   * Retrieve a list of monitored channels in the system. At most 25 records are returned for each call. Use the **offset** query parameter for pagination. Optionally filter by partial match with 'editor' and 'channel'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMastersMonitoredChannelsList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMastersMonitoredChannelsList$Response(params?: {

    /**
     * Audiomatch channel id
     */
    amchid?: Amchid;

    /**
     * Channel name
     */
    channel?: MonitoredChannelChannel;

    /**
     * Channel editor
     */
    editor?: MonitoredChannelEditor;

    /**
     * UDP port of the server for the monitored channel
     */
    udp_port?: MonitoredChannelUdPport;

    /**
     * status of the monitored channel
     */
    status?: MonitoredChannelStatus;

    /**
     * status of the show schedule audiomatch procedure for the monitored channel
     */
    showscheduleStatus?: ShowScheduleStatus;

    /**
     * inserted start interval
     */
    insertedFrom?: DateTime;

    /**
     * inserted end interval
     */
    insertedTo?: DateTime;

    /**
     * if true return graph data
     */
    statusGraphData?: boolean;

    /**
     * statusGraphData start date
     */
    statusGraphDateFrom?: DateTime;

    /**
     * statusGraphData end date
     */
    statusGraphDateTo?: DateTime;

    /**
     * if true return graph data
     */
    sourceGraphData?: boolean;

    /**
     * sourceGraphDate start date
     */
    sourceGraphDateFrom?: DateTime;

    /**
     * sourceGraphDate end date
     */
    sourceGraphDateTo?: DateTime;

    /**
     * fingerprint version
     */
    fpversion?: FingerprintVersion;

    /**
     * Attribute to sort
     */
    sort?: 'channel' | 'editor' | 'id_server' | 'udp_port' | 'status' | 'inserted';

    /**
     * Sorting order
     */
    order?: 'asc' | 'desc';

    /**
     * List offset for pagination
     */
    offset?: number;

    /**
     * Limit the number of retrieved contents
     */
    limit?: number;
  }): Observable<StrictHttpResponse<Array<MonitoredChannel>>> {

    const rb = new RequestBuilder(this.rootUrl, RetrieveService.GetMastersMonitoredChannelsListPath, 'get');
    if (params) {
      rb.query('amchid', params.amchid, {});
      rb.query('channel', params.channel, {});
      rb.query('editor', params.editor, {});
      rb.query('udp_port', params.udp_port, {});
      rb.query('status', params.status, {});
      rb.query('showscheduleStatus', params.showscheduleStatus, {});
      rb.query('insertedFrom', params.insertedFrom, {});
      rb.query('insertedTo', params.insertedTo, {});
      rb.query('statusGraphData', params.statusGraphData, {});
      rb.query('statusGraphDateFrom', params.statusGraphDateFrom, {});
      rb.query('statusGraphDateTo', params.statusGraphDateTo, {});
      rb.query('sourceGraphData', params.sourceGraphData, {});
      rb.query('sourceGraphDateFrom', params.sourceGraphDateFrom, {});
      rb.query('sourceGraphDateTo', params.sourceGraphDateTo, {});
      rb.query('fpversion', params.fpversion, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
      rb.query('offset', params.offset, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MonitoredChannel>>;
      })
    );
  }

  /**
   * List of master monitored channels.
   *
   * Retrieve a list of monitored channels in the system. At most 25 records are returned for each call. Use the **offset** query parameter for pagination. Optionally filter by partial match with 'editor' and 'channel'
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMastersMonitoredChannelsList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMastersMonitoredChannelsList(params?: {

    /**
     * Audiomatch channel id
     */
    amchid?: Amchid;

    /**
     * Channel name
     */
    channel?: MonitoredChannelChannel;

    /**
     * Channel editor
     */
    editor?: MonitoredChannelEditor;

    /**
     * UDP port of the server for the monitored channel
     */
    udp_port?: MonitoredChannelUdPport;

    /**
     * status of the monitored channel
     */
    status?: MonitoredChannelStatus;

    /**
     * status of the show schedule audiomatch procedure for the monitored channel
     */
    showscheduleStatus?: ShowScheduleStatus;

    /**
     * inserted start interval
     */
    insertedFrom?: DateTime;

    /**
     * inserted end interval
     */
    insertedTo?: DateTime;

    /**
     * if true return graph data
     */
    statusGraphData?: boolean;

    /**
     * statusGraphData start date
     */
    statusGraphDateFrom?: DateTime;

    /**
     * statusGraphData end date
     */
    statusGraphDateTo?: DateTime;

    /**
     * if true return graph data
     */
    sourceGraphData?: boolean;

    /**
     * sourceGraphDate start date
     */
    sourceGraphDateFrom?: DateTime;

    /**
     * sourceGraphDate end date
     */
    sourceGraphDateTo?: DateTime;

    /**
     * fingerprint version
     */
    fpversion?: FingerprintVersion;

    /**
     * Attribute to sort
     */
    sort?: 'channel' | 'editor' | 'id_server' | 'udp_port' | 'status' | 'inserted';

    /**
     * Sorting order
     */
    order?: 'asc' | 'desc';

    /**
     * List offset for pagination
     */
    offset?: number;

    /**
     * Limit the number of retrieved contents
     */
    limit?: number;
  }): Observable<Array<MonitoredChannel>> {

    return this.getMastersMonitoredChannelsList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MonitoredChannel>>) => r.body as Array<MonitoredChannel>)
    );
  }

  /**
   * Path part for operation getNumMasterMonitoredChannels
   */
  static readonly GetNumMasterMonitoredChannelsPath = '/count/monitoredchannels/masters';

  /**
   * Number of monitored channels.
   *
   * Retrieve the number of monitored channels
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNumMasterMonitoredChannels()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNumMasterMonitoredChannels$Response(params?: {

    /**
     * Audiomatch channel id
     */
    amchid?: Amchid;

    /**
     * Channel name
     */
    channel?: MonitoredChannelChannel;

    /**
     * Channel editor
     */
    editor?: MonitoredChannelEditor;

    /**
     * UDP port of the server for the monitored channel
     */
    udp_port?: MonitoredChannelUdPport;

    /**
     * status of the monitored channel
     */
    status?: MonitoredChannelStatus;

    /**
     * status of the show schedule audiomatch procedure for the monitored channel
     */
    showscheduleStatus?: ShowScheduleStatus;

    /**
     * inserted start interval
     */
    insertedFrom?: DateTime;

    /**
     * inserted end interval
     */
    insertedTo?: DateTime;

    /**
     * fingerprint version
     */
    fpversion?: FingerprintVersion;
  }): Observable<StrictHttpResponse<Count>> {

    const rb = new RequestBuilder(this.rootUrl, RetrieveService.GetNumMasterMonitoredChannelsPath, 'get');
    if (params) {
      rb.query('amchid', params.amchid, {});
      rb.query('channel', params.channel, {});
      rb.query('editor', params.editor, {});
      rb.query('udp_port', params.udp_port, {});
      rb.query('status', params.status, {});
      rb.query('showscheduleStatus', params.showscheduleStatus, {});
      rb.query('insertedFrom', params.insertedFrom, {});
      rb.query('insertedTo', params.insertedTo, {});
      rb.query('fpversion', params.fpversion, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Count>;
      })
    );
  }

  /**
   * Number of monitored channels.
   *
   * Retrieve the number of monitored channels
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getNumMasterMonitoredChannels$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNumMasterMonitoredChannels(params?: {

    /**
     * Audiomatch channel id
     */
    amchid?: Amchid;

    /**
     * Channel name
     */
    channel?: MonitoredChannelChannel;

    /**
     * Channel editor
     */
    editor?: MonitoredChannelEditor;

    /**
     * UDP port of the server for the monitored channel
     */
    udp_port?: MonitoredChannelUdPport;

    /**
     * status of the monitored channel
     */
    status?: MonitoredChannelStatus;

    /**
     * status of the show schedule audiomatch procedure for the monitored channel
     */
    showscheduleStatus?: ShowScheduleStatus;

    /**
     * inserted start interval
     */
    insertedFrom?: DateTime;

    /**
     * inserted end interval
     */
    insertedTo?: DateTime;

    /**
     * fingerprint version
     */
    fpversion?: FingerprintVersion;
  }): Observable<Count> {

    return this.getNumMasterMonitoredChannels$Response(params).pipe(
      map((r: StrictHttpResponse<Count>) => r.body as Count)
    );
  }

  /**
   * Path part for operation getMonitoredChannelBackup
   */
  static readonly GetMonitoredChannelBackupPath = '/monitoredchannel/{amchid}/{id_server}/{udp_port}/backup';

  /**
   * Get a monitored channel that is the backup of the given monitored channel.
   *
   * Retrieve all the information about a monitored channel that is the backup of the given monitored channel
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMonitoredChannelBackup()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMonitoredChannelBackup$Response(params: {

    /**
     * Audio match channel id parameter
     */
    amchid: Amchid;

    /**
     * MonitoredChannel id server
     */
    id_server: OnpremiseServerId;

    /**
     * MonitoredChannel udp port
     */
    udp_port: MonitoredChannelUdPport;
  }): Observable<StrictHttpResponse<MonitoredChannel>> {

    const rb = new RequestBuilder(this.rootUrl, RetrieveService.GetMonitoredChannelBackupPath, 'get');
    if (params) {
      rb.path('amchid', params.amchid, {});
      rb.path('id_server', params.id_server, {});
      rb.path('udp_port', params.udp_port, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MonitoredChannel>;
      })
    );
  }

  /**
   * Get a monitored channel that is the backup of the given monitored channel.
   *
   * Retrieve all the information about a monitored channel that is the backup of the given monitored channel
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMonitoredChannelBackup$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMonitoredChannelBackup(params: {

    /**
     * Audio match channel id parameter
     */
    amchid: Amchid;

    /**
     * MonitoredChannel id server
     */
    id_server: OnpremiseServerId;

    /**
     * MonitoredChannel udp port
     */
    udp_port: MonitoredChannelUdPport;
  }): Observable<MonitoredChannel> {

    return this.getMonitoredChannelBackup$Response(params).pipe(
      map((r: StrictHttpResponse<MonitoredChannel>) => r.body as MonitoredChannel)
    );
  }

  /**
   * Path part for operation getShowScheduleChannel
   */
  static readonly GetShowScheduleChannelPath = '/showschedule/channel';

  /**
   * Get Show Schedule Channel.
   *
   * Get all the channel that have at least one entry in the showschedule table
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getShowScheduleChannel()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShowScheduleChannel$Response(params?: {
  }): Observable<StrictHttpResponse<Array<ChannelBundle>>> {

    const rb = new RequestBuilder(this.rootUrl, RetrieveService.GetShowScheduleChannelPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ChannelBundle>>;
      })
    );
  }

  /**
   * Get Show Schedule Channel.
   *
   * Get all the channel that have at least one entry in the showschedule table
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getShowScheduleChannel$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShowScheduleChannel(params?: {
  }): Observable<Array<ChannelBundle>> {

    return this.getShowScheduleChannel$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ChannelBundle>>) => r.body as Array<ChannelBundle>)
    );
  }

  /**
   * Path part for operation getShowScheduleList
   */
  static readonly GetShowScheduleListPath = '/matches/showschedule';

  /**
   * List show schedules.
   *
   * Retrieve a list of matches in the system that made up show schedule. At most 25 records are returned for each call. Use the **offset** query parameter for pagination.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getShowScheduleList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShowScheduleList$Response(params?: {

    /**
     * Editor
     */
    editor?: Editor;

    /**
     * Channel
     */
    channel?: Channel;

    /**
     * Amid
     */
    amid?: Amid;

    /**
     * start date from for the given content and channel
     */
    startDateFrom?: DateTime;

    /**
     * start date to for the given content and channel
     */
    startDateTo?: DateTime;

    /**
     * end date from for the given content and channel
     */
    endDateFrom?: DateTime;

    /**
     * end date to for the given content and channel
     */
    endDateTo?: DateTime;

    /**
     * title of the content partial match
     */
    title?: ContentTitle;

    /**
     * only full content will be returner
     */
    onlyFull?: boolean;

    /**
     * partial full relationship details are returned
     */
    partialfull_details?: boolean;

    /**
     * if true all show schedules are returned without limit
     */
    forExporting?: boolean;

    /**
     * List offset for pagination
     */
    offset?: number;

    /**
     * Limit the number of retrieved contents
     */
    limit?: number;

    /**
     * Attribute to sort
     */
    sort?: 'show_date';

    /**
     * Sorting order
     */
    order?: 'asc' | 'desc';
  }): Observable<StrictHttpResponse<Array<ShowSchedule>>> {

    const rb = new RequestBuilder(this.rootUrl, RetrieveService.GetShowScheduleListPath, 'get');
    if (params) {
      rb.query('editor', params.editor, {});
      rb.query('channel', params.channel, {});
      rb.query('amid', params.amid, {});
      rb.query('startDateFrom', params.startDateFrom, {});
      rb.query('startDateTo', params.startDateTo, {});
      rb.query('endDateFrom', params.endDateFrom, {});
      rb.query('endDateTo', params.endDateTo, {});
      rb.query('title', params.title, {});
      rb.query('onlyFull', params.onlyFull, {});
      rb.query('partialfull_details', params.partialfull_details, {});
      rb.query('forExporting', params.forExporting, {});
      rb.query('offset', params.offset, {});
      rb.query('limit', params.limit, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ShowSchedule>>;
      })
    );
  }

  /**
   * List show schedules.
   *
   * Retrieve a list of matches in the system that made up show schedule. At most 25 records are returned for each call. Use the **offset** query parameter for pagination.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getShowScheduleList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShowScheduleList(params?: {

    /**
     * Editor
     */
    editor?: Editor;

    /**
     * Channel
     */
    channel?: Channel;

    /**
     * Amid
     */
    amid?: Amid;

    /**
     * start date from for the given content and channel
     */
    startDateFrom?: DateTime;

    /**
     * start date to for the given content and channel
     */
    startDateTo?: DateTime;

    /**
     * end date from for the given content and channel
     */
    endDateFrom?: DateTime;

    /**
     * end date to for the given content and channel
     */
    endDateTo?: DateTime;

    /**
     * title of the content partial match
     */
    title?: ContentTitle;

    /**
     * only full content will be returner
     */
    onlyFull?: boolean;

    /**
     * partial full relationship details are returned
     */
    partialfull_details?: boolean;

    /**
     * if true all show schedules are returned without limit
     */
    forExporting?: boolean;

    /**
     * List offset for pagination
     */
    offset?: number;

    /**
     * Limit the number of retrieved contents
     */
    limit?: number;

    /**
     * Attribute to sort
     */
    sort?: 'show_date';

    /**
     * Sorting order
     */
    order?: 'asc' | 'desc';
  }): Observable<Array<ShowSchedule>> {

    return this.getShowScheduleList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ShowSchedule>>) => r.body as Array<ShowSchedule>)
    );
  }

  /**
   * Path part for operation exportShowScheduleList
   */
  static readonly ExportShowScheduleListPath = '/matches/showschedule/export';

  /**
   * Export show schedules.
   *
   * Export a list of matches in the system that made up show schedule.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportShowScheduleList()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportShowScheduleList$Response(params: {

    /**
     * Editor
     */
    editor: Editor;

    /**
     * Channel
     */
    channel: Channel;

    /**
     * start date from for the given content and channel
     */
    startDateFrom: DateTime;

    /**
     * start date to for the given content and channel
     */
    startDateTo: DateTime;

    /**
     * only full content will be returner
     */
    onlyFull?: boolean;

    /**
     * partial full relationship details are returned
     */
    showInterruptions?: boolean;
  }): Observable<StrictHttpResponse<Array<ExportShowSchedule>>> {

    const rb = new RequestBuilder(this.rootUrl, RetrieveService.ExportShowScheduleListPath, 'get');
    if (params) {
      rb.query('editor', params.editor, {});
      rb.query('channel', params.channel, {});
      rb.query('startDateFrom', params.startDateFrom, {});
      rb.query('startDateTo', params.startDateTo, {});
      rb.query('onlyFull', params.onlyFull, {});
      rb.query('showInterruptions', params.showInterruptions, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ExportShowSchedule>>;
      })
    );
  }

  /**
   * Export show schedules.
   *
   * Export a list of matches in the system that made up show schedule.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `exportShowScheduleList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportShowScheduleList(params: {

    /**
     * Editor
     */
    editor: Editor;

    /**
     * Channel
     */
    channel: Channel;

    /**
     * start date from for the given content and channel
     */
    startDateFrom: DateTime;

    /**
     * start date to for the given content and channel
     */
    startDateTo: DateTime;

    /**
     * only full content will be returner
     */
    onlyFull?: boolean;

    /**
     * partial full relationship details are returned
     */
    showInterruptions?: boolean;
  }): Observable<Array<ExportShowSchedule>> {

    return this.exportShowScheduleList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ExportShowSchedule>>) => r.body as Array<ExportShowSchedule>)
    );
  }

  /**
   * Path part for operation getShowScheduleAnomalies
   */
  static readonly GetShowScheduleAnomaliesPath = '/matches/showschedule/anomalies';

  /**
   * Get show schedule anomalies.
   *
   * Export a list of show schedule anomalies
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getShowScheduleAnomalies()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShowScheduleAnomalies$Response(params: {

    /**
     * Editor
     */
    editor: Editor;

    /**
     * Channel
     */
    channel: Channel;

    /**
     * start date from for the given content and channel
     */
    startDateFrom: DateTime;

    /**
     * start date to for the given content and channel
     */
    startDateTo: DateTime;
  }): Observable<StrictHttpResponse<Array<ShowScheduleAnomaly>>> {

    const rb = new RequestBuilder(this.rootUrl, RetrieveService.GetShowScheduleAnomaliesPath, 'get');
    if (params) {
      rb.query('editor', params.editor, {});
      rb.query('channel', params.channel, {});
      rb.query('startDateFrom', params.startDateFrom, {});
      rb.query('startDateTo', params.startDateTo, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ShowScheduleAnomaly>>;
      })
    );
  }

  /**
   * Get show schedule anomalies.
   *
   * Export a list of show schedule anomalies
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getShowScheduleAnomalies$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShowScheduleAnomalies(params: {

    /**
     * Editor
     */
    editor: Editor;

    /**
     * Channel
     */
    channel: Channel;

    /**
     * start date from for the given content and channel
     */
    startDateFrom: DateTime;

    /**
     * start date to for the given content and channel
     */
    startDateTo: DateTime;
  }): Observable<Array<ShowScheduleAnomaly>> {

    return this.getShowScheduleAnomalies$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ShowScheduleAnomaly>>) => r.body as Array<ShowScheduleAnomaly>)
    );
  }

  /**
   * Path part for operation countShowSchedule
   */
  static readonly CountShowSchedulePath = '/count/matches/showschedule';

  /**
   * count show schedule matches.
   *
   * count matches in the system that made up show schedule
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countShowSchedule()` instead.
   *
   * This method doesn't expect any request body.
   */
  countShowSchedule$Response(params?: {

    /**
     * Editor
     */
    editor?: Editor;

    /**
     * Channel
     */
    channel?: Channel;

    /**
     * Amid
     */
    amid?: Amid;

    /**
     * start date from for the given content and channel
     */
    startDateFrom?: DateTime;

    /**
     * start date to for the given content and channel
     */
    startDateTo?: DateTime;

    /**
     * end date from for the given content and channel
     */
    endDateFrom?: DateTime;

    /**
     * end date to for the given content and channel
     */
    endDateTo?: DateTime;

    /**
     * title of the content partial match
     */
    title?: ContentTitle;

    /**
     * only full content will be returner
     */
    onlyFull?: boolean;
  }): Observable<StrictHttpResponse<Count>> {

    const rb = new RequestBuilder(this.rootUrl, RetrieveService.CountShowSchedulePath, 'get');
    if (params) {
      rb.query('editor', params.editor, {});
      rb.query('channel', params.channel, {});
      rb.query('amid', params.amid, {});
      rb.query('startDateFrom', params.startDateFrom, {});
      rb.query('startDateTo', params.startDateTo, {});
      rb.query('endDateFrom', params.endDateFrom, {});
      rb.query('endDateTo', params.endDateTo, {});
      rb.query('title', params.title, {});
      rb.query('onlyFull', params.onlyFull, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Count>;
      })
    );
  }

  /**
   * count show schedule matches.
   *
   * count matches in the system that made up show schedule
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `countShowSchedule$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  countShowSchedule(params?: {

    /**
     * Editor
     */
    editor?: Editor;

    /**
     * Channel
     */
    channel?: Channel;

    /**
     * Amid
     */
    amid?: Amid;

    /**
     * start date from for the given content and channel
     */
    startDateFrom?: DateTime;

    /**
     * start date to for the given content and channel
     */
    startDateTo?: DateTime;

    /**
     * end date from for the given content and channel
     */
    endDateFrom?: DateTime;

    /**
     * end date to for the given content and channel
     */
    endDateTo?: DateTime;

    /**
     * title of the content partial match
     */
    title?: ContentTitle;

    /**
     * only full content will be returner
     */
    onlyFull?: boolean;
  }): Observable<Count> {

    return this.countShowSchedule$Response(params).pipe(
      map((r: StrictHttpResponse<Count>) => r.body as Count)
    );
  }

  /**
   * Path part for operation getPartialFullList
   */
  static readonly GetPartialFullListPath = '/matches/partialfull';

  /**
   * List partial full matches.
   *
   * Retrieve a list of matches in the system that are related by a partial-full relationship. At most 25 records are returned for each call. Use the **offset** query parameter for pagination.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPartialFullList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartialFullList$Response(params?: {

    /**
     * Partial Content ID
     */
    id_partial?: Id;

    /**
     * Full Content ID
     */
    id_full?: Id;

    /**
     * Content editor
     */
    editor?: Editor;

    /**
     * published start interval of partial
     */
    publishedFromPartial?: DateTime;

    /**
     * published end interval of partial
     */
    publishedToPartial?: DateTime;

    /**
     * published start interval full
     */
    publishedFromFull?: DateTime;

    /**
     * published end interval full
     */
    publishedToFull?: DateTime;

    /**
     * inserted start interval partial
     */
    insertedFromPartial?: DateTime;

    /**
     * inserted end interval partial
     */
    insertedToPartial?: DateTime;

    /**
     * inserted start interval full
     */
    insertedFromFull?: DateTime;

    /**
     * inserted end interval full
     */
    insertedToFull?: DateTime;

    /**
     * Fingerprint version (required if audiofeat or audiomatch filters are used)
     */
    version?: FingerprintVersion;

    /**
     * threshold for similarity between partial and full description
     */
    similarityThreshold?: number;

    /**
     * if true all partial full are returned without limit
     */
    forExporting?: boolean;

    /**
     * Attribute to sort
     */
    sort?: 'published_partial' | 'published_full' | 'inserted_partial' | 'inserted_full' | 'audiomatch_date';

    /**
     * Sorting order
     */
    order?: 'asc' | 'desc';

    /**
     * List offset for pagination
     */
    offset?: number;

    /**
     * Limit the number of retrieved contents
     */
    limit?: number;
  }): Observable<StrictHttpResponse<Array<PartialFull>>> {

    const rb = new RequestBuilder(this.rootUrl, RetrieveService.GetPartialFullListPath, 'get');
    if (params) {
      rb.query('id_partial', params.id_partial, {});
      rb.query('id_full', params.id_full, {});
      rb.query('editor', params.editor, {});
      rb.query('publishedFromPartial', params.publishedFromPartial, {});
      rb.query('publishedToPartial', params.publishedToPartial, {});
      rb.query('publishedFromFull', params.publishedFromFull, {});
      rb.query('publishedToFull', params.publishedToFull, {});
      rb.query('insertedFromPartial', params.insertedFromPartial, {});
      rb.query('insertedToPartial', params.insertedToPartial, {});
      rb.query('insertedFromFull', params.insertedFromFull, {});
      rb.query('insertedToFull', params.insertedToFull, {});
      rb.query('version', params.version, {});
      rb.query('similarityThreshold', params.similarityThreshold, {});
      rb.query('forExporting', params.forExporting, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
      rb.query('offset', params.offset, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PartialFull>>;
      })
    );
  }

  /**
   * List partial full matches.
   *
   * Retrieve a list of matches in the system that are related by a partial-full relationship. At most 25 records are returned for each call. Use the **offset** query parameter for pagination.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPartialFullList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartialFullList(params?: {

    /**
     * Partial Content ID
     */
    id_partial?: Id;

    /**
     * Full Content ID
     */
    id_full?: Id;

    /**
     * Content editor
     */
    editor?: Editor;

    /**
     * published start interval of partial
     */
    publishedFromPartial?: DateTime;

    /**
     * published end interval of partial
     */
    publishedToPartial?: DateTime;

    /**
     * published start interval full
     */
    publishedFromFull?: DateTime;

    /**
     * published end interval full
     */
    publishedToFull?: DateTime;

    /**
     * inserted start interval partial
     */
    insertedFromPartial?: DateTime;

    /**
     * inserted end interval partial
     */
    insertedToPartial?: DateTime;

    /**
     * inserted start interval full
     */
    insertedFromFull?: DateTime;

    /**
     * inserted end interval full
     */
    insertedToFull?: DateTime;

    /**
     * Fingerprint version (required if audiofeat or audiomatch filters are used)
     */
    version?: FingerprintVersion;

    /**
     * threshold for similarity between partial and full description
     */
    similarityThreshold?: number;

    /**
     * if true all partial full are returned without limit
     */
    forExporting?: boolean;

    /**
     * Attribute to sort
     */
    sort?: 'published_partial' | 'published_full' | 'inserted_partial' | 'inserted_full' | 'audiomatch_date';

    /**
     * Sorting order
     */
    order?: 'asc' | 'desc';

    /**
     * List offset for pagination
     */
    offset?: number;

    /**
     * Limit the number of retrieved contents
     */
    limit?: number;
  }): Observable<Array<PartialFull>> {

    return this.getPartialFullList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PartialFull>>) => r.body as Array<PartialFull>)
    );
  }

  /**
   * Path part for operation countPartialFull
   */
  static readonly CountPartialFullPath = '/count/matches/partialfull';

  /**
   * count partial full matches.
   *
   * count matches in the system that are related by a partial-full relationship
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countPartialFull()` instead.
   *
   * This method doesn't expect any request body.
   */
  countPartialFull$Response(params?: {

    /**
     * Partial Content ID
     */
    id_partial?: Id;

    /**
     * Full Content ID
     */
    id_full?: Id;

    /**
     * Content editor
     */
    editor?: Editor;

    /**
     * published start interval of partial
     */
    publishedFromPartial?: DateTime;

    /**
     * published end interval of partial
     */
    publishedToPartial?: DateTime;

    /**
     * published start interval full
     */
    publishedFromFull?: DateTime;

    /**
     * published end interval full
     */
    publishedToFull?: DateTime;

    /**
     * inserted start interval partial
     */
    insertedFromPartial?: DateTime;

    /**
     * inserted end interval partial
     */
    insertedToPartial?: DateTime;

    /**
     * inserted start interval full
     */
    insertedFromFull?: DateTime;

    /**
     * inserted end interval full
     */
    insertedToFull?: DateTime;

    /**
     * Fingerprint version (required if audiofeat or audiomatch filters are used)
     */
    version?: FingerprintVersion;

    /**
     * threshold for similarity between partial and full description
     */
    similarityThreshold?: number;
  }): Observable<StrictHttpResponse<Count>> {

    const rb = new RequestBuilder(this.rootUrl, RetrieveService.CountPartialFullPath, 'get');
    if (params) {
      rb.query('id_partial', params.id_partial, {});
      rb.query('id_full', params.id_full, {});
      rb.query('editor', params.editor, {});
      rb.query('publishedFromPartial', params.publishedFromPartial, {});
      rb.query('publishedToPartial', params.publishedToPartial, {});
      rb.query('publishedFromFull', params.publishedFromFull, {});
      rb.query('publishedToFull', params.publishedToFull, {});
      rb.query('insertedFromPartial', params.insertedFromPartial, {});
      rb.query('insertedToPartial', params.insertedToPartial, {});
      rb.query('insertedFromFull', params.insertedFromFull, {});
      rb.query('insertedToFull', params.insertedToFull, {});
      rb.query('version', params.version, {});
      rb.query('similarityThreshold', params.similarityThreshold, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Count>;
      })
    );
  }

  /**
   * count partial full matches.
   *
   * count matches in the system that are related by a partial-full relationship
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `countPartialFull$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  countPartialFull(params?: {

    /**
     * Partial Content ID
     */
    id_partial?: Id;

    /**
     * Full Content ID
     */
    id_full?: Id;

    /**
     * Content editor
     */
    editor?: Editor;

    /**
     * published start interval of partial
     */
    publishedFromPartial?: DateTime;

    /**
     * published end interval of partial
     */
    publishedToPartial?: DateTime;

    /**
     * published start interval full
     */
    publishedFromFull?: DateTime;

    /**
     * published end interval full
     */
    publishedToFull?: DateTime;

    /**
     * inserted start interval partial
     */
    insertedFromPartial?: DateTime;

    /**
     * inserted end interval partial
     */
    insertedToPartial?: DateTime;

    /**
     * inserted start interval full
     */
    insertedFromFull?: DateTime;

    /**
     * inserted end interval full
     */
    insertedToFull?: DateTime;

    /**
     * Fingerprint version (required if audiofeat or audiomatch filters are used)
     */
    version?: FingerprintVersion;

    /**
     * threshold for similarity between partial and full description
     */
    similarityThreshold?: number;
  }): Observable<Count> {

    return this.countPartialFull$Response(params).pipe(
      map((r: StrictHttpResponse<Count>) => r.body as Count)
    );
  }

  /**
   * Path part for operation getMetrics
   */
  static readonly GetMetricsPath = '/metrics';

  /**
   * Retrive metric by name.
   *
   * Retrieve the given metric
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMetrics()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMetrics$Response(params: {

    /**
     * Name of the metric
     */
    name: Metrics;

    /**
     * Content editor
     */
    editor?: Editor;

    /**
     * Editor filter for download, audiofeat, audiomatch status
     */
    editor_status?: Editor;

    /**
     * Channel brand description filter for download, audiofeat, audiomatch status
     */
    channelbranddescription_status?: Editor;

    /**
     * start date
     */
    dateFrom?: DateTime;

    /**
     * end date
     */
    dateTo?: DateTime;

    /**
     * Fingerprint version
     */
    version?: FingerprintVersion;
  }): Observable<StrictHttpResponse<Array<NamedSeries>>> {

    const rb = new RequestBuilder(this.rootUrl, RetrieveService.GetMetricsPath, 'get');
    if (params) {
      rb.query('name', params.name, {});
      rb.query('editor', params.editor, {});
      rb.query('editor_status', params.editor_status, {});
      rb.query('channelbranddescription_status', params.channelbranddescription_status, {});
      rb.query('dateFrom', params.dateFrom, {});
      rb.query('dateTo', params.dateTo, {});
      rb.query('version', params.version, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<NamedSeries>>;
      })
    );
  }

  /**
   * Retrive metric by name.
   *
   * Retrieve the given metric
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMetrics$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMetrics(params: {

    /**
     * Name of the metric
     */
    name: Metrics;

    /**
     * Content editor
     */
    editor?: Editor;

    /**
     * Editor filter for download, audiofeat, audiomatch status
     */
    editor_status?: Editor;

    /**
     * Channel brand description filter for download, audiofeat, audiomatch status
     */
    channelbranddescription_status?: Editor;

    /**
     * start date
     */
    dateFrom?: DateTime;

    /**
     * end date
     */
    dateTo?: DateTime;

    /**
     * Fingerprint version
     */
    version?: FingerprintVersion;
  }): Observable<Array<NamedSeries>> {

    return this.getMetrics$Response(params).pipe(
      map((r: StrictHttpResponse<Array<NamedSeries>>) => r.body as Array<NamedSeries>)
    );
  }

}
