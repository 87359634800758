import { Component, OnInit } from '@angular/core';

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
    { path: '/metrics', title: 'Metrics',  icon: 'dashboard', class: '' },
    { path: '/audiomatchchannels', title: 'Linear Channels',  icon:'live_tv', class: '' },
    { path: '/monitoredchannels', title: 'Monitored Channels',  icon:'settings_input_antenna', class: '' },
    { path: '/contents', title: 'Ondemand Contents',  icon:'content_paste', class: '' },
    { path: '/advs', title: 'Advertisements',  icon:'list_alt', class: '' },
    { path: '/audiomatchrunsbydate', title: 'Audiomatch Runs',  icon:'compare', class: '' },
    { path: '/channels', title: 'Linear Contents',  icon:'tv', class: '' },
    { path: '/onpremiseservers', title: 'On-premise Servers',  icon:'dns', class: '' },
    { path: '/partialfull', title: 'Partial Full Contents',  icon:'people_outline', class: '' },
    { path: '/showschedule', title: 'Show Schedule',  icon:'calendar_today', class: '' },
    //{ path: '/metrics', title: 'Audiomatch Metrics',  icon:'bar_chart', class: '' },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor() { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };
}
