import { Component, ChangeDetectionStrategy, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { CalendarEvent, CalendarEventTitleFormatter, CalendarView, CalendarWeekViewBeforeRenderEvent } from 'angular-calendar';
import { Observable, of, zip } from 'rxjs';
import { RetrieveService } from '../api/services/retrieve.service';
import moment from 'moment';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { CustomEventTitleFormatter } from './custom-event-title-formatter.provider';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { bool } from 'aws-sdk/clients/signer';
import { ShowSchedule } from '../api/models/show-schedule';

import domtoimage from 'dom-to-image-improved';
import { ChannelBundle } from '../api/models/channel-bundle';

interface Show {
  title: string;
  start_date: string;
  end_date: string;
}

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

@Component({
  selector: 'app-view-showschedule',
  templateUrl: './view-showschedule.component.html',
  styleUrls: ['./view-showschedule.component.css'],
  providers: [
    {
      provide: CalendarEventTitleFormatter,
      useClass: CustomEventTitleFormatter,
    },
  ],
  styles: [
    `
      .my-custom-class span {
        color: #ff3d7f !important;
        border-color: $primary;
      }
    `,
  ],
})
export class ViewShowscheduleComponent implements OnInit, AfterViewInit {

  view: CalendarView = CalendarView.Week;

  viewDate: Date = new Date();

  events$: Observable<CalendarEvent[]>;

  activeDayIsOpen: boolean = false;

  hourSegments = "12";
  showInterruption: bool = false;
  onlyFull: bool;
  showAnomalies: bool;
  startDateFrom = new FormControl();
  startDateTo = new FormControl();
  hourFrom = new FormControl();
  hourTo = new FormControl();
  minuteFrom = new FormControl();
  minuteTo = new FormControl();
  channel = new FormControl();
  daysInWeek = 7;
  dayStartHour = 0;
  dayEndHour = 23;
  dayStartMinute = 0;
  dayEndMinute = 59;

  downloadLoading: boolean = false;

  channels: Array<ChannelBundle> = []
  channelsReady: boolean = false;

  @ViewChild('screen') screen: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;
  @ViewChild('spinner') spinner: ElementRef;

  constructor(
    private retrieve: RetrieveService,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.hourSegments = "12";
    this.showInterruption = false;
    this.onlyFull = true;
    this.showAnomalies = false;
    this.hourTo.setValue(23);
    this.minuteFrom.setValue(0);
    this.minuteTo.setValue(59);


    /* Read query parameters from URL */
    this.route.queryParams.subscribe(params => {
      this.startDateFrom.setValue(params['startDate'] ? moment(params['startDate']).toDate() : new Date(), { emitEvent: false });
      this.startDateTo.setValue(params['endDate'] ? moment(params['endDate']).toDate() : new Date(), { emitEvent: false });

      if (this.startDateTo.value <= this.startDateFrom.value) {
        this.startDateTo.setValue(moment(this.startDateFrom.value).add(1, 'days').toDate())
      }

      this.hourFrom.setValue(params['hourFrom'] ? Math.min(Math.max(params['hourFrom'], 0), 23) : 0, { emitEvent: false });
      this.dayStartHour = this.hourFrom.value;
      this.hourTo.setValue(params['hourTo'] ? Math.min(Math.max(params['hourTo'], 0), 23) : 23, { emitEvent: false });
      this.dayEndHour = this.hourTo.value;
      this.minuteFrom.setValue(params['minuteFrom'] ? Math.min(Math.max(params['minuteFrom'], 0), 59) : 0, { emitEvent: false });
      this.dayStartMinute = this.minuteFrom.value;
      this.minuteTo.setValue(params['minuteTo'] ? Math.min(Math.max(params['minuteTo'], 0), 59) : 59, { emitEvent: false });
      this.dayEndMinute = this.minuteTo.value;

      if (this.hourTo.value < this.hourFrom.value) {
        this.hourTo.setValue(23);
        this.minuteTo.setValue(59);
      }

      this.viewDate = this.startDateFrom.value;
      const diffTime = Math.abs(this.startDateTo.value - this.startDateFrom.value);
      this.daysInWeek = Math.max(Math.floor(diffTime / (1000 * 60 * 60 * 24)) + 1, 1);
      this.channel.setValue(params['channel'] ? params['channel'] : null, { emitEvent: false });

      this.channelsReady = false;
      this.retrieve.getShowScheduleChannel().subscribe(
        (channels) => {
          this.channels = channels;
          this.channelsReady = true;
        }
      );
      if (this.channel.value) {
        this.fetchEvents();
      }
    });
  }

  ngAfterViewInit(): void {
    /* Dates */
    this.startDateFrom.valueChanges.pipe(
    ).subscribe(value => {
      this.router.navigate(['viewshowschedule'], {
        queryParams: {
          startDate: moment(value).set({ minute: 0, hour: 0, second: 0, millisecond: 0 }),
        },
        queryParamsHandling: 'merge'
      });
    });

    this.startDateTo.valueChanges.pipe(
    ).subscribe(value => {
      this.router.navigate(['viewshowschedule'], {
        queryParams: {
          endDate: moment(value).set({ minute: 59, hour: 23, second: 59, millisecond: 0 }),
        },
        queryParamsHandling: 'merge'
      });
    });

    this.hourFrom.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['viewshowschedule'], {
        queryParams: {
          hourFrom: value,
        },
        queryParamsHandling: 'merge'
      });
    });

    this.hourTo.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['viewshowschedule'], {
        queryParams: {
          hourTo: value,
        },
        queryParamsHandling: 'merge'
      });
    });

    this.minuteFrom.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['viewshowschedule'], {
        queryParams: {
          minuteFrom: value,
        },
        queryParamsHandling: 'merge'
      });
    });

    this.minuteTo.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['viewshowschedule'], {
        queryParams: {
          minuteTo: value,
        },
        queryParamsHandling: 'merge'
      });
    });

    this.channel.valueChanges.pipe(
    ).subscribe(value => {
      this.router.navigate(['viewshowschedule'], {
        queryParams: {
          channel: value,
        },
        queryParamsHandling: 'merge'
      });
    });
  }

  searchEvent(showSchedules: Array<ShowSchedule>, searchAmid: string, startDate, endDate): number {
    let index = showSchedules.findIndex(({ amid, start_date, end_date }) => amid == searchAmid && moment(start_date) >= startDate && moment(end_date) <= endDate)
    return index
  }

  fetchEvents(): void {
    let currentDate = moment(this.viewDate);
    //this.startDateFrom.setValue(moment(this.viewDate));
    //let weekStart = currentDate.clone().startOf('week').startOf('day').subtract(6, 'hours');
    //let weekEnd = currentDate.clone().endOf('week').endOf('day');

    var offset = new Date().getTimezoneOffset();
    offset = -1 * offset;

    let weekStart = moment(this.startDateFrom.value).set({ minute: 0, hour: 0, second: 0, millisecond: 0 });
    let weekEnd = moment(this.startDateTo.value).set({ minute: 59, hour: 23, second: 59, millisecond: 0 });

    if (!this.showAnomalies) {
      this.events$ = this.retrieve.getShowScheduleList({
        channel: this.channel.value,
        startDateFrom: weekStart.toISOString(),
        startDateTo: weekEnd.toISOString(),
        onlyFull: false,
        partialfull_details: true,
        forExporting: true
      }).pipe(
        map((results) => {
          let shows: Array<CalendarEvent> = [];
          let addedPartialAmids = [];
          let addedAmids = [];

          let addedIndex = []
          results.forEach(
            (result: ShowSchedule, fullIndex: number) => {

              var letters = 'BCDEF'.split('');
              var randColor = '#';
              for (var i = 0; i < 6; i++) {
                randColor += letters[Math.floor(Math.random() * letters.length)];
              }

              //let randColor = Math.floor(Math.random() * 16777215).toString(16);
              let randomColor = {
                primary: randColor,
                secondary: randColor
              };

              if (this.onlyFull) {
                if (!(result.is_partial == "TRUE")) {
                  if (!addedIndex.includes(fullIndex)) {
                    shows.push(...this.computeEventsToAdd(result, randomColor, false, fullIndex, null, result.twins.length > 0));
                    addedIndex.push(fullIndex)
                    if (result.twins.length > 0) {
                      result.twins.forEach((twin) => {
                        const twinIndex = this.searchEvent(results, twin, moment(result.start_date).subtract(5, 'minutes'), moment(result.end_date).add(5, 'minutes'));
                        if (twinIndex >= 0 && !addedIndex.includes(twinIndex)) {
                          addedIndex.push(twinIndex)
                        }
                      })
                    }
                  }
                }
              } else {
                if (!(result.is_partial == "TRUE")) {
                  if (!addedIndex.includes(fullIndex)) {
                    shows.push(...this.computeEventsToAdd(result, randomColor, false, fullIndex, null, result.twins.length > 0))
                    addedIndex.push(fullIndex);
                    if (result.twins.length > 0) {
                      result.twins.forEach((twin) => {
                        const twinIndex = this.searchEvent(results, twin, moment(result.start_date).subtract(5, 'minutes'), moment(result.end_date).add(5, 'minutes'));
                        if (twinIndex >= 0 && !addedIndex.includes(twinIndex)) {
                          addedIndex.push(twinIndex)
                        }
                      })
                    }
                    if (result.partials) {
                      result.partials.forEach((partial) => {
                        const partialIndex = this.searchEvent(results, partial, moment(result.start_date).subtract(5, 'minutes'), moment(result.end_date).add(5, 'minutes'));

                        if (partialIndex >= 0 && !addedIndex.includes(partialIndex)) {
                          const partialEvent = results[partialIndex];
                          shows.push(...this.computeEventsToAdd(partialEvent, randomColor, true, partialIndex, null, partialEvent.twins.length > 0));
                          addedIndex.push(partialIndex);

                          if (partialEvent.twins.length > 0) {
                            partialEvent.twins.forEach((twin) => {
                              const twinIndex = this.searchEvent(results, twin, moment(partialEvent.start_date).subtract(5, 'minutes'), moment(partialEvent.end_date).add(5, 'minutes'));
                              if (twinIndex >= 0 && !addedIndex.includes(twinIndex)) {
                                addedIndex.push(twinIndex)
                              }
                            })
                          }
                        }
                      });
                    }
                  }
                }
              }
            }
          )

          if (!this.onlyFull) {
            const missingIndexes = Array.from(Array(results.length).keys()).filter(x => !addedIndex.includes(x));
            const redColor = {
              primary: '#ff3232',
              secondary: '#ff3232'
            };
            missingIndexes.forEach((missingIndex) => {
              if (!addedIndex.includes(missingIndex)) {
                const missingEvent = results[missingIndex];
                shows.push(...this.computeEventsToAdd(missingEvent, redColor, true, missingIndex, null, missingEvent.twins.length > 0));
                addedIndex.push(missingIndex);
                if (missingEvent.twins.length > 0) {
                  missingEvent.twins.forEach((twin) => {
                    const twinIndex = this.searchEvent(results, twin, moment(missingEvent.start_date).subtract(5, 'minutes'), moment(missingEvent.end_date).add(5, 'minutes'));
                    if (twinIndex >= 0 && !addedIndex.includes(twinIndex)) {
                      addedIndex.push(twinIndex)
                    }
                  })
                }

              }
            }
            )
          }
          return shows
        }
        )
      )
    } else {
      let editor = (this.channels.find(({ channel }) => channel === this.channel.value)).editor;
      this.events$ = this.retrieve.getShowScheduleAnomalies({
        channel: this.channel.value,
        startDateFrom: weekStart.toISOString(),
        startDateTo: weekEnd.toISOString(),
        editor: editor
      }).pipe(
        map((results) => {
          let shows: Array<CalendarEvent> = [];

          results.forEach(
            (anomalyType) => {

              let blueRandomColors = ['#482aa3', '#0c8bf9', '#3170b2', '#0e4b91', '#2575aa', '#106a6d', '#5e84c1', '#90cce5', '#2f828e']
              let redRandomColors = ['#ff7a8e', '#f9959e', '#ef437f', '#bf394f', '#dd6877', '#bf0d1f', '#e8333c', '#e8a99b', '#f99795']

              let showIdx = 0
              anomalyType.showschedules.forEach(
                (showschedules) => {
                  let randIdx = Math.floor(Math.random() * 10);
                  let randColor = '#FF0000'
                  if (anomalyType.type.includes('partial')) {
                    randColor = blueRandomColors[randIdx]
                  } else {
                    randColor = redRandomColors[randIdx]
                  }
                  let randomColor = {
                    primary: randColor,
                    secondary: randColor
                  };
                  showschedules.forEach(
                    (showschedule) => {
                      shows.push(...this.computeEventsToAdd(showschedule, randomColor, showschedule.is_partial == 'TRUE', showIdx, null, false));
                      showIdx++;
                    }
                  )
                }
              )
            }
          )
          return shows
        }
        ))
    }
  }

  getEventTitlePrefixString(start, end) {
    return '[' + start.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' }) + ' - ' + end.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' }) + '] '
  }

  computeEventsToAdd(showschedule: ShowSchedule, color, isPartial, id, customCss = null, isTwin = false): Array<CalendarEvent> {
    let shows: Array<CalendarEvent> = [];

    var showscheduleDateFrom = moment(showschedule.start_date);
    // Your moment at midnight
    var mmtMidnight = showscheduleDateFrom.clone().startOf('day');
    // Difference in minutes
    var diffMinutes = showscheduleDateFrom.diff(mmtMidnight, 'minutes');

    if (!(diffMinutes >= this.minuteFrom.value + this.hourFrom.value * 60) || !(diffMinutes <= this.minuteTo.value + this.hourTo.value * 60)) {
      return shows
    }

    let computeInterruptions: bool = false;
    if (this.showInterruption) {
      if ('interruptions' in showschedule.meta) {
        let interruptions: Array<any> = showschedule.meta['interruptions']
        if (interruptions.length > 0) {
          computeInterruptions = true
        }
      }
    }

    if (computeInterruptions) {
      var offset = new Date().getTimezoneOffset();
      offset = -1 * offset;

      let interruptions = showschedule.meta['interruptions'] as Array<any>;
      interruptions.sort(function (a, b) {
        var keyA = moment(a.start);
        var keyB = moment(b.start);
        // Compare the 2 dates
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });

      let start = moment(showschedule.start_date).toDate();
      let end = moment(interruptions[0].start).add(offset, 'minutes').toDate();
      let title = isTwin ? '* ' + this.getEventTitlePrefixString(start, end) + showschedule.title + ' (twin)' : this.getEventTitlePrefixString(start, end) + showschedule.title;
      title += isPartial ? ' (partial)' : ' (full)'
      let ce: CalendarEvent = {
        title: title,
        start: start,
        end: end,
        draggable: false,
        meta: {
          'amid': showschedule.amid,
          'isPartial': isPartial,
          'id': id
        },
        color: color
      };
      if (customCss) {
        ce['cssClass'] = customCss;
      }
      shows.push(ce)

      for (let i = 0; i < interruptions.length - 1; i++) {
        let start = moment(interruptions[i].end).add(offset, 'minutes').toDate();
        let end = moment(interruptions[i + 1].start).add(offset, 'minutes').toDate();
        title = isTwin ? '* ' + this.getEventTitlePrefixString(start, end) + showschedule.title + ' (twin)' : this.getEventTitlePrefixString(start, end) + showschedule.title;
        title += isPartial ? ' (partial)' : ' (full)'
    
        let ce: CalendarEvent = {
          title: title,
          start: start,
          end: end,
          draggable: false,
          meta: {
            'amid': showschedule.amid,
            'isPartial': isPartial,
            'id': id
          },
          color: color
        };
        if (customCss) {
          ce['cssClass'] = customCss;
        }
        shows.push(ce)
      }
      start = moment(interruptions[interruptions.length - 1].end).add(offset, 'minutes').toDate();
      end = moment(showschedule.end_date).toDate();
      title = isTwin ? '* ' + this.getEventTitlePrefixString(start, end) + showschedule.title + ' (twin)' : this.getEventTitlePrefixString(start, end) + showschedule.title;
      title += isPartial ? ' (partial)' : ' (full)'
      ce = {
        title: title,
        start: start,
        end: end,
        draggable: false,
        meta: {
          'amid': showschedule.amid,
          'isPartial': isPartial,
          'id': id
        },
        color: color
      };
      if (customCss) {
        ce['cssClass'] = customCss;
      }
      shows.push(ce)
    } else {
      let start = moment(showschedule.start_date).toDate();
      let end = moment(showschedule.end_date).toDate();
      let title = isTwin ? '* ' + this.getEventTitlePrefixString(start, end) + showschedule.title + ' (twin)' : this.getEventTitlePrefixString(start, end) + showschedule.title;
      title += isPartial ? ' (partial)' : ' (full)'
      let ce: CalendarEvent = {
        title: title,
        start: start,
        end: end,
        draggable: false,
        meta: {
          'amid': showschedule.amid,
          'isPartial': isPartial,
          'id': id
        },
        color: color
      };
      if (customCss) {
        ce['cssClass'] = customCss;
      }
      shows.push(ce)
    }

    return shows
  }

  eventClicked(event: CalendarEvent): void {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/content/${event.meta.amid}`])
    );

    window.open(url, '_blank');
  }

  dateRangeOverlaps(a_start, a_end, b_start, b_end) {
    let latestStart = a_start;
    if (b_start.getTime() > a_start.getTime()){
      latestStart = b_start
    } 

    let earliestEnd = a_end;
    if (a_end.getTime() > b_end.getTime()){
      earliestEnd = b_end
    }

    return earliestEnd.getTime() > latestStart.getTime() ? earliestEnd.getTime() - latestStart.getTime() : -1
  }

  overlapInterval(a_start, a_end, b_start, b_end) {
    let latestStart = Math.max(a_start, b_start);
    let earliestEnd = Math.min(a_end, b_end);
    return earliestEnd > latestStart
  }

  beforeViewRender(view: CalendarWeekViewBeforeRenderEvent) {


    view.hourColumns.forEach(column => {
      let dayEventsPos = []
      column.events.forEach(event => {
        if (event.width == 100 && !event.event.meta.isPartial) {
          dayEventsPos.push(
            {
              'left': event.left,
              'width': event.width,
              'start': event.event.start,
              'end': event.event.end
            }
          )
        } else {

          console.log(event.event.title);
          let timeOverlappedEvents = []
          dayEventsPos.forEach((dayEvent) => {
            const timeOverlap = this.dateRangeOverlaps(dayEvent.start, dayEvent.end, event.event.start, event.event.end)
            console.log(timeOverlap);
            if (timeOverlap > 1*1000) {
              timeOverlappedEvents.push(dayEvent);
            }
          })

          let left = 0;
          let width = event.width;
          if (event.event.meta.isPartial) {
            if (timeOverlappedEvents.length > 0){
              left = timeOverlappedEvents[0].width;
              width = timeOverlappedEvents[0].width;
            }else{
              left = Math.min(event.width, 100 - event.width);
              if (left == 0){
                left = 50;
              }
              if (left == 100){
                width = 100/(100/width +1);
                left = width;
              } else if (left + width > 100){
                width = 100/(100/width + 1);
                left = width;
              }
          }
          }

          console.log(timeOverlappedEvents);

          let inserted = false;
          while (!inserted){
            console.log(left);
            inserted = true;
            for (let i = 0; i < timeOverlappedEvents.length; i++) {
              if (timeOverlappedEvents[i].left >=  left){
                if (this.overlapInterval(timeOverlappedEvents[i].left, timeOverlappedEvents[i].left + timeOverlappedEvents[i].width,
                  left, left+width)){
                    inserted = false;
                  }
              }
            }
            console.log(inserted);
            if (!inserted){
              left += width;
              if (left == 100){
                width = 100/(100/width +1);
                left = width;
              } else if (left + width > 100){
                width = 100/(100/width + 1);
                left = width;
              }
            }
          }

          dayEventsPos.push(
            {
              'left': left,
              'width': width,
              'start': event.event.start,
              'end': event.event.end
            }
          )

          event.left = left;
          event.width = width;

        }
      })
    })
  }

  downloadImage(format: string = 'jpeg') {
    let currentDate = moment(this.viewDate);
    let weekStart = currentDate.clone().startOf('week').startOf('day');
    let weekEnd = currentDate.clone().endOf('week').endOf('day');
    this.downloadLoading = true;

    let of = this.onlyFull ? '_onlyfull' : '';
    let interruptions = this.showInterruption ? '_interruption' : '';
    let hourSub = '_' + (Math.round(60 / parseFloat(this.hourSegments))).toString() + 'min';

    if (format == 'jpeg') {
      setTimeout(() => domtoimage.toJpeg(this.screen.nativeElement, { quality: 1.5 })
        .then(dataUrl => {
          this.downloadLink.nativeElement.href = dataUrl
          this.downloadLink.nativeElement.download = this.channel + '_' + weekStart.format('YYYY-MM-DD') + '_' + weekEnd.format('YYYY-MM-DD') + of + interruptions + hourSub + '.jpeg';
          this.downloadLink.nativeElement.click();
          this.downloadLoading = false;
        })
        .catch(error => {
          this.downloadLoading = false;
          console.error('oops, something went wrong!', error);
        }), 1000);
    } else {
      function filter(node) {
        return (node.tagName !== 'i');
      }
      setTimeout(() => domtoimage.toSvg(this.screen.nativeElement, { filter: filter })
        .then(dataUrl => {
          this.downloadLink.nativeElement.href = dataUrl
          this.downloadLink.nativeElement.download = this.channel + '_' + weekStart.format('YYYY-MM-DD') + '_' + weekEnd.format('YYYY-MM-DD') + of + interruptions + hourSub + '.svg';
          this.downloadLink.nativeElement.click();
          this.downloadLoading = false;
        })
        .catch(error => {
          this.downloadLoading = false;
          console.error('oops, something went wrong!', error);
        }), 1000);
    }
  }

  detectBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  previousView() {
    this.router.navigate(['viewshowschedule'], {
      queryParams: {
        startDate: moment(this.startDateFrom.value).subtract(this.daysInWeek, 'days').set({ minute: 0, hour: 0, second: 0, millisecond: 0 }),
        endDate: moment(this.startDateTo.value).subtract(this.daysInWeek, 'days').set({ minute: 59, hour: 23, second: 59, millisecond: 0 }),
      },
      queryParamsHandling: 'merge'
    });
  }

  nextView() {
    this.router.navigate(['viewshowschedule'], {
      queryParams: {
        startDate: moment(this.startDateFrom.value).add(this.daysInWeek, 'days').set({ minute: 0, hour: 0, second: 0, millisecond: 0 }),
        endDate: moment(this.startDateTo.value).add(this.daysInWeek, 'days').set({ minute: 59, hour: 23, second: 59, millisecond: 0 }),
      },
      queryParamsHandling: 'merge'
    });
  }

  todayView() {
    this.router.navigate(['viewshowschedule'], {
      queryParams: {
        startDate: moment().clone().startOf('week').startOf('day'),
        endDate: moment().clone().endOf('week').endOf('day')
      },
      queryParamsHandling: 'merge'
    });
  }

  resetView() {
    this.router.navigate(['viewshowschedule'], {
      queryParams: {
        startDate: moment().clone().startOf('week').startOf('day'),
        endDate: moment().clone().endOf('week').endOf('day'),
        hourFrom: 0,
        minuteFrom: 0,
        hourTo: 23,
        minuteTo: 59
      },
      queryParamsHandling: 'merge'
    });
  }

  downloadCSV() {
    let editor = (this.channels.find(({ channel }) => channel === this.channel.value)).editor;
    let startDate = moment(this.startDateFrom.value)
    let endDate = moment(this.startDateTo.value)

    startDate.set({ hour: this.hourFrom.value, minute: this.minuteFrom.value, second: 0, millisecond: 0 })
    endDate.set({ hour: this.hourTo.value, minute: this.minuteTo.value, second: 0, millisecond: 0 })

    let fileName = this.channel.value + '_' + startDate.format('YYYY_MM_DD');
    if (startDate.toDate().getDate() != endDate.toDate().getDate()) {
      fileName += '_' + endDate.format('YYYY_MM_DD');
    }
    if (!(this.hourFrom.value == 0 && this.minuteFrom.value == 0 &&
      this.hourTo.value == 23 && this.minuteTo.value == 59)) {
      fileName += ' - ' + String(this.hourFrom.value).padStart(2, '0') + ':' + String(this.minuteFrom.value).padStart(2, '0')
        + '_' + String(this.hourTo.value).padStart(2, '0') + ':' + String(this.minuteTo.value).padStart(2, '0');
    }
    fileName += '_showschedule'

    this.retrieve.exportShowScheduleList(
      {
        channel: this.channel.value,
        startDateFrom: startDate.toISOString(),
        startDateTo: endDate.toISOString(),
        onlyFull: this.onlyFull,
        showInterruptions: this.showInterruption,
        editor: editor
      }
    ).subscribe(
      (data) => {
        this.downloadFile(data, 'csv', fileName);
      }
    )
  }

  downloadFile(items, fileType: string, filename = 'data') {

    let blob

    let csv = []
    let csvString

    const header = ['Date', 'Channel', 'Start time', 'End time', 'Title', 'Duration', 'Type'];
    csv = [header.join(';')] // header row first]

    let currentDate = new Date(items[0].start_date)
    items.forEach((item: any) => {
      let date = new Date(item.start_date).toLocaleDateString("it-IT");
      let startTime = moment(item.start_date).toISOString(true).substring(11, 19);
      let endTime = moment(item.end_date).toISOString(true).substring(11, 19);
      let duration = new Date(item.duration * 1000).toISOString().substring(11, 19);

      let tmpDate = new Date(item.start_date)
      if (tmpDate.getDate() !== currentDate.getDate()) {
        csv.push(['', '', '', '', '', '', ''].join(';'));
        currentDate = tmpDate;
      }
      csv.push([date,
        this.channel.value,
        startTime, endTime, item.title, duration, item.type].join(';'))
    });
    csvString = csv.join('\r\n')
    blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + "." + fileType);
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }
}
