import { Injectable, OnInit } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { tap, switchMap, catchError } from 'rxjs/operators';

import { Auth } from 'aws-amplify'

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return from(Auth.currentSession())
      .pipe(
        switchMap((auth: any) => { // switchMap() is used instead of map().

          let jwt = auth.getAccessToken().getJwtToken();
          let with_auth_request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${jwt}`
            }
          });
          return next.handle(with_auth_request);
        })
      );

  }
}
