import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  transform(value: number, format?: string): string {
    
    if (value == undefined){
      return '';
    }

    const hours = Math.floor(value / 3600);
    const minutes = Math.floor(value / 60) - hours * 60;
    const seconds = Math.floor(value) - hours * 3600 - minutes * 60;

    if (format == undefined) {
      if (hours > 0){
        format = 'hh:mm:ss';
      }else{
        format = 'mm:ss';
      }
    }

    let out = '';

    format.split(':').forEach(fmt => {
      if (out.length) {
        out += ':';
      }
      switch (fmt) {
        case 'hh':
          out += hours;
          break;
        case 'hh':
          if (hours < 10) {
            out += '0' + hours;
          } else {
            out += hours;
          }
          break;
        case 'm':
          out += minutes;
          break;
        case 'mm':
          if (minutes < 10) {
            out += '0' + minutes;
          } else {
            out += minutes;
          }
          break;
        case 's':
          out += seconds;
          break;
        case 'ss':
          if (seconds < 10) {
            out += '0' + seconds;
          } else {
            out += seconds;
          }
          break;
      }
    });

    return out;
  }

}
