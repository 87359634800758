/* tslint:disable */
/* eslint-disable */

/**
 * Last status of the onpremise server
 */
export enum OnpremiseServerStatus {
  None = 'none',
  Running = 'running',
  Error = 'error'
}
