/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { NewAdvContent } from '../models/new-adv-content';
import { NewAudiomatchChannel } from '../models/new-audiomatch-channel';
import { NewContentId } from '../models/new-content-id';
import { NewLinearContent } from '../models/new-linear-content';
import { NewMonitoredChannel } from '../models/new-monitored-channel';
import { NewOndemandContent } from '../models/new-ondemand-content';
import { NewPartialFull } from '../models/new-partial-full';
import { NewShowSchedule } from '../models/new-show-schedule';
import { OnpremiseServer } from '../models/onpremise-server';
import { OnpremiseServerId } from '../models/onpremise-server-id';


/**
 * Insert new contents
 */
@Injectable({
  providedIn: 'root',
})
export class InsertService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation addLinearContent
   */
  static readonly AddLinearContentPath = '/content/linear';

  /**
   * Add a new linear content.
   *
   * Insert a new linear content in the database
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addLinearContent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addLinearContent$Response(params: {
    body: NewLinearContent
  }): Observable<StrictHttpResponse<NewContentId>> {

    const rb = new RequestBuilder(this.rootUrl, InsertService.AddLinearContentPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NewContentId>;
      })
    );
  }

  /**
   * Add a new linear content.
   *
   * Insert a new linear content in the database
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addLinearContent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addLinearContent(params: {
    body: NewLinearContent
  }): Observable<NewContentId> {

    return this.addLinearContent$Response(params).pipe(
      map((r: StrictHttpResponse<NewContentId>) => r.body as NewContentId)
    );
  }

  /**
   * Path part for operation addOndemandContent
   */
  static readonly AddOndemandContentPath = '/content/ondemand';

  /**
   * Add a new ondemand content.
   *
   * Insert a new ondemand content in the database
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addOndemandContent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addOndemandContent$Response(params: {

    /**
     * If the content exist it updates its meta data, publication date, insertion date
     */
    updateIfExist?: boolean;
    body: NewOndemandContent
  }): Observable<StrictHttpResponse<NewContentId>> {

    const rb = new RequestBuilder(this.rootUrl, InsertService.AddOndemandContentPath, 'post');
    if (params) {
      rb.query('updateIfExist', params.updateIfExist, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NewContentId>;
      })
    );
  }

  /**
   * Add a new ondemand content.
   *
   * Insert a new ondemand content in the database
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addOndemandContent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addOndemandContent(params: {

    /**
     * If the content exist it updates its meta data, publication date, insertion date
     */
    updateIfExist?: boolean;
    body: NewOndemandContent
  }): Observable<NewContentId> {

    return this.addOndemandContent$Response(params).pipe(
      map((r: StrictHttpResponse<NewContentId>) => r.body as NewContentId)
    );
  }

  /**
   * Path part for operation addAdvContent
   */
  static readonly AddAdvContentPath = '/content/adv';

  /**
   * Add a new adv content.
   *
   * Insert a new advertisement content in the database
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addAdvContent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addAdvContent$Response(params: {
    body: NewAdvContent
  }): Observable<StrictHttpResponse<NewContentId>> {

    const rb = new RequestBuilder(this.rootUrl, InsertService.AddAdvContentPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NewContentId>;
      })
    );
  }

  /**
   * Add a new adv content.
   *
   * Insert a new advertisement content in the database
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addAdvContent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addAdvContent(params: {
    body: NewAdvContent
  }): Observable<NewContentId> {

    return this.addAdvContent$Response(params).pipe(
      map((r: StrictHttpResponse<NewContentId>) => r.body as NewContentId)
    );
  }

  /**
   * Path part for operation notifyOnpremiseServer
   */
  static readonly NotifyOnpremiseServerPath = '/onpremiseserver/notify';

  /**
   * Notify onpremise server.
   *
   * Allows onpremise server to notify their existance to the system or to update their status
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notifyOnpremiseServer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notifyOnpremiseServer$Response(params: {
    body: OnpremiseServer
  }): Observable<StrictHttpResponse<OnpremiseServerId>> {

    const rb = new RequestBuilder(this.rootUrl, InsertService.NotifyOnpremiseServerPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OnpremiseServerId>;
      })
    );
  }

  /**
   * Notify onpremise server.
   *
   * Allows onpremise server to notify their existance to the system or to update their status
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `notifyOnpremiseServer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notifyOnpremiseServer(params: {
    body: OnpremiseServer
  }): Observable<OnpremiseServerId> {

    return this.notifyOnpremiseServer$Response(params).pipe(
      map((r: StrictHttpResponse<OnpremiseServerId>) => r.body as OnpremiseServerId)
    );
  }

  /**
   * Path part for operation postOnpremiseServerMetrics
   */
  static readonly PostOnpremiseServerMetricsPath = '/onpremiseserver/{id}/metrics';

  /**
   * Post onpremise server metrics.
   *
   * Allows onpremise server to post new metrics
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postOnpremiseServerMetrics()` instead.
   *
   * This method doesn't expect any request body.
   */
  postOnpremiseServerMetrics$Response(params: {

    /**
     * OnpremiseServer ID
     */
    id: OnpremiseServerId;

    /**
     * json dumped string of collected metrics
     */
    list_of_metrics: string;
  }): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, InsertService.PostOnpremiseServerMetricsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('list_of_metrics', params.list_of_metrics, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Post onpremise server metrics.
   *
   * Allows onpremise server to post new metrics
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postOnpremiseServerMetrics$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postOnpremiseServerMetrics(params: {

    /**
     * OnpremiseServer ID
     */
    id: OnpremiseServerId;

    /**
     * json dumped string of collected metrics
     */
    list_of_metrics: string;
  }): Observable<number> {

    return this.postOnpremiseServerMetrics$Response(params).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Path part for operation addAudiomatchChannel
   */
  static readonly AddAudiomatchChannelPath = '/amchannel';

  /**
   * Add a new audiomatch channel.
   *
   * Insert a new audiomatch channel in the database
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addAudiomatchChannel()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addAudiomatchChannel$Response(params: {
    body: NewAudiomatchChannel
  }): Observable<StrictHttpResponse<NewAudiomatchChannel>> {

    const rb = new RequestBuilder(this.rootUrl, InsertService.AddAudiomatchChannelPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NewAudiomatchChannel>;
      })
    );
  }

  /**
   * Add a new audiomatch channel.
   *
   * Insert a new audiomatch channel in the database
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addAudiomatchChannel$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addAudiomatchChannel(params: {
    body: NewAudiomatchChannel
  }): Observable<NewAudiomatchChannel> {

    return this.addAudiomatchChannel$Response(params).pipe(
      map((r: StrictHttpResponse<NewAudiomatchChannel>) => r.body as NewAudiomatchChannel)
    );
  }

  /**
   * Path part for operation addMonitoredChannel
   */
  static readonly AddMonitoredChannelPath = '/monitoredchannel';

  /**
   * Add a new monitored channel.
   *
   * Insert a new monitored channel in the database
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addMonitoredChannel()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addMonitoredChannel$Response(params: {
    body: NewMonitoredChannel
  }): Observable<StrictHttpResponse<NewMonitoredChannel>> {

    const rb = new RequestBuilder(this.rootUrl, InsertService.AddMonitoredChannelPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NewMonitoredChannel>;
      })
    );
  }

  /**
   * Add a new monitored channel.
   *
   * Insert a new monitored channel in the database
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addMonitoredChannel$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addMonitoredChannel(params: {
    body: NewMonitoredChannel
  }): Observable<NewMonitoredChannel> {

    return this.addMonitoredChannel$Response(params).pipe(
      map((r: StrictHttpResponse<NewMonitoredChannel>) => r.body as NewMonitoredChannel)
    );
  }

  /**
   * Path part for operation addShowSchedule
   */
  static readonly AddShowSchedulePath = '/showschedule';

  /**
   * Add a new show schedule.
   *
   * Add a new show schedule in the database
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addShowSchedule()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addShowSchedule$Response(params: {
    body: NewShowSchedule
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, InsertService.AddShowSchedulePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Add a new show schedule.
   *
   * Add a new show schedule in the database
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addShowSchedule$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addShowSchedule(params: {
    body: NewShowSchedule
  }): Observable<void> {

    return this.addShowSchedule$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation addPartialFull
   */
  static readonly AddPartialFullPath = '/partialfull';

  /**
   * Add a new partial full entry.
   *
   * Insert  new partial full entry in the database
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addPartialFull()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addPartialFull$Response(params: {
    body: NewPartialFull
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, InsertService.AddPartialFullPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Add a new partial full entry.
   *
   * Insert  new partial full entry in the database
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addPartialFull$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addPartialFull(params: {
    body: NewPartialFull
  }): Observable<void> {

    return this.addPartialFull$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
