import { AfterViewInit, Component, OnInit, ViewChild, } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { ShowScheduleListDataSource } from './showschedule-list-datasource';
import { RetrieveService } from '../api/services/retrieve.service';
import { ShowSchedule } from '../api/models/show-schedule';
import { debounceTime, distinctUntilChanged, finalize, last } from 'rxjs/operators';
import { FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute, RouterLinkWithHref } from '@angular/router';
import moment from 'moment';
import { Observable } from 'rxjs';
import { ChannelBundle } from '../api/models/channel-bundle';
import { MatDialog } from '@angular/material/dialog';
import { ShowscheduleMatchSettingsComponent, DialogData } from '../showschedule-match-settings/showschedule-match-settings.component';
import { OperateService } from '../api/services/operate.service';
import { SubmitShowSchedule } from '../api/models/submit-show-schedule';

export interface SearchParams {
  editor?: string;
  channel?: string;
  startDateFrom?: string;
  startDateTo?: string;
  endDateFrom?: string;
  endDateTo?: string;
  title?: string;
  onlyFull?: boolean;
  limit?: number;
  offset?: number;
  sort?: 'show_date';
  order?: 'asc' | 'desc';
}


@Component({
  selector: 'app-showschedule-list',
  templateUrl: './showschedule-list.component.html',
  styleUrls: ['./showschedule-list.component.css']
})
export class ShowscheduleListComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<ShowSchedule>;

  /* Sorting */
  sortActive: string;
  sortDirection: 'asc' | 'desc';

  /* Pagination */
  pageIndex: number;
  pageSize: number;

  /** query parameters */
  searchParams: SearchParams = {};


  /** Table data source */
  dataSource: ShowScheduleListDataSource;

  /* Filters */
  editorFilter = new FormControl();
  channelFilter = new FormControl();
  amidFilter = new FormControl();
  titleFilter = new FormControl();
  onlyFullFilter = new FormControl();

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['editor', 'channel', 'amid', 'title', 'show_date', 'start_date', 'end_date', 'inserted'];

  /* Datepickers helpers */
  startDateFrom = new FormControl();
  startDateTo = new FormControl();

  channels$: Observable<ChannelBundle[]>

  fpversion = 1;

  constructor(
    private retrieve: RetrieveService,
    private operate: OperateService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
  ) {
  };

  ngOnInit(): void {
    this.dataSource = new ShowScheduleListDataSource(this.retrieve);

    this.channels$ = this.retrieve.getShowScheduleChannel();

    /* Read query parameters from URL */
    this.route.queryParams.subscribe(params => {
      this.editorFilter.setValue(params['editor'], { emitEvent: false });
      this.channelFilter.setValue(params['channel'], { emitEvent: false });
      this.amidFilter.setValue(params['amid'], { emitEvent: false });
      this.titleFilter.setValue(params['title'], { emitEvent: false });
      this.onlyFullFilter.setValue(params['onlyFull'] == 'true' ? true : false, { emitEvent: false });
      this.startDateFrom.setValue(params['startDateFrom'] ? moment(params['startDateFrom']) : null, { emitEvent: false });
      this.startDateTo.setValue(params['startDateTo'] ? moment(params['startDateTo']) : null, { emitEvent: false });
      this.pageIndex = Number(params['pageIndex']) || 0;
      this.pageSize = Number(params['pageSize']) || 10;
      this.sortDirection = params['sortDirection'] || 'asc';
      this.sortActive = params['sortActive'] || 'show_date';
      this.loadContentsPage();
    });
  }

  ngAfterViewInit() {


    this.sort.sortChange
      .subscribe(() => {
        this.router.navigate(['showschedule/'], {
          queryParams: {
            sortDirection: this.sort.direction,
            sortActive: this.sort.active,
            pageIndex: 0,
          },
          queryParamsHandling: 'merge'
        });
      });

    this.editorFilter.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['showschedule/'], {
        queryParams: {
          editor: value,
          pageIndex: 0
        },
        queryParamsHandling: 'merge'
      });
    });

    this.channelFilter.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
    ).subscribe(value => {
      console.log(value)
      this.router.navigate(['showschedule/'], {
        queryParams: {
          channel: value,
          pageIndex: 0
        },
        queryParamsHandling: 'merge'
      });
    });

    this.amidFilter.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['showschedule/'], {
        queryParams: {
          amid: value,
          pageIndex: 0
        },
        queryParamsHandling: 'merge'
      });
    });

    this.titleFilter.valueChanges.pipe(
      debounceTime(1500),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['showschedule/'], {
        queryParams: {
          title: value,
          pageIndex: 0
        },
        queryParamsHandling: 'merge'
      });
    });

    this.onlyFullFilter.valueChanges.pipe(
    ).subscribe(value => {
      this.router.navigate(['showschedule/'], {
        queryParams: {
          onlyFull: value,
          pageIndex: 0
        },
        queryParamsHandling: 'merge'
      });
    });

    this.paginator.page
      .subscribe(() => {
        this.router.navigate(['showschedule/'], {
          queryParams: {
            pageIndex: this.paginator.pageIndex,
            pageSize: this.paginator.pageSize
          },
          queryParamsHandling: 'merge'
        });
      });

    /* Dates */
    this.startDateFrom.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged()
    ).subscribe(value => {
      this.router.navigate(['showschedule/'], {
        queryParams: {
          pageIndex: 0,
          startDateFrom: value ? value.format('YYYY-MM-DD') : null,
          startDateTo: value ? moment(value, 'YYYY-MM-DD').add(1, 'days') : null
        },
        queryParamsHandling: 'merge'
      });
    });

    this.startDateTo.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged()
    ).subscribe(value => {
      this.router.navigate(['showschedule/'], {
        queryParams: {
          pageIndex: 0,
          startDateTo: value ? value.format('YYYY-MM-DD') : null
        },
        queryParamsHandling: 'merge'
      });
    });
  }

  loadContentsPage(): void {
    /**
     * Load a page of contents based on current parameters
     */

    /* Pagination */
    this.searchParams['offset'] = this.pageIndex * this.pageSize;
    this.searchParams['limit'] = this.pageSize;

    /* Filters */
    this.searchParams['editor'] = this.editorFilter.value;
    this.searchParams['channel'] = this.channelFilter.value;
    this.searchParams['amid'] = this.amidFilter.value;
    this.searchParams['title'] = this.titleFilter.value;
    this.searchParams['onlyFull'] = this.onlyFullFilter.value;

    /* Sorting */
    this.searchParams['order'] = this.sortDirection;
    this.searchParams['sort'] = this.sortActive as "show_date";

    /* Dates */
    this.searchParams['startDateFrom'] = this.startDateFrom.value ? this.startDateFrom.value.format('YYYY-MM-DD') : null;
    this.searchParams['startDateTo'] = this.startDateTo.value ? this.startDateTo.value.format('YYYY-MM-DD') : null;

    /* Run */
    this.dataSource.loadContents(this.searchParams);
  }

  download(fileType: string) {
    this.retrieve.countShowSchedule(this.searchParams).subscribe(
      count => {
        if (confirm("Are you sure you wanto to export " + count + " show schedules?")) {
          this.retrieve.getShowScheduleList({ ...this.searchParams, forExporting: true }).subscribe(
            data => this.downloadFile(data, fileType, (new Date()).toISOString())
          )
        }
      }
    )
  }
  downloadFile(items, fileType: string, filename = 'data') {

    let blob
    if (fileType == 'csv') {
      const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
      const header = Object.keys(items[0])
      const csv = [
        header.join(';'), // header row first
        ...items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(';'))
      ].join('\r\n')
      blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    }
    else if (fileType == 'json') {
      blob = new Blob([JSON.stringify(items, null, 2)], { type: 'application/json' });
    }

    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + "." + fileType);
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  viewShowSchedule(): void {
    this.router.navigate(['viewshowschedule'], {
      queryParams: {
        startDate: this.startDateFrom.value ? moment(this.startDateFrom.value).toDate() : moment().clone().startOf('week').startOf('day'),
        endDate: this.startDateTo.value ? moment(this.startDateTo.value).toDate() : moment().clone().endOf('week').endOf('day'),
        channel: this.channelFilter.value ? this.channelFilter.value : null
      }
    });
  }
  openShowScheduleAudiomatchSettings(): void {

    const dialogData: DialogData = {
      monitoredChannel: null,
      processingDate: moment().format('YYYY-MM-DD'),
      fpversion: this.fpversion
    }

    const dialogRef = this.dialog.open(ShowscheduleMatchSettingsComponent, { data: dialogData });

    dialogRef.afterClosed().subscribe(result => {
      if (!(result == null || result == '')) {
        let submitShowSchedule: SubmitShowSchedule = {
          amchid: result.monitoredChannel.amchid,
          id_server: result.monitoredChannel.id_server,
          udp_port: result.monitoredChannel.udp_port,
          processingDate: result.processingDate,
          version: result.fpversion,
        }
        this.operate.queueShowScheduleAudiomatch({
          body: submitShowSchedule
        }).subscribe(
          (resp) => console.log(resp),
          (err) => (console.log(err))
        )
      }
    });
  }
}
