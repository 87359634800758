import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TaskStatus } from '../api/models/task-status';
import { Error } from '../api/models/error';
import { AudioMatchRunLegacy } from '../api/models/audio-match-run-legacy';
import { RetrieveService } from '../api/services/retrieve.service';
import { Type } from '../api/models/type'
import { forkJoin, combineLatest } from 'rxjs';
import { ConstantPool } from '@angular/compiler';
import moment from 'moment';

interface Task {
  status: TaskStatus,
  error?: Error,
  lastUpdate?: string,
  job?: string,
  elapsed?: number,
  number_of_matches?: number,
  runs?: AudioMatchRunLegacy[]
}

@Component({
  selector: 'app-task-in-list',
  templateUrl: './task-in-list.component.html',
  styleUrls: ['./task-in-list.component.css']
})
export class TaskInListComponent implements OnInit, OnChanges {

  @Input() task: Task

  _task: Task;

  constructor(private retrieve: RetrieveService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {

    if ('taskType' in changes.task.currentValue && changes.task.currentValue['taskType'] == 'audiomatch') {
      if (changes.task.currentValue['status'] == 'completed') {
        combineLatest([this.retrieve.countMatches({'amid': changes.task.currentValue['amid'], version: 1, 'type': Type.Ondemand}), 
        this.retrieve.countMatches({'amid': changes.task.currentValue['amid'], version: 1, 'type': Type.Linear, 'groupBy': 'onair'})]).subscribe(
          count => {
            var linear_matches = 0;
            let lm = count[1] as unknown as Array<number>;
            lm.forEach(element => {
              linear_matches += element['count']
            });
            var linear_days = '';
            if (changes.task.currentValue.policy.includeLinear.startsWith('t')){
              var onairTo = moment(changes.task.currentValue.policy.onairTo)
              var onairFrom = moment(changes.task.currentValue.policy.onairFrom)
              var duration = moment.duration(onairTo.diff(onairFrom));
              
              var seconds = duration.asSeconds();
              
              var d = Math.floor(seconds / (3600*24));
              var h = Math.floor(seconds % (3600*24) / 3600);
              var m = Math.floor(seconds % 3600 / 60);
              
              var dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
              var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
              var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
              
              linear_days = dDisplay + hDisplay + mDisplay;
              linear_days = linear_days.slice(0, -2);
            }

            this._task = {
              ...changes.task.currentValue,
              'processedReferences': changes.task.currentValue.processingTimes==undefined?-1:Object.keys(changes.task.currentValue.processingTimes).length,
              'number_of_ondemand_matches': count[0],
              'number_of_linear_matches': lm.length,
              'number_of_matches': linear_matches + count[0],
              'linear_days': linear_days
            };
          }
        )
      } else {
        let currentRun: AudioMatchRunLegacy = changes.task.currentValue;

        if ('listOfReferences' in currentRun.policy) {
          this._task = {
            ...changes.task.currentValue,
            'processedReferences': currentRun.policy['listOfReferences'].length
          };
        } else if ('totalReferences' in currentRun){
          this._task = {
            ...changes.task.currentValue,
            'processedReferences': currentRun['totalReferences']
          };
        }
        else {
          let countPolicy = { ...currentRun.policy };
          delete countPolicy['includeOndemand'];
          delete countPolicy['includeLinear'];

          let countReferences = [];

          if (currentRun.policy.includeOndemand) {
            let countPolicyOndemand = countPolicy;
            delete countPolicyOndemand['channel'];
            delete countPolicyOndemand['onairFrom'];
            delete countPolicyOndemand['onairTo'];

            countReferences.push(
              this.retrieve.countReferences({ ...countPolicyOndemand, 'amid': changes.task.currentValue['amid'], 'type': Type.Ondemand, 'version': 1 }))
          }

          if (currentRun.policy.includeLinear) {
            let countPolicyLinear = countPolicy;
            delete countPolicyLinear['publishedFrom'];
            delete countPolicyLinear['publishedTo'];

            countReferences.push(
              this.retrieve.countReferences({ ...countPolicyLinear, 'amid': changes.task.currentValue['amid'], 'type': Type.Linear, 'version': 1 }))
          }

          forkJoin(countReferences).subscribe(results => {
            let totalCount = results.length==2 ? <number>results[0] + <number>results[1] : <number>results[0];
            this._task = {
              ...changes.task.currentValue,
              'processedReferences': totalCount
            };
          });
        }
      }
    } else {
      this._task = changes.task.currentValue;
    }
  }
}
