/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Amchid } from '../models/amchid';
import { Amid } from '../models/amid';
import { DateTime } from '../models/date-time';
import { DeleteContent } from '../models/delete-content';
import { MonitoredChannelUdPport } from '../models/monitored-channel-ud-pport';
import { NewContentId } from '../models/new-content-id';
import { OnpremiseServerId } from '../models/onpremise-server-id';
import { OnpremiseServersMetrics } from '../models/onpremise-servers-metrics';


/**
 * Delete contents
 */
@Injectable({
  providedIn: 'root',
})
export class DeleteService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation deleteContents
   */
  static readonly DeleteContentsPath = '/contents';

  /**
   * Delete contents for a given set of parameters.
   *
   * Delete contents for a given set of parameters
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteContents()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteContents$Response(params: {
    body: DeleteContent
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DeleteService.DeleteContentsPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete contents for a given set of parameters.
   *
   * Delete contents for a given set of parameters
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteContents$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteContents(params: {
    body: DeleteContent
  }): Observable<void> {

    return this.deleteContents$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation deleteContent
   */
  static readonly DeleteContentPath = '/content/{amid}';

  /**
   * Delete a content.
   *
   * Delete content and/or media
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteContent()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteContent$Response(params: {

    /**
     * Query AMID
     */
    amid: Amid;

    /**
     * If set to True deletes Audio media
     */
    audio?: boolean;

    /**
     * If set to True deletes Video media
     */
    video?: boolean;

    /**
     * If set to True deletes all fingerprints
     */
    fingerprints?: boolean;

    /**
     * If set to True deletes all matches
     */
    matches?: boolean;
  }): Observable<StrictHttpResponse<NewContentId>> {

    const rb = new RequestBuilder(this.rootUrl, DeleteService.DeleteContentPath, 'delete');
    if (params) {
      rb.path('amid', params.amid, {});
      rb.query('audio', params.audio, {});
      rb.query('video', params.video, {});
      rb.query('fingerprints', params.fingerprints, {});
      rb.query('matches', params.matches, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NewContentId>;
      })
    );
  }

  /**
   * Delete a content.
   *
   * Delete content and/or media
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteContent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteContent(params: {

    /**
     * Query AMID
     */
    amid: Amid;

    /**
     * If set to True deletes Audio media
     */
    audio?: boolean;

    /**
     * If set to True deletes Video media
     */
    video?: boolean;

    /**
     * If set to True deletes all fingerprints
     */
    fingerprints?: boolean;

    /**
     * If set to True deletes all matches
     */
    matches?: boolean;
  }): Observable<NewContentId> {

    return this.deleteContent$Response(params).pipe(
      map((r: StrictHttpResponse<NewContentId>) => r.body as NewContentId)
    );
  }

  /**
   * Path part for operation deleteOnpremiseServerMetrics
   */
  static readonly DeleteOnpremiseServerMetricsPath = '/onpremiseserver/{id}/metrics';

  /**
   * Delete onpremise server metrics.
   *
   * Delete content and/or media
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteOnpremiseServerMetrics()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOnpremiseServerMetrics$Response(params: {

    /**
     * OnpremiseServer ID
     */
    id: OnpremiseServerId;

    /**
     * date before which all metrics or the metric specified in name will be deleted
     */
    before?: DateTime;

    /**
     * Name of the metric
     */
    name?: OnpremiseServersMetrics;
  }): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, DeleteService.DeleteOnpremiseServerMetricsPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('before', params.before, {});
      rb.query('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Delete onpremise server metrics.
   *
   * Delete content and/or media
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteOnpremiseServerMetrics$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOnpremiseServerMetrics(params: {

    /**
     * OnpremiseServer ID
     */
    id: OnpremiseServerId;

    /**
     * date before which all metrics or the metric specified in name will be deleted
     */
    before?: DateTime;

    /**
     * Name of the metric
     */
    name?: OnpremiseServersMetrics;
  }): Observable<number> {

    return this.deleteOnpremiseServerMetrics$Response(params).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Path part for operation deleteMonitoredChannel
   */
  static readonly DeleteMonitoredChannelPath = '/monitoredchannel/{amchid}/{id_server}/{udp_port}';

  /**
   * Delete a monitored channel.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteMonitoredChannel()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMonitoredChannel$Response(params: {

    /**
     * Audio match channel id parameter
     */
    amchid: Amchid;

    /**
     * MonitoredChannel id server
     */
    id_server: OnpremiseServerId;

    /**
     * MonitoredChannel udp port
     */
    udp_port: MonitoredChannelUdPport;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DeleteService.DeleteMonitoredChannelPath, 'delete');
    if (params) {
      rb.path('amchid', params.amchid, {});
      rb.path('id_server', params.id_server, {});
      rb.path('udp_port', params.udp_port, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete a monitored channel.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteMonitoredChannel$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMonitoredChannel(params: {

    /**
     * Audio match channel id parameter
     */
    amchid: Amchid;

    /**
     * MonitoredChannel id server
     */
    id_server: OnpremiseServerId;

    /**
     * MonitoredChannel udp port
     */
    udp_port: MonitoredChannelUdPport;
  }): Observable<void> {

    return this.deleteMonitoredChannel$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
