/* tslint:disable */
/* eslint-disable */

/**
 * Last status of the task
 */
export enum DownloadTaskStatus {
  None = 'none',
  Live = 'live',
  Requested = 'requested',
  Completed = 'completed',
  Error = 'error'
}
