import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { RetrieveService } from '../api/services/retrieve.service';
import { MonitoredChannel } from '../api/models/monitored-channel';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { OperateService } from '../api/services/operate.service';
import { SimpleMonitoredChannel } from '../api/models/simple-monitored-channel';

@Component({
  selector: 'app-monitoredchannel',
  templateUrl: './monitoredchannel.component.html',
  styleUrls: ['./monitoredchannel.component.css']
})
export class MonitoredchannelComponent implements OnInit {

  monitoredchannel$:Observable<MonitoredChannel>;

  showscheduleStatus = new FormControl();
  showscheduleStatusDisbaled = true;

  constructor(private retrieve: RetrieveService,
    private route: ActivatedRoute,
    private operate: OperateService
    ) { }

  ngOnInit(): void {
    this.monitoredchannel$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap) =>        
        this.retrieve.getMonitoredChannel({amchid:params.get('amchid'), id_server:params.get('id_server'), 
        udp_port:params.get('udp_port')}))
    );
    this.monitoredchannel$.subscribe(
      (monitoredChannel) => {
        if (monitoredChannel.status != 'running'){
          this.showscheduleStatusDisbaled = true;
        }else{
          this.showscheduleStatusDisbaled = false;
        }
        if (monitoredChannel.showschedule_status == 'on'){
          this.showscheduleStatus.setValue(true);
        }else{
          this.showscheduleStatus.setValue(false);
        }
      }
    )
  }

  toggleShowSchedule(monitoredChannel: MonitoredChannel): void {
    console.log(this.showscheduleStatus.value)
    let simpleMonitoredChannel: SimpleMonitoredChannel = {
      amchid: monitoredChannel.amchid,
      udp_port: monitoredChannel.udp_port,
      id_server: monitoredChannel.id_server
    }
    if (this.showscheduleStatus.value){
      this.operate.setShowscheduleOn({body: simpleMonitoredChannel}).subscribe(
        () => {console.log('Set show schedule on')},
        (err) => {console.log(err)}
      )
    }else{
      this.operate.setShowscheduleOff({body: simpleMonitoredChannel}).subscribe(
        () => {console.log('Set show schedule off')},
        (err) => {console.log(err)}
      )
    }
  }
}
