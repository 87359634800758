import { Pipe, PipeTransform } from '@angular/core';
import { stringify } from 'querystring';

@Pipe({
  name: 'id'
})
export class IdPipe implements PipeTransform {
/**
 * Transform content ID to better fit in tables
 * @param value Content ID
 */
  transform(value: string, ): string {
    if (value.startsWith('ContentItem-')){
      value = value.replace('ContentItem-','');
    }
    if (value.length > 24){
      value = value.substr(0,15) + '...' + value.substr(value.length-5);
    }
    return value;
  }

}
